import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiMethod } from "@core/core.module/decorators/api-method.decorator";
import { ApiService } from "@core/core.module/decorators/api-service.decorator";
import { NrtEntityApiService } from "@core/core.module/services/NrtEntityApiService";
import { environment } from "projects/player-portal/player-portal-client/src/environments/environment";
import { Observable } from "rxjs";
import { ProfileTier } from "./ProfileTier";

@Injectable({ providedIn: "root" })
@ApiService(environment.API_ENDPOINT + "/api/tiers/")
export class ProfileTiersService extends NrtEntityApiService<ProfileTier> {
  constructor(
    http: HttpClient /* , protected translateService: TranslocoService */
  ) {
    super(http, "tiers" /* , Serializer.for(ProfileTier) */);
  }

  @ApiMethod({ method: "GET", route: "" /* , cache: true */ })
  public getTiers$(): Observable<ProfileTier[]> {
    return this.getEntityArray$();
  }
}
