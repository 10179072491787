import { Validators } from '@angular/forms';
import { FormUtils } from '../form.utils';

function addValidator(
  target: any,
  propertyKey: string,
  pattern: RegExp,
  message?: string
) {
  const control = FormUtils.getCreateFormControl(target, propertyKey);

  control.validators.push({
    name: 'pattern',
    validator: Validators.pattern(pattern),
    message: message || '@VALIDATORS.WRONG_VALUE|Wrong value.'
  });
}

export const PatternValidator = (pattern: RegExp, message?: string) => (
  target: any,
  propertyKey: string
) => {
  addValidator(target, propertyKey, pattern, message);
};
