import { NgZone } from "@angular/core";
import { Observable, Subscription } from "rxjs";

export function outsideZone<T>(zone: NgZone) {
  return function(source: Observable<T>) {
    return new Observable<T>(observer => {
      let sub: Subscription;
      zone.runOutsideAngular(() => {
        sub = source.subscribe(observer);
      });

      return sub;
    });
  };
}
