import { BrandingClient } from "./branding";

export const clientMapping: Record<string, BrandingClient> = {
  ["F5AF6230FC6A"]: "offercraft",
  ["C918C10156D4"]: "naskila",
  ["41DB233D47EF"]: "tp",
  ["A9DC7A9E5A7D"]: "tp",
  ["70F437E9D6D7"]: "rwlv",
  ["16A55E6EDA2E"]: "coyote", //TODO
  ["16A55E6EDA2E_"]: "elite" //TODO
};

export const clientDomainMapping: Record<string, BrandingClient> = {
  "rewards.rwlasvegas.com": "rwlv",
  "marketing.naskila.com": "naskila"
};

export const clientInitionalLoader: Record<string, string> = {
  rwlv:
    "https://prodocdata.blob.core.windows.net/media/70f437e9d6d7/commonimages/loader.png",
  naskila:
    "https://prodocdata.blob.core.windows.net/media/c918c10156d4/commonimages/favicon.png"
};
