import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiMethod } from "@core/core.module/decorators/api-method.decorator";
import { ApiService } from "@core/core.module/decorators/api-service.decorator";
import { NrtEntityApiService } from "@core/core.module/services/NrtEntityApiService";
import { environment } from "projects/player-portal/player-portal-client/src/environments/environment";
import { Observable } from "rxjs";
import { LocationInfo } from "./LocationInfo";
import { LocationOwner } from "./LocationOwner";

@Injectable({ providedIn: "root" })
@ApiService(environment.API_ENDPOINT + "/api/locations")
export class LocationsService extends NrtEntityApiService<LocationOwner> {
  constructor(protected http: HttpClient) {
    super(http, "location-owners");
  }

  //override
  identityKey = (e: LocationOwner) => `${e.name}`;
  identityKeyFixed = (index: number, e: LocationInfo) => `${e.locationName}`;

  // @ApiMethod({ cache: true })
  // public getLocations$(): Observable<LocationInfo[]> {
  //   return this.getEntityArray$();
  // }

  @ApiMethod({ method: "GET", route: "locationOwners", cache: true })
  public getLocationOwners$(): Observable<LocationOwner[]> {
    // const arr = [
    //   { name: 'PUMP', description: 'some some some', isMainLocation: true },
    //   { name: 'LOUNGE', description: 'hfg djfhgj df dj', isMainLocation: false }
    // ] as LocationOwner[];

    // const x = of(arr);
    // this.store.update(s => ({ ...s, ['location-owners']: arr }));

    // return x;

    return this.getEntityArray$(); // this.getEntityArray$();
    //return this.storeRequest$<LocationOwner[]>("location-owners");
  }
}
