import { from } from "rxjs";
import { map, toArray } from "rxjs/operators";

//import { from } from 'linq';

export class AssetsLoader {
  public static cache = {};
  public static loadStyles(selector: string) {
    const q =
      AssetsLoader.cache[selector] ||
      (AssetsLoader.cache[selector] = from(document.styleSheets).pipe(
        map((styleSheet: CSSStyleSheet) => {
          const sel = [];
          for (let index = 0; index < styleSheet.cssRules.length; index++) {
            const rule = styleSheet.cssRules.item(index) as CSSStyleRule;
            if (rule.selectorText && rule.selectorText.indexOf(selector) > -1) {
              sel.push(rule.cssText);
            }
          }
          return sel;
        }),
        toArray()
      ));

    return q;
  }

  public static recurseLoadStyles(
    el: Element,
    appendTo?: Element,
    excludeList?: Array<string>
  ) {
    excludeList = excludeList || [];
    const nodes = el.childNodes || [];
    nodes.forEach(x => {
      const cl = x.className as string;
      if (cl && typeof cl === "string") {
        cl.split(" ").forEach(c => {
          if (excludeList.indexOf(c) === -1) {
            excludeList.push(c);
            AssetsLoader.loadStyles(`.${c}`).forEach(x => {
              AssetsLoader.injectStyle(appendTo || el, x);
            });
          }
        });
      }

      this.recurseLoadStyles(x, appendTo || el, excludeList);
    });
  }

  static injectStyle(el: Element, cssText: string) {
    const shadowDomStyleElement = document.createElement("style");
    shadowDomStyleElement.innerText = cssText;
    el.appendChild(shadowDomStyleElement);
  }

  public static getAllCSSVariableNames(
    styleSheets = document.styleSheets
  ): string[] {
    var cssVars = [];
    // loop each stylesheet
    for (var i = 0; i < styleSheets.length; i++) {
      // loop stylesheet's cssRules
      try {
        // try/catch used because 'hasOwnProperty' doesn't work
        for (var j = 0; j < styleSheets[i]["cssRules"].length; j++) {
          try {
            // loop stylesheet's cssRules' style (property names)
            for (
              var k = 0;
              k < styleSheets[i]["cssRules"][j]["style"].length;
              k++
            ) {
              let name = styleSheets[i]["cssRules"][j]["style"][k];
              // test name for css variable signiture and uniqueness
              if (name.startsWith("--") && cssVars.indexOf(name) == -1) {
                cssVars.push(name);
              }
            }
          } catch (error) {}
        }
      } catch (error) {}
    }
    return cssVars;
  }

  public static getElementCSSVariables(
    allCSSVars: string[],
    element = document.body,
    pseudo?: string
  ): Record<string, string> {
    var elStyles = window.getComputedStyle(element, pseudo);
    var cssVars = {};
    for (var i = 0; i < allCSSVars.length; i++) {
      let key = allCSSVars[i];
      let value = elStyles.getPropertyValue(key);
      if (value) {
        cssVars[key] = value;
      }
    }
    return cssVars;
  }

  private static resolver(
    appendTo: HTMLElement,
    url: string,
    async = true,
    atTheTop = false
  ) {
    const func = function(resolve) {
      const script = document.createElement("script");
      async && script.toggleAttribute("async");
      script.setAttribute("src", url);
      script.onload = () => resolve();

      if (!atTheTop) {
        appendTo.appendChild(script);
      } else {
        appendTo.insertBefore(script, appendTo.firstChild);
      }
    };

    return func;
  }

  static async loadScript(
    appendTo: HTMLElement,
    url: string,
    async = true,
    atTheTop = false
  ): Promise<any> {
    const resolver = this.resolver(appendTo, url, async, atTheTop);
    return new Promise(resolver);
  }

  static injectScript(
    appendTo: HTMLElement,
    scriptContent: string,
    atTheTop = false
  ): HTMLScriptElement {
    const script = document.createElement("script");
    var inlineScript = document.createTextNode(scriptContent);
    script.appendChild(inlineScript);
    //script.innerText = scriptContent;

    if (!atTheTop) {
      appendTo.appendChild(script);
    } else {
      appendTo.insertBefore(script, appendTo.firstChild);
    }

    return script;
  }
}
