import { Injectable } from "@angular/core";
import { PreloadAllModules, Route } from "@angular/router";
import { Observable, EMPTY } from "rxjs";

@Injectable({ providedIn: 'root' })
export class OptimisticPreloadingStrategy extends PreloadAllModules {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route && (route.data ? !route.data.NO_PRELOAD : true)) {
      return super.preload(route, load);
    }
    else {
      return EMPTY;
    }
  }
}
