import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BootstrapMetaData } from "@client-services/api/clientMetaData/MetaData";
import { MetaDataQuery } from "@client-services/api/clientMetaData/storage/metaData.query";
import { ApiMethod, ApiService } from "@core/core.module/decorators";
import { NrtApiService } from "@core/core.module/services";
import { METADATA } from "@core/core.module/services/IMetaData";
import { NrtQuery } from "@core/core.module/services/storage/NrtQuery";
import { environment } from "projects/player-portal/player-portal-client/src/environments/environment";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
@ApiService(environment.AUTH_ENDPOINT + "/api/externalAuth")
export class OktaExtService extends NrtApiService {
  get okta_endpoint() {
    return this.metadata?.current?.ssoProviders.find(x => x.type === "Okta")
      ?.authEndpoint;
  }

  constructor(
    protected http: HttpClient,
    @Inject(METADATA) public metadata: NrtQuery<BootstrapMetaData>
  ) {
    super(http);
  }

  @ApiMethod({ method: "POST", route: "okta" })
  public extLogin$(
    username: string,
    password: string,
    params: any
  ): Observable<any> {
    const request = { username: username, password: password, params: params };
    return this.request$(request, { skipErrorMessage: true });
  }

  @ApiMethod({ method: "POST", route: "signUp" })
  public extSignup$(
    firstName: string,
    lastName: string,
    birthday: string,
    email: string,
    phone: string,
    password: string,
    address1: string,
    address2: string,
    city: string,
    stateId: number,
    postalCode: string,
    countryId: number,
    mailAllowed: boolean
  ): Observable<any> {
    const request = {
      firstName: firstName,
      lastName: lastName,
      birthday: birthday,
      email: email,
      phone: phone,
      password: password,
      address1: address1,
      address2: address2,
      city: city,
      stateId: stateId,
      postalCode: postalCode,
      countryId: countryId,
      mailAllowed: mailAllowed,
    };
    return this.request$(request, { skipErrorMessage: true });
  }

  @ApiMethod({ method: "POST", route: "forgot" })
  public extForgot$(email: string): Observable<any> {
    const request = { email: email };
    return this.request$(request, { skipErrorMessage: true });
  }

  @ApiMethod({ method: "POST", route: "validate" })
  public extValidate$(patronNumber: string, pin: number): Observable<any> {
    const request = { patronNumber: patronNumber, pin: pin };
    return this.request$(request, { skipErrorMessage: true });
  }

  @ApiMethod({ method: "POST", route: "linkWithOkta" })
  public extLinkWithOkta$(patronNumber: string, email: string, password: string): Observable<any> {
    const request = { patronNumber: patronNumber, email: email, password: password };
    return this.request$(request, { skipErrorMessage: true });
  }

  logoutFromOkta(id_token: string, redirect_uri: string) {
    window.location.replace(
      `${this.okta_endpoint}/logout?id_token_hint=${id_token}&post_logout_redirect_uri=${redirect_uri}`
    );
  }

  redirectToOktaAuthorize(prms: Record<string, string>) {
    const auth = new URL(this.okta_endpoint + "/authorize");

    Object.keys(prms).forEach(k => auth.searchParams.set(k, prms[k]));

    auth.searchParams.set("check_auth", "false");
    auth.searchParams.set("state", window.location.href);

    window.location.replace(auth.toString());
  }
}
