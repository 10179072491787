import { InjectionToken } from '@angular/core';

export const AUTH_ENDPOINT = new InjectionToken<string>('AUTH_ENDPOINT');
export const REMOTE_AUTH_ENDPOINT = new InjectionToken<string>('REMOTE_AUTH_ENDPOINT');
export const API_ENDPOINT = new InjectionToken<string>('API_ENDPOINT');
export const LOGIN_PATH = new InjectionToken<string>('LOGIN_PATH');
export const CLIENT_ID = new InjectionToken<string>('CLIENT_ID');
export const FB_APP_ID = new InjectionToken<string>('FB_APP_ID');
export const GOOGLE_APP_ID = new InjectionToken<string>('GOOGLE_APP_ID');
export const IDLE_TIME = new InjectionToken<number>('IDLE_TIME');
export const R_TOKEN_FLAG = new InjectionToken<string>('R_TOKEN_FLAG');
export const OKTA_API_ENDPOINT = new InjectionToken<string>('OKTA_API_ENDPOINT');

