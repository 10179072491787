import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'projects/player-portal/player-portal-client/src/environments/environment';
import { ApiService } from '@core/core.module/decorators/api-service.decorator';
import { ApiMethod } from '@core/core.module/decorators/api-method.decorator';
import { NrtApiService } from '@core/core.module/services/NrtApiService';
import { Announcement } from './Announcement';

@Injectable({ providedIn: 'root' })
@ApiService(environment.API_ENDPOINT + '/api/announcements/')
export class AnnouncementsService extends NrtApiService {
  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  @ApiMethod({
    method: 'GET',
    route: '',
    cache: false
  })
  public getNotifications$(): Observable<Announcement[]> {
    return this.request$();
  }

}
