import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiMethod } from "@core/core.module/decorators/api-method.decorator";
import { ApiService } from "@core/core.module/decorators/api-service.decorator";
import { IDataQueryModel, IPagedResult } from "@core/core.module/services";
import { NrtEntityApiService } from "@core/core.module/services/NrtEntityApiService";
import { guid, isDefined } from "@datorama/akita";
import { Reward } from "projects/player-portal/common/models/Reward";
import { environment } from "projects/player-portal/player-portal-client/src/environments/environment";
import { Observable } from "rxjs";
import { startWith } from "rxjs/operators";
import { RewardInfo } from "./RewardInfo";

export const defaultDataQuery: IDataQueryModel = {
  searchText: "",
  sortBy: "",
  isDescending: false,
  pageNumber: 1,
  pageSize: 1000
};
@Injectable({ providedIn: "root" })
@ApiService(environment.API_ENDPOINT + "/api/rewards/")
export class RewardsService extends NrtEntityApiService<RewardInfo> {
  identityKey = (e: RewardInfo) => (isDefined(e.id) ? `${e.id}` : guid());

  constructor(protected http: HttpClient) {
    super(http, "player-rewards");
  }

  @ApiMethod({ method: "GET", route: "", cache: true })
  public getPlayerRewards$(
    params: IDataQueryModel = defaultDataQuery
  ): Observable<IPagedResult<Reward>> {
    return this.getEntityPagedArray$({
      ...params
    });
  }

  @ApiMethod({ method: "GET", route: "ext/{rewardType}", cache: true })
  public getPlayerExtRewards$(
    rewardType: string
  ): Observable<IPagedResult<Reward>> {
    return this.getEntityPagedArray$({
      rewardType: rewardType
    });
  }

  @ApiMethod({ method: "GET", route: "{rewardCode}", cache: true })
  public getRewardInfo$(rewardCode: string): Observable<RewardInfo> {
    //return this.getEntity$(id);
    //return this.getEntity$({ id });

    return this.request$<RewardInfo>({ rewardCode }).pipe(
      startWith(
        this.query.getEntity(rewardCode)
      ) /* ,
      tap(x => {
        x && this.store.upsert(rewardCode, x);
      }) */
    );
  }

  @ApiMethod({
    method: "GET",
    route: `${environment.COBRA_API_ENDPOINT}/api/rewardlookup/codeimage/{rewardCode}?scale=0.6`,
    cache: true
  })
  public getQRCode$(rewardCode: string): Observable<Blob> {
    return this.request$({ rewardCode }, { responseType: "blob" });
  }

  @ApiMethod({ method: "POST", route: "swap" })
  public swapRewards$(
    id: string,
    rewardCode: string,
    birthdate: Date
  ): Observable<IPagedResult<Reward>> {
    return this.request$({ id, rewardCode, birthdate });
  }
}
