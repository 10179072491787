import {
  trigger,
  transition,
  style,
  query,
  animateChild,
  group,
  animate
} from "@angular/animations";

export const forwardRules = new Set<string>();
export const backwardRules = new Set<string>();

export function addForwardTransition(
  ...transitions: [string, string][]
) {
  transitions.map(_ => `${_[0]}|${_[1]}`).forEach(_ => forwardRules.add(_));
}

export function addBackwardTransition(
  ...transitions: [string, string][]
) {
  transitions.map(_ => `${_[0]}|${_[1]}`).forEach(_ => backwardRules.add(_));
}

export function addTransitionSequence(...transitions: string[]) {
  for (let i = 0; i < transitions.length - 1; i++) {
    const t1 = transitions[i];

    for (let j = i + 1; j < transitions.length; j++) {
      const t2 = transitions[j];
      addForwardTransition([t1, t2]);
      addBackwardTransition([t2, t1]);
    }
  }
}

export function forwardAnimations(from: string, to: string) {
  return forwardRules.has(`${from}|${to}`);
}

export function backwardAnimations(from: string, to: string) {
  return backwardRules.has(`${from}|${to}`);
}

const animLeft = [
  style({ position: "relative" }),
  query(":enter, :leave", [
    style({
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%"
    })
  ]),
  query(":enter", [style({ left: "-100%", opacity: 0 })]),
  query(":leave", animateChild()),
  group([
    query(":leave", [
      animate(`{{duration}} ease-out`, style({ left: "100%", opacity: 0 }))
    ]),
    query(":enter", [
      animate(`{{duration}} ease-out`, style({ left: "0", opacity: 1 }))
    ])
  ]),
  query(":enter", animateChild())
];

const animRight = [
  style({ position: "relative" }),
  query(":enter, :leave", [
    style({
      position: "absolute",
      top: 0,
      right: 0,
      width: "100%"
    })
  ]),
  query(":enter", [style({ right: "-100%", opacity: 0 })]),
  query(":leave", animateChild()),
  group([
    query(":leave", [
      animate(`{{duration}} ease-out`, style({ right: "100%", opacity: 0 }))
    ]),
    query(":enter", [
      animate(`{{duration}} ease-out`, style({ right: "0", opacity: 1 }))
    ])
  ]),
  query(":enter", animateChild())
];

export const routeSlideAnimations = trigger("triggerName", [
  transition(forwardAnimations, animRight, {
    params: { duration: "250ms" }
  }),
  transition(backwardAnimations, animLeft, {
    params: { duration: "250ms" }
  })
]);
