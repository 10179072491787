<pgcard
  [Maximize]="false"
  [Toggle]="false"
  [Close]="false"
  [Refresh]="false"
  [ShowHeader]="false"
>
  <div class="modal-header text-left">
    <h5 class="alert-confirm">
      <pg-menu-icon
        IconType="clr"
        IconName="warning-standard"
        [Size]="24"
        [Class]="'is-warning'"
        [style.margin-right.px]="5"
      ></pg-menu-icon>
      {{ "@SESSION.LOGOUT_CONFIRMATION" | transloco }}
    </h5>
  </div>
  <div class="modal-body">
    <h5>
      {{ "@SESSION.LOGOUT_CONFIRMATION_MSG" | transloco }}
      {{ logoutTime$ | async }} sec.
    </h5>
    <div class="pull-right p-t-15">
      <div class="p-b-25">
        <button
          class="btn m-r-10 btn-primary"
          type="button"
          (click)="confirm()"
        >
          {{ "@SESSION.LOGOUT_STAY" | transloco }}
        </button>
        <button class="btn btn-default" type="button" (click)="closeModal()">
          {{ "@MENU.LOGOUT" | transloco }}
        </button>
      </div>
    </div>
  </div>
</pgcard>
