import { IFormFieldMetadata } from "../../base/IFormFieldMetadata";
import { FormUtils } from "./form.utils";

function extendFormField(
  target: any,
  propertyKey: string,
  config?: IFormFieldMetadata
) {
  FormUtils.getCreateFormControl(target, propertyKey, config);
}

export const FormField = (config?: IFormFieldMetadata) => (
  target: any,
  propertyKey
) => {
  extendFormField(target, propertyKey, config);
};
