import {
  CommonModule,
  LocationStrategy,
  PathLocationStrategy,
  ViewportScroller
} from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import {
  ApplicationRef,
  APP_INITIALIZER,
  Inject,
  Injectable,
  Injector,
  NgModule
} from "@angular/core";
import {
  BrowserModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG
} from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Event, NavigationEnd, Router, Scroll } from "@angular/router";
import { ServiceWorkerModule, SwUpdate } from "@angular/service-worker";
import { BootstrapMetaData } from "@client-services/api/clientMetaData/MetaData";
import { NrtDialogsModule } from "@common/components/dialogs/dialogs.module";
import { DialogService } from "@common/services/dialog.service";
import { UpdateService } from "@common/services/update.service";
//import { skipCache } from '@core/core.module/services';
import { CacheInterceptor } from "@core/cache.module/cache.interceptor";
import {
  TranslocoCustomMissingHandler,
  TranslocoTranspiler
} from "@core/core.module/base/NrtMissingTranslationHandler";
import { LogoutConfirmationComponent } from "@core/core.module/components/logout-confirmation/logout-confirmation.component";
import { SocialNetwork } from "@core/core.module/components/social-login/social-definitions";
import { OCCoreModule } from "@core/core.module/core.module";
import { ImageLazyLoadModule } from "@core/core.module/directives/image-lazy-load/image-lazy-load.module";
import { ScreenModule } from "@core/core.module/directives/screen/screen.module";
import {
  DIALOGS_SERVICE_TOKEN,
  MESSAGES_SERVICE_TOKEN
} from "@core/core.module/interfaces";
import { JwtInterceptor } from "@core/core.module/jwt";
import { AuthService } from "@core/core.module/jwt/auth.service";
import {
  API_ENDPOINT,
  AUTH_ENDPOINT,
  CLIENT_ID,
  FB_APP_ID,
  GOOGLE_APP_ID,
  IDLE_TIME,
  LOGIN_PATH,
  R_TOKEN_FLAG
} from "@core/core.module/jwt/injection-tokens";
import { TokenInterceptor } from "@core/core.module/jwt/token.interceptor";
import {
  METADATA,
  METADATA_SERVICE
} from "@core/core.module/services/IMetaData";
import {
  TranslocoHttpLoader,
  translocoLoader
} from "@core/core.module/services/NrtTranslocoHttpLoader";
import { NrtQuery } from "@core/core.module/services/storage/NrtQuery";
import { setRootInjector } from "@core/core.module/utils/root-injector";
import { FormGroupDefaultDirective } from "@core/pages-ui.module/components/forms/form-group-default.directive";
import { pgIconModule } from "@core/pages-ui.module/components/icon/menu-icon.module";
import { MessageModule } from "@core/pages-ui.module/components/message/message.module";
import { MessageService } from "@core/pages-ui.module/components/message/message.service";
import { ProgressModule } from "@core/pages-ui.module/components/progress/progress.module";
import { LoadingStages } from "@core/pages-ui.module/services/loading-stages.enum";
import { pagesToggleService } from "@core/pages-ui.module/services/toggler.service";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import {
  TranslocoConfig,
  TranslocoModule,
  TRANSLOCO_CONFIG
} from "@ngneat/transloco";
import { ModalModule } from "ngx-bootstrap/modal";
//import { HttpModule } from "@angular/http";
import {
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from "ngx-perfect-scrollbar";
import { MessagesService } from "projects/player-portal/common/services/message.service";
import { filter, finalize, scan, switchMap } from "rxjs/operators";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CardResolverModule } from "./branding/clients/Coyote/card-resolver/card-resolver.module";
import { PageLoaderModule } from "./components/page-loader/page-loader.module";
import { ClientLayoutsModule } from "./layouts/layouts.module";
import { SettingsService } from "./pages/settings/storage/settings.service";
import { MetaDataService } from "./services/api/clientMetaData/metaData.service";
import { MetaDataQuery } from "./services/api/clientMetaData/storage/metaData.query";
import { ProfileService } from "./services/api/profile/profile.service";
import { AppInitService, extractSsoAppKey } from "./services/app-init.service";
import { GoogleAnalyticsService } from "./services/google-analytics.service";

//import { DIALOGS_SERVICE_TOKEN } from '@core/core.module/interfaces/core-dialogs.service';

const dialogs = [];
const DEFAULT_KIOSK_IDLE_TIME = 30;
const DEFAULT_PP_IDLE_TIME = 900;

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

//Hammer Config Overide
//https://github.com/angular/angular/issues/10541
@Injectable()
export class AppHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

export function createTranslocoLoader(http: HttpClient) {
  return new TranslocoHttpLoader(http);
}

@NgModule({
  imports: [
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    // angular
    BrowserModule,
    CardResolverModule,
    BrowserAnimationsModule,
    CommonModule,
    OCCoreModule,
    //OCCacheModule,
    // app
    AppRoutingModule,
    //HttpModule,
    HttpClientModule,
    //DynamicModule.withComponents([SettingsComponent]),
    //SettingsModule,
    //PagesUIModule
    ModalModule.forRoot(),
    ClientLayoutsModule,
    NrtDialogsModule,
    MessageModule,
    ImageLazyLoadModule,
    ProgressModule,
    pgIconModule,
    PageLoaderModule,
    TranslocoModule,
    ScreenModule,
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: true || environment.production
    })
  ],
  declarations: [
    AppComponent,
    LogoutConfirmationComponent,
    FormGroupDefaultDirective,
    ...dialogs
  ],
  entryComponents: [...dialogs],
  providers: [
    SettingsService,
    MessageService,
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        availableLangs: ["en-US", "es-MX", "en-ZW"],
        defaultLang: "en-US",
        prodMode: environment.production,
        reRenderOnLangChange: true
      } as TranslocoConfig
    },
    TranslocoCustomMissingHandler,
    TranslocoTranspiler,
    translocoLoader,
    //MetrixService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: MESSAGES_SERVICE_TOKEN,
      useFactory: (messageService: MessageService) =>
        new MessagesService(messageService, {
          errorOptions: { Style: "bar", Position: "top" },
          successOptions: { Style: "bar", Position: "top" }
        }),
      deps: [MessageService]
    },
    { provide: DIALOGS_SERVICE_TOKEN, useClass: DialogService },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: OfflineInterceptor, multi: true },
    //fakeBackendProvider,
    //AuthGuard
    AuthService,
    GoogleAnalyticsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AppHammerConfig
    },
    {
      provide: AUTH_ENDPOINT,
      useValue: environment.AUTH_ENDPOINT
    },
    {
      provide: API_ENDPOINT,
      useValue: environment.API_ENDPOINT
    },
    {
      provide: CLIENT_ID,
      useValue: environment.CLIENT_ID
    },
    // {
    //   provide: LOGIN_PATH,
    //   useValue: "/session/login"
    // },
    {
      provide: LOGIN_PATH,
      useFactory: (query: MetaDataQuery) =>
        query.current?.playerPortalSettings?.unauthorizedRedirectUri ||
        "/session/login",
      deps: [MetaDataQuery]
    },
    {
      provide: R_TOKEN_FLAG,
      useValue: "R_TOKEN"
    },
    {
      provide: FB_APP_ID,
      useFactory: (query: MetaDataQuery) => {
        return extractSsoAppKey(
          SocialNetwork.FACEBOOK,
          query.current.ssoProviders
        );
      },
      deps: [MetaDataQuery]
    },
    {
      provide: GOOGLE_APP_ID,
      useFactory: (query: MetaDataQuery) => {
        return extractSsoAppKey(
          SocialNetwork.GOOGLE,
          query.current.ssoProviders
        );
      },
      deps: [MetaDataQuery]
    },

    {
      provide: CLIENT_ID,
      useValue: environment.CLIENT_ID
    },
    {
      provide: METADATA,
      useClass: MetaDataQuery
    },
    {
      provide: METADATA_SERVICE,
      useExisting: MetaDataService
    },
    {
      provide: IDLE_TIME,
      useFactory: (query: MetaDataQuery) => {
        const {
          logoutIdleTimeKiosk = DEFAULT_KIOSK_IDLE_TIME,
          logoutIdleTimePlayerPortal = DEFAULT_PP_IDLE_TIME
        } = query.current.kioskMode;
        return { logoutIdleTimeKiosk, logoutIdleTimePlayerPortal };
      },
      deps: [MetaDataQuery]
    },
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitService: AppInitService) => {
        return (): Promise<any> => {
          return appInitService.init();
        };
      },
      deps: [AppInitService],
      multi: true
    },
    {
      provide: UpdateService,
      useFactory: (upd: SwUpdate) =>
        new UpdateService(environment.version, upd, false),
      deps: [SwUpdate]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private injector: Injector,
    private app: ApplicationRef,
    private router: Router,
    private auth: AuthService,
    private toggler: pagesToggleService,
    private profileService: ProfileService,
    private viewportScroller: ViewportScroller,
    private ga: GoogleAnalyticsService,
    @Inject(METADATA) public metaDataQuery: NrtQuery<BootstrapMetaData>
  ) {
    setRootInjector(this.injector);
    this.bootstrap();
    this.processScrollBehavior();
  }

  bootstrap() {
    /Edge\/\d./i.test(navigator.userAgent) &&
      setInterval(() => {
        this.app.tick();
      }, 250);

    this.auth.userLogin$
      .pipe(
        filter(isLogged => !!isLogged),
        switchMap(() => {
          this.toggler.setLoading(true);
          this.toggler.setLoadingStage(LoadingStages.PROFILE);
          return this.profileService
            .getProfile$()
            .pipe(
              finalize(() => this.toggler.setLoading(false))
            ) /* .pipe(skipCache()) */;
        })
        //shareReplay(1)
      )
      .subscribe(profile => {
        //this.toggler.setLoading(false);
        profile && this.auth.setCurrentProfile(profile);
      });
  }

  processScrollBehavior() {
    this.router.events
      .pipe(
        filter(
          (e: Event): e is Scroll =>
            e instanceof Scroll && e.routerEvent instanceof NavigationEnd
        )
      )
      .pipe(
        scan(
          (acc, e) => {
            const navi = this.router.getCurrentNavigation();
            const state = navi?.extras?.state;

            if (state && state.skipScrollToTop) {
              acc.allowScrollTop = false;
            } else if (acc.allowScrollTop) {
              if (e.anchor) {
                this.viewportScroller.scrollToAnchor(e.anchor);
              } else {
                this.viewportScroller.scrollToPosition([0, 0]);
              }
            } else {
              acc.allowScrollTop = true;
            }

            return acc;
          },
          { allowScrollTop: true }
        )
      )
      .subscribe();
  }
}
