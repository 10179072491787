import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { _MESSAGE_DEFAULT_CONFIG_PROVIDER } from "./message-config";
import { MessageContainerComponent } from "./message-container.component";
import { MessageComponent } from "./message.component";
import { pgIconModule } from "../icon/menu-icon.module";

const providers = [_MESSAGE_DEFAULT_CONFIG_PROVIDER];

@NgModule({
  imports: [CommonModule, OverlayModule, pgIconModule],
  declarations: [MessageContainerComponent, MessageComponent],
  providers: [],
  entryComponents: [MessageContainerComponent]
})
export class MessageModule {}
