<!-- WRAP LAYOUT IF BOXED -->
<div [ngClass]="{ desktop: screen.isMd$ | async }">
  <ng-container
    *ngTemplateOutlet="
      contentTpl;
      context: { metaDataParent: metaDataQuery.current$ | async }
    "
  ></ng-container>
</div>

<ng-template #logo let-meta="metaData" let-class="className">
  <ng-container *ngIf="brand$ | async as brand">
    <a (click)="linkClick($event, 'profile/' + brand)">
      <img
        *ngIf="(meta?.branding?.themes?.default?.settings)['rwlv-logo']"
        [src]="(meta?.branding?.themes?.default?.settings)['rwlv-logo']"
        alt="logo"
        [ngClass]="class"
      />
    </a>
  </ng-container>
</ng-template>

<!-- YOUR LAYOUT CONTENT GOES INSIDE HERE -->
<ng-template #contentTpl let-metap="metaDataParent">
  <!-- PAGE CONTAINER -->
  <ngx-headroom>
    <div class="header-wrapper" #topmenu>
      <div class="box a">
        <div class="brand inline p-r-0">
          <ng-container
            *ngTemplateOutlet="
              logo;
              context: { metaData: metap, className: 'brand' }
            "
          ></ng-container>
        </div>
      </div>

      <div class="box c">
        <div
          class="modern-menu"
          [ngClass]="{ 'active-nav': menuOpened }"
          *ngIf="!(screen.isMd$ | async)"
        >
          <button
            type="button"
            class="nav-toggle js-primary-toggle"
            (click)="toggleMenu()"
          >
            <span class="ada-hidden">Menu</span>
          </button>
        </div>
      </div>
      <div class="box b">
        <!-- <div class="container">
          <div class="row menu-line"> -->
        <ul
          class="list"
          [style.pointer-events]="'all'"
          *ngIf="screen.isMd$ | async"
        >
          <li class="items" *ngFor="let m of menu">
            <a
              class="links"
              [class.active]="isLinkActive(m)"
              (click)="linkClick($event, m.routerLink)"
              >{{ m.label }}</a
            >
            <div
              *ngIf="m.label === 'How it works'"
              class="variants variants-desktop"
            >
              <ng-container
                *ngTemplateOutlet="benefits; context: { $p: m }"
              ></ng-container>
            </div>
            <!-- <div *ngIf="m.label === 'Statements'" class="variants variants-desktop">
              <ng-container *ngTemplateOutlet="statementsTmpl; context: { $p: m }"></ng-container>
            </div> -->
          </li>
          <li class="items">
            <a class="links" (click)="logoutDialog()">{{
              "MENU.LOGOUT" | transloco
            }}</a>
          </li>
        </ul>
      </div>
      <!-- </div>
      </div> -->
      <div
        class="digital-buttons"
        [ngClass]="{ 'mobile-buttons': !(screen.isMd$ | async) }"
      >
        <a
          class="links"
          href="https://www.rwlasvegas.com/red-digital-concierge"
          target="_blank"
          [ngClass]="{ 'mobile-hide': !(screen.isMd$ | async) }"
          ><span
            >Red
            <span [ngClass]="{ 'mobile-hide': !(screen.isMd$ | async) }">
              : Digital Concierge</span
            >
          </span>
        </a>
        <a
          class="btn-book book-grad"
          href="https://reservations.rwlasvegas.com/ibe/index.aspx?hotelID=16702&amp;checkin=08/16/2021&amp;checkout=08/18/2021&amp;_ga=2.110081861.1590374843.1629149295-490179562.1629149295&amp;_gac=1.241297974.1629149670.EAIaIQobChMI3KDDub-28gIVDXNvBB1UvQ3tEAAYASAAEgL9ovD_BwE"
          target="_blank"
          ><span
            >Book
            <span [ngClass]="{ 'mobile-hide': !(screen.isMd$ | async) }">
              Now</span
            >
          </span></a
        >
      </div>
    </div>
  </ngx-headroom>

  <ng-container *ngIf="portalOverlayService.overlayTemplate$ | async as tmpl">
    <ng-container [cdkPortalHost]="tmpl"></ng-container>
  </ng-container>

  <div
    class="page-content-wrapper p-b-20 {{ _pageContainerClass }}"
    *ngIf="{ loading: loading$ | async } as q"
  >
    <!-- START PAGE CONTENT -->

    <section
      class="top-header"
      (window:scroll)="onWindowScroll($event)"
      #topheader
    >
      <div class="title-name">
        <h2>{{ title }}</h2>
      </div>
      <div class="floating-pattern"></div>
      <header
        [ngStyle]="{ 'background-image': 'url(' + headerBg + ')' }"
      ></header>
    </section>

    <div class="content {{ contentClass }}" [@fadeAnimation]="o.isActivated">
      <router-outlet #o="outlet"></router-outlet>
    </div>
  </div>
</ng-template>

<div
  bsModal
  #menuModal="bs-modal"
  class="menu-modal modal fill-in"
  [config]="{ backdrop: false, animated: false }"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content-wrapper">
      <div class="modal-content">
        <ul class="list" [style.pointer-events]="'all'">
          <li
            class="items digital-concierge"
            [ngClass]="{ 'mobile-hide': screen.isMd$ | async }"
          >
            <a
              class="links"
              href="https://www.rwlasvegas.com/red-digital-concierge"
              target="_blank"
              ><span>Red </span>
            </a>
          </li>
          <li class="items" *ngFor="let m of menuModal.isShown ? menu : []">
            <div class="title-container">
              <a
                class="links"
                [class.active]="isLinkActive(m)"
                (click)="linkClick($event, m.routerLink)"
                >{{ m.label }}</a
              >
              <div class="arrow-icon-container" (click)="openMenu(m)">
                <p
                  *ngIf="m.haveChild && m.label === 'How it works'"
                  [ngClass]="{ 'revers-icon': openBenefits }"
                ></p>
                <p
                  *ngIf="m.haveChild && m.label === 'Statements'"
                  [ngClass]="{ 'revers-icon': isStatementDropdownOpened }"
                ></p>
              </div>
            </div>

            <div
              *ngIf="m.label === 'How it works'"
              [ngClass]="{ variants: true, visible: openBenefits }"
            >
              <ng-container
                *ngTemplateOutlet="benefits; context: { $p: m }"
              ></ng-container>
            </div>
            <div
              *ngIf="m.label === 'Statements'"
              [ngClass]="{ variants: true, visible: isStatementDropdownOpened }"
            >
              <ng-container
                *ngTemplateOutlet="statementsTmpl; context: { $p: m }"
              ></ng-container>
            </div>
          </li>
          <li class="items">
            <a class="links" (click)="logoutDialog()">{{
              "MENU.LOGOUT" | transloco
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #benefits let-m="$p">
  <a
    class="benefit"
    *ngFor="let b of benefitsY"
    [class.active]="isLinkActive(b)"
    (click)="linkClick($event, m.routerLink + b.routerLink)"
  >
    <div class="sub_menu">{{ b.label }}</div>
  </a>
</ng-template>

<ng-template #statementsTmpl let-m="$p">
  <a
    class="benefit"
    *ngFor="let menuItem of statementsNavigationMenuItems"
    [class.active]="isLinkActive(menuItem)"
    (click)="linkClick($event, m.routerLink + menuItem.routerLink)"
  >
    <div class="sub_menu">{{ menuItem.label }}</div>
  </a>
</ng-template>
