import { AuthService } from "@core/core.module/jwt/auth.service";
import { ChangeDetectorRef, OnInit, NgZone } from "@angular/core";
import { Component, OnDestroy, ChangeDetectionStrategy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { distinctUntilChanged } from "rxjs/operators";
import { AppModeService } from "@core/core.module/services/app-mode.service";

@Component({
  selector: "card-resolver",
  styles: [],
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardResolverComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected cd: ChangeDetectorRef,
    private authService: AuthService,
    private appModeService: AppModeService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.route.params.pipe(distinctUntilChanged()).subscribe(params => {
      this.authService.doLogoutUser();
      this.appModeService.setKioskMode();
      this.router.navigate(["session/login"], {
        state: { cardNumber: params.cardNumber },
        replaceUrl: true
      });
    });
  }

  ngOnDestroy() {}
}
