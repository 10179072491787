import {
  Validators
} from '@angular/forms';
import { FormUtils } from '../form.utils';

export interface MinMaxValidation {
  min: number;
  max: number;
}

function addValidator(
  target: any,
  propertyKey: string,
  config: MinMaxValidation,
  message?: string
) {

  const control = FormUtils.getCreateFormControl(target, propertyKey);

  control.validators.push(
    { name: 'maxlength', validator: Validators.maxLength(config.max), message: message || `Value must be less then ${config.max}` },
    { name: 'minlength', validator: Validators.minLength(config.min), message: message || `Value must be greater then ${config.min}`});
}

export const LenghtValidator = (config: MinMaxValidation, message?: string) => (
  target: any,
  propertyKey: string
) => {
  addValidator(target, propertyKey, config, message);
};
