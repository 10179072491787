
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NrtDialogComponent } from './nrt-dialog/nrt-dialog.component';
import { TranslocoModule } from '@ngneat/transloco';
import { pgIconModule } from '@core/pages-ui.module/components/icon/menu-icon.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [ModalModule, CommonModule, TranslocoModule, pgIconModule],
  declarations: [NrtDialogComponent],
  entryComponents: [NrtDialogComponent],
  providers:[BsModalService]
})
export class NrtDialogsModule {

}
