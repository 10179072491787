import { MessageDataOptions } from "../../../../libs/core/src/lib/pages-ui.module/components/message/message.definitions";
import {
  Injectable,
  Optional
} from "@angular/core";
import { MessageService } from "@core/pages-ui.module/components/message/message.service";
import { IOCMessagesService, MessageType } from "@core/core.module/interfaces";

export class MessagesConfig {
  errorOptions?: MessageDataOptions;
  successOptions?: MessageDataOptions;
  warningOptions?: MessageDataOptions;
}

@Injectable({ providedIn: "root" })
export class MessagesService implements IOCMessagesService {
  private _optionsErrorInfo: MessageDataOptions = {
    Style: "bar",
    Duration: 5000,
    Position: "top",
    Animate: true
  };
  private _optionsSuccess: MessageDataOptions = {
    Style: "bar",
    Duration: 5000,
    Position: "top",
    Animate: true
  };
  private _optionsWarning: MessageDataOptions = {
    Style: "bar",
    Duration: 0,
    Position: "top",
    Animate: true
  };

  constructor(
    private notification: MessageService,
    @Optional() private options?: MessagesConfig
  ) {
    if (options) {
      this._optionsErrorInfo = {
        ...this._optionsErrorInfo,
        ...this.options.errorOptions
      };
      this._optionsSuccess = {
        ...this._optionsSuccess,
        ...this.options.successOptions
      };
      this._optionsWarning = {
        ...this._optionsWarning,
        ...this.options.warningOptions
      };
    }
  }

  private rawOutput(type: MessageType, logMessage: string | any) {
    switch (type) {
      case MessageType.Error:
      case MessageType.RuntimeError: {
        this.notification.error(logMessage, this._optionsErrorInfo);
        break;
      }
      case MessageType.Success: {
        this.notification.success(logMessage, this._optionsSuccess);
        break;
      }
      case MessageType.Log:
      case MessageType.Info: {
        this.notification.info(logMessage, this._optionsErrorInfo);
        break;
      }
      case MessageType.Warning: {
        this.notification.warning(logMessage, this._optionsWarning);
        break;
      }
    }
  }

  info(message: string): void {
    this.rawOutput(MessageType.Info, message);
  }

  success(message: string): void {
    this.rawOutput(MessageType.Success, message);
  }

  warning(message: string): void {
    this.rawOutput(MessageType.Warning, message);
  }

  error(message: string | Object): void {
    this.rawOutput(MessageType.Error, message);
  }

  runtimeError(message: string | Object): void {
    this.rawOutput(MessageType.RuntimeError, message);
  }

  log(message: string): void {
    this.rawOutput(MessageType.Log, message);
  }

  debug(message: string) {
    this.rawOutput(MessageType.Log, message);
  }

  clear(types?: MessageType): void {}
}
