import { StoreConfig } from "@datorama/akita";
import { Injectable } from "@angular/core";
import { NrtStore } from "@core/core.module/services/storage/NrtStore";
import { IProfileState } from "./profile.state";

export const defaultSettings: Partial<IProfileState> = {

};

@StoreConfig({ name: "profile" })
@Injectable({ providedIn: "root" })
export class ProfileStore extends NrtStore<IProfileState> {
  constructor() {
    super(defaultSettings)
  }
}
