import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Input, OnDestroy, Optional } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { DialogType } from "projects/player-portal/common/services/dialog.service";
import { UntilDestroy } from "@core/core.module/decorators/until-destroy.decorator";
import { untilDestroyed } from "@core/core.module/utils/take-until-destroy/take-until-destroy";

@UntilDestroy()
@Component({
  selector: "oc-nrt-dialog",
  templateUrl: "./nrt-dialog.component.html",
  styleUrls: ["./nrt-dialog.component.scss"]
})
export class NrtDialogComponent implements OnInit, OnDestroy {
  @Input() text: string;
  @Input() header: string;
  @Input() type: DialogType;
  @Input() message: string;
  @Input() showCancelButton: boolean;

  okBtnText = 'Ok';
  cancelBtnText = 'Cancel';

  private result$ = new Subject<boolean>();

  result = this.result$.pipe(untilDestroyed(this)).toPromise();

  constructor(public bsModalRef: BsModalRef, @Optional() private translate: TranslocoService) {
    if(translate){
      this.okBtnText = this.translate.translate('@POPUP.OK|Ok');
      this.cancelBtnText = this.translate.translate('@POPUP.CANCEL|Cancel');
    }
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  private setResult(result: boolean) {
    this.result$.next(result);
    this.result$.complete();

    this.hide();
  }

  ok() {
    this.setResult(true);
  }

  cancel() {
    this.setResult(false);
  }

  yes() {
    this.setResult(true);
  }

  no() {
    this.setResult(false);
  }

  hide() {
    this.bsModalRef.hide();
  }
}
