<div [attr.class]="'alert-' + type">
  <div class="modal-header text-left">
    <!-- <button type="button" class="close" (click)="hide()"><i [attr.class]="'pg pg-close fs-14 alert-' + type"></i></button> -->

    <div class="close">
      <pg-menu-icon
        IconType="clr"
        [Size]="24"
        IconName="times"
        (click)="hide()"
      >
      </pg-menu-icon>
    </div>

    <h5 [attr.class]="'alert-' + type">
      <!-- <i class="p-r-10 fa" [ngClass]="{
        'info':'fa-info',
        'warning':'fa-warning',
        'error':'fa-exclamation-circle',
        'confirm':'fa-check',
        'success':'fa-check-circle',
        'ask':'fa-question'
      }[type]"></i> -->

      <pg-menu-icon
        IconType="clr"
        [Size]="24"
        [Class]="
          {
            info: 'is-highlight',
            warning: 'is-warning',
            error: 'is-error',
            confirm: 'is-warning',
            success: 'is-success',
            ask: 'is-highlight'
          }[type]
        "
        [style.margin-right.px]="5"
        [IconName]="
          {
            info: 'info-standard',
            warning: 'warning-standard',
            error: 'error-standard',
            confirm: 'warning-standard',
            success: 'success-standard',
            ask: 'help'
          }[type]
        "
      >
      </pg-menu-icon>
      {{ header }}
    </h5>
  </div>

  <div class="modal-body">
    <div [innerHTML]="text"></div>
    <div class="pull-right p-t-15">
      <div *ngIf="type === 'confirm'" class="p-b-25">
        <button
          type="button"
          class="btn m-r-10 btn-primary"
          tooltip="Ok"
          (click)="ok()"
        >
          {{ okBtnText }}
        </button>
        <button
          type="button"
          class="btn m-r-10 btn-default"
          tooltip="Cancel"
          (click)="cancel()"
        >
          {{ cancelBtnText }}
        </button>
      </div>
      <div *ngIf="type === 'ask'" class="p-b-25">
        <button
          type="button"
          class="btn m-r-10 btn-primary"
          tooltip="Yes"
          (click)="yes()"
        >
          Yes
        </button>
        <button
          type="button"
          class="btn m-r-10 btn-danger"
          tooltip="No"
          (click)="no()"
        >
          No
        </button>
        <button
          type="button"
          class="btn m-r-10 btn-default"
          tooltip="Cancel"
          (click)="cancel()"
          *ngIf="showCancelButton"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
