import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { RippleDirective } from "@core/core.module/directives/ripple/ripple.directive";

const animationDuration = 500;

@Component({
  selector: "pgcard",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./card.component.html",
  animations: [
    trigger("collapseState", [
      state(
        "inactive",
        style({
          opacity: "0",
          display: "none",
          height: 0,
          paddingBottom: "0"
        })
      ),
      state(
        "active",
        style({
          opacity: "1",
          display: "block",
          height: "*",
          paddingBottom: "*"
        })
      ),
      transition("inactive => active", animate("125ms ease-in")),
      transition("active => inactive", animate("125ms ease-out"))
    ]),
    trigger("fadeAnimation", [
      state(
        "false",
        style({
          opacity: "0",
          visibility: "hidden"
        })
      ),
      state(
        "true",
        style({
          opacity: "1",
          visibility: "visible"
        })
      ),
      transition("false => true", animate(`${animationDuration}ms ease-in`)),
      transition("true => false", animate(`${animationDuration}ms ease-out`))
    ])
  ]
})
export class pgCard {
  _isCollapsed = false;
  _isMaximixed = false;
  _isLoading = false;
  _watermark: TemplateRef<void>;
  _minimalHeader = false;
  _message = "";
  _messageType = "danger";
  _messageVisible = false;
  _headerVisible = true;
  _progressType: "bar" | "circleready" | "circle" = "circle";
  _progressColor = "";
  _showTools = true;
  _close_card = false;
  _refresh = true;
  _refreshColor = "light";
  _close = true;
  _toggle = true;
  _maximize = true;
  _timeout = 0;
  _titleText = "";
  _type = "default";

  _extraHeaderClass = "";
  _extraBodyClass = "";

  _additionalClasses = "";

  @ViewChild("appRipple", { static: false })
  ripple: RippleDirective;

  @ViewChild("hostContent", { static: false }) _hostContent: ElementRef;
  @ViewChild("minimalCircleLoading", { static: false })
  minimalCircleLoading: ElementRef;
  @ViewChild("minimalCircleLoadingTrigger", { static: false })
  minimalCircleLoadingTrigger: ElementRef;
  @ContentChild("CardTitle", { static: false }) CardTitle: TemplateRef<void>;
  @ContentChild("CardExtraControls", { static: false })
  CardExtraControls: TemplateRef<void>;
  _cardStyle: string;
  _bodyStyle: string;

  _zoom = 1;
  _disableLoadingOverlay: boolean;
  @Input()
  set Title(value: string) {
    this._titleText = value;
  }
  get Title() {
    return this._titleText;
  }

  @Input()
  set Type(value: string) {
    this._type = value;
  }

  @Input()
  set MinimalHeader(value: boolean) {
    this._minimalHeader = value;
  }

  @Input()
  set ProgressType(value: "circle" | "bar" | "circleready") {
    this._progressType = value;
  }

  @Input()
  set ProgressColor(value: string) {
    this._progressColor = value;
  }

  @Input()
  set Zoom(value: number) {
    this._zoom = value;
  }

  @Input()
  set Refresh(value: boolean) {
    this._refresh = value;
  }

  @Input()
  set RefreshColor(value: string) {
    this._refreshColor = value;
  }

  @Input()
  set Maximize(value: boolean) {
    this._maximize = value;
  }

  @Input()
  set Close(value: boolean) {
    this._close = value;
  }

  @Input()
  set Toggle(value: boolean) {
    this._toggle = value;
  }

  @Input()
  set Collapsed(value: boolean) {
    this._isCollapsed = value;
  }

  @Input()
  set HeaderClass(value: string) {
    this._extraHeaderClass = value;
  }

  @Input()
  set BodyClass(value: string) {
    this._extraBodyClass = value;
  }

  @Input()
  set AdditionalClasses(value: string) {
    this._additionalClasses = value;
  }

  @Input()
  set CardStyle(value: string) {
    this._cardStyle = value;
  }

  @Input()
  set BodyStyle(value: string) {
    this._bodyStyle = value;
  }

  @Input()
  set Controls(value: boolean) {
    this._showTools = value;
  }

  @Input()
  set ShowMessage(value: boolean) {
    this._messageVisible = value;
  }

  @Input()
  set DisableLoadingOverlay(value: boolean) {
    this._disableLoadingOverlay = value;
  }

  @Input()
  set ShowHeader(value: boolean) {
    this._headerVisible = value;
  }

  @Input()
  set Message(value: string) {
    this._message = value;
  }

  @Input()
  set Loading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  @Input()
  set Watermark(value: TemplateRef<void>) {
    this._watermark = value;
  }

  @Input()
  set TimeOut(value: number) {
    this._timeout = value;
  }

  @Input() ShowRipple = false;

  @Input() CardType: "info" | "warning" | "error" | "success";

  constructor(private cd: ChangeDetectorRef) {}

  @Output() onRefresh: EventEmitter<void> = new EventEmitter();
  @Output() onToggle: EventEmitter<boolean> = new EventEmitter();
  @Output() onClick: EventEmitter<boolean> = new EventEmitter();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  @HostListener("@collapseState.done") onDoneCollapseAnimation($event) {
    this.onToggle.next(this._isCollapsed);
  }

  @HostListener("click", ["$event"]) click($event) {
    this.ripple.ripple($event);
  }

  toggle(): void {
    this._isCollapsed = this._isCollapsed === true ? false : true;
  }

  maximize(): void {
    const nativeElement = this._hostContent.nativeElement;
    if (this._isMaximixed) {
      this._isMaximixed = false;
      nativeElement.style.left = null;
      nativeElement.style.top = null;
    } else {
      this._isMaximixed = true;
      const pagecontainer = document.querySelector(".content");
      console.log(pagecontainer);
      const rect = pagecontainer.getBoundingClientRect();
      // let elementRect = nativeElement.getBoundingClientRect();
      const style = window.getComputedStyle(pagecontainer);

      nativeElement.style.left =
        parseFloat(style["marginLeft"]) +
        parseFloat(style["paddingLeft"]) +
        rect.left +
        "px";
      nativeElement.style.top =
        parseFloat(style["padding-top"]) + rect.top + "px";
    }
  }
  alertDismiss(): void {
    this._messageVisible = false;
  }
  refresh(): void {
    if (!this._isLoading) {
      this._isLoading = true;
      this.onRefresh.emit();
    }
    if (this._timeout > 0) {
      setTimeout(() => {
        this._isLoading = false;
      }, this._timeout);
    }

    this.cd.detectChanges();
  }
  close(): void {
    this._close_card = true;
    this.onClose.emit();
  }
}
