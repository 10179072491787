import { enableProdMode, PLATFORM_INITIALIZER } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import {
  enableAkitaProdMode,
  persistState,
  akitaConfig
} from "@datorama/akita";
import "@clr/icons";

import '@clr/icons/shapes/essential-shapes';
import '@clr/icons/shapes/social-shapes';
import '@clr/icons/shapes/media-shapes';
import '@clr/icons/shapes/technology-shapes';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

akitaConfig({
  resettable: true
});

window["akita_store"] = persistState({
  // exclude: [
  //   "player-rewards",
  //   "locations",
  //   "tiers",
  //   "profile",
  //   "location-owners",
  //   "elite-comps",
  //   "elite-offers",
  //   "external-profile",
  //   "coupon-book",

  //   "offers",
  //   "invitations",
  //   "catalog",
  //   "medallions",
  //   "profile"
  // ]
  include: [
    "settings",
    "meta-data"
  ]
});

async function loadIcons() {
  return Promise.all([
    import("@clr/icons/shapes/essential-shapes"),
    import("@clr/icons/shapes/social-shapes"),
    import("@clr/icons/shapes/commerce-shapes"),
    import("@clr/icons/shapes/media-shapes")
  ]);
}

loadIcons();

platformBrowserDynamic()
  .bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
  .catch(err => console.log(err));
