import { InjectionToken } from "@angular/core";
import { SetupSignleton } from "../decorators/setup-singleton.decorator";

export const MESSAGES_SERVICE_TOKEN = new InjectionToken("IOCMessagesService");

export enum MessageType {
  Info = "info",
  Error = "danger",
  Success = "success",
  Warning = "warning",
  Log = "log",
  RuntimeError = "RuntimeError"
}

export interface IOCMessagesService {
  /**
   * Displays informational message to console and to informational messages panel, if it is avaliable.
   * @param message informational message text
   * @param closeAfter Time to wait for closing the message
   */
  info(message: string): void;

  /**
   * Displays informational message to console and to informational messages panel, if it is avaliable.
   * @param message informational message text
   * @param closeAfter Time to wait for closing the message
   */
  success(message: string): void;

  /**
   * Displays warning message to console and to informational messages panel, if it is avaliable.
   * @param message informational message text
   * @param closeAfter Time to wait for closing the message
   */
  warning(message: string): void;

  /**
   * Displays error message to console and to informational messages panel, if it is avaliable.
   * @param message Error text or object from which the text will be extracted as obj.responseText or obj.status + ' ' + obj.statusText
   * @param closeAfter Время, после которого окно с ошибкой будет закрыто
   */
  error(message: string | Object): void;

  /**
   * Displays runtime error message to console and to informational messages panel, if it is avaliable.
   * Use this type exactly for runtime errors, not for application errors.
   * These error displays only in debug mode.
   * @param message Error text or object from which the text will be extracted as obj.responseText or obj.status + ' ' + obj.statusText
   * @param closeAfter Время, после которого окно с ошибкой будет закрыто
   */
  runtimeError(message: string | Object): void;

  /**
   * Displays string in console.
   * @param message Text to display in console.
   */
  log(message: string): void;

  // ReSharper disable once InconsistentNaming
  /**
   * Displays string in console only in debug mode (if App.IsDebug == true).
   * @param message Text to display in console.
   */
  debug(message: string);

  /**
   * Removes all messages of particular type.
   * If no types not set - all messages will be removed.
   */
  clear(types?: MessageType): void;
}

export class DefaultMessagesService implements IOCMessagesService {
  private rawOutput(type: MessageType, logMessage: string | any) {
    let errorType = "";
    switch (type) {
      case MessageType.Error:
      case MessageType.RuntimeError:
        errorType = "error";
        break;
      case MessageType.Info:
        errorType = "info";
        break;
      case MessageType.Warning:
        errorType = "warn";
        break;
      case MessageType.Success:
        errorType = "log";
        break;
    }

    errorType && console[errorType](logMessage);
  }

  info = (message: string) => {
    console.info(message);
    this.rawOutput(MessageType.RuntimeError, message);
  }

  success = (message: string) => {
    console.info(message);
    this.rawOutput(MessageType.RuntimeError, message);
  }

  warning = (message: string) => {
    console.warn(message);
    this.rawOutput(MessageType.RuntimeError, message);
  }

  error = (message: string | Object) => {
    console.error(message);
    this.rawOutput(MessageType.RuntimeError, message);
  }

  runtimeError = (message: string | Object) => {
    console.error(message);
    this.rawOutput(MessageType.RuntimeError, message);
  }

  log = (message: string) => {
    console.log(message);
  }

  debug = (message: string) => {
    console.debug(message);
  }

  clear = (types?: MessageType) => {}
}

export class CoreMessagesService implements IOCMessagesService {
  info: (message: string) => void;
  success: (essage: string) => void;
  warning: (essage: string) => void;
  error: (essage: string | Object) => void;
  runtimeError: (essage: string | Object) => void;
  log: (essage: string) => void;
  debug: (essage: string) => void;
  clear: (types?: MessageType) => void;

  @SetupSignleton(MESSAGES_SERVICE_TOKEN, DefaultMessagesService)
  static instance: IOCMessagesService = null;
}

export const msg: IOCMessagesService = new CoreMessagesService();

export function clear() {
  msg.clear();
}
export function error(message: string) {
  msg.error(message);
}
export function info(message: string) {
  msg.info(message);
}
export function warning(message: string) {
  msg.warning(message);
}
export function runtimeError(message: string) {
  msg.runtimeError(message);
}
export function success(message: string) {
  msg.success(message);
}
export function log(message: string) {
  msg.log(message);
}
export function debug(message: string) {
  msg.debug(message);
}
