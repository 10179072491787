import { EntityState, Query } from "@datorama/akita";
import { Observable } from "rxjs";

export abstract class NrtQuery<
  TState extends EntityState
> extends Query<TState> {

  get current$(): Observable<TState> {
    return this.select(x => x);
  }

  get current(): TState {
    return this.getValue();
  }

}
