import { Inject, Injectable, NgZone } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { BrandingClient } from "@core/core.module/branding/branding";
import { BrandingSettings } from "@core/core.module/branding/BrandingSettings";
import { AuthService } from "@core/core.module/jwt/auth.service";
import { LOGIN_PATH } from "@core/core.module/jwt/injection-tokens";
import { AppModeService } from "@core/core.module/services/app-mode.service";
import { getInjectableInstance } from "@core/core.module/utils/root-injector";
import { Observable, of } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { MetaDataService } from "./services/api/clientMetaData/metaData.service";
@Injectable({ providedIn: "root" })
export class BrandingLayoutGuard implements CanActivate, CanActivateChild {

  constructor(
    private auth: AuthService,
    private router: Router,
    private clientMetaData: MetaDataService,
    private zone: NgZone,
    private appModeService: AppModeService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const h = route.queryParamMap.get("h");
    const patronId = route.queryParamMap.get("patronId");
    const terminalId = route.queryParamMap.get("terminalId");
    const suffix = (terminalId) ? `&terminalId=${terminalId}` : ''
    if (h && patronId) {
      const encodedHash = encodeURIComponent(h);
      if (~route.url[0].path.indexOf("dummy")) {
        this.zone.run(() =>
          this.router.navigateByUrl(
            `dummy?h=${encodedHash}&patronId=${patronId}`
          )
        );
      } else {
        this.zone.run(() =>
          this.router.navigateByUrl(
            `device?h=${encodedHash}&patronId=${patronId}` + suffix
          )
        );
      }

      return of(true);
    }
    return this.clientMetaData.query
      .select(x => x.currentBranding)
      .pipe(
        distinctUntilChanged(),
        map((brand: BrandingClient) => {
          let layout = "condensed";
          let prefix = "";

          const sett = getInjectableInstance(BrandingSettings);

          //TODO: move these to correspondent branding settings class
          switch (brand) {
            case "tp":
              if (state.url.startsWith("/kiosk")) {
                this.appModeService.setTabletMode();
              } else if (this.appModeService.isTabletMode()) {
                prefix = "kiosk/";
              }
              break;
            case "coyote":
              if (state.url.startsWith("/kiosk")) {
                this.appModeService.setKioskMode();
              } else if (this.appModeService.isKioskMode()) {
                prefix = "kiosk/";
                layout = "lucid";
              }
              break;
            case "rwlv":
              if (state.url.startsWith("/kiosk")) {
                this.appModeService.setKioskMode();
                prefix = "kiosk/";
                layout = "lucid";
              } else if (this.appModeService.isKioskMode()) {
                prefix = "kiosk/";
                layout = "lucid";
              } else {
                layout = "mobile";
              }
              break;
            case "offercraft":
              if (state.url.startsWith("/kiosk")) {
                this.appModeService.setKioskMode();
                prefix = "kiosk/";
                layout = "lucid";
              } else if (this.appModeService.isKioskMode()) {
                prefix = "kiosk/";
                layout = "lucid";
              } else {
                layout = "condensed";
              }
              break;
            case "naskila":
              if (state.url.startsWith("/kiosk")) {
                this.appModeService.setKioskMode();
                prefix = "kiosk/";
                layout = "lucid";
              } else if (this.appModeService.isKioskMode()) {
                prefix = "kiosk/";
                layout = "lucid";
              } else {
                layout = "condensed";
              }
              break;
            default:
          }

          if (!brand || !this.auth.isLoggedIn) {
            //return this.resolveLoginPage(state.url);
            return sett?.behavior?.resolveLoginPage(state.url);
          } else {
            switch (state.url) {
              case "/":
              case "/kiosk":
              case "/lucid/home":
              case "/mobile/home":
              case "/condensed/home":
              case "/kiosk/lucid/home":
              case "/kiosk/condensed/home":
                this.zone.run(() =>
                  this.router.navigateByUrl(`${prefix}${layout}/home/${brand}`)
                );
            }
          }
          return true;
        })
      );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
