import { Injectable } from "@angular/core";
import { NgControl } from "@angular/forms";
import { FieldType } from "@core/core.module/base/IFormFieldMetadata";
import { NrtFormControl } from "@core/core.module/base/NrtFormControl";
import { BehaviorSubject, combineLatest, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { filter } from "rxjs/operators/filter";
import Keyboard from "simple-keyboard";

@Injectable({ providedIn: "root" })
export class KeyboardService {
  private _targetInputControl$ = new ReplaySubject<
    NgControl | HTMLInputElement
  >(1);
  targetInputControl$ = this._targetInputControl$.asObservable();

  private _inputValue$ = new BehaviorSubject<string>("");
  inputValue$ = combineLatest(
    this._inputValue$,
    this._targetInputControl$
  ).pipe(
    filter(([, control]) => !!control),
    map(([input]) => input)
  );

  private _input: NgControl | HTMLInputElement;
  private _enabled: boolean = false;

  public keyBoard: Keyboard = null;

  // private static _instance: KeyboardService;
  // static get Instance(): KeyboardService {
  //   !KeyboardService._instance &&
  //     (KeyboardService._instance = new KeyboardService());
  //   return KeyboardService._instance;
  // }

  get targetInput(): NgControl | HTMLInputElement {
    return this._input;
  }

  public readonly isVisible$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  set isVisible(isVisible: boolean) {
    this.isVisible$.next(isVisible);
    !isVisible && this.reset();
  }
  get isVisible() {
    return this.isVisible$.getValue();
  }

  constructor() {
    combineLatest(this._targetInputControl$, this._inputValue$)
      .pipe(
        filter(
          ([target, value]) =>
            this._enabled && !!target && value !== null && value !== undefined
        )
      )
      .subscribe(([target, value]) => {
        if (target instanceof HTMLInputElement) {
          const type = target.type as FieldType;
          const val = this.transformValueByType(value, type);
          target.value = val;
        } else if (target instanceof NgControl) {
          const type =
            (target.control as NrtFormControl)?.metadata?.type || "string";
          const val = this.transformValueByType(value, type);
          target.control.patchValue(val);
        } else {
          throw new Error(`Invalid control attached!`);
        }
      });
  }

  transformValueByType(value: any, type: FieldType) {
    let outputValue = "";

    switch (type) {
      case "number": {
        console.warn(
          "Don't use NUMBER type with virtual keyboard! It's better to use TEXT type with regex. Current implementation will filter only digits. "
        );
        outputValue = value.replace(/[^0-9]/g, "");
        break;
      }
      default: {
        outputValue = value;
        break;
      }
    }

    return outputValue;
  }

  disable() {
    this._enabled = false;
  }

  enable() {
    this._enabled = true;
  }

  setTarget(input: NgControl | HTMLInputElement) {
    this._input = input;
    this._targetInputControl$.next(null);
    this._inputValue$.next(this._input.value);
    this._targetInputControl$.next(this._input);
  }

  setValue(value: string) {
    if (value === null) this.keyBoard?.clearInput();
    this._inputValue$.next(value);
  }

  reset() {
    this._input = null;
    this._targetInputControl$.next(null);
    this._inputValue$.next("");
  }

  hideKeyboard() {
    this.isVisible = false;
  }
}
