import { Type } from "@angular/core";
import { ISerializer } from "./ISerializer";
import { AppFormDeclaration } from "../decorators/forms/app-form.decorator";
import { FormUtils } from "../decorators/forms/form.utils";
import * as dayjs from "dayjs";
import { memoize } from "../decorators/memoize.decorator";

export class Serializer<T> implements ISerializer<T> {
  form: AppFormDeclaration;

  constructor(protected type: Type<T>) {
    this.form = FormUtils.getFormDeclaration(this.type);
  }

  @memoize()
  static for<T>(type: Type<T>) {
    return new Serializer(type);
  }
  fromDTO(dto: { [key: string]: any }): T {
    return this.form
      ? this.patch(new this.type(), dto)
      : Object.assign(new this.type(), dto);
  }
  toDTO(object: T): T {
    let dto = object;
    if (!(object instanceof this.type)) {
      dto = this.patch(new this.type(), object);
    }

    return dto;
  }

  patch(
    instance: T,
    dto: { [key: string]: any },
    form?: AppFormDeclaration
  ): T {
    for (const key in dto) {
      if (dto.hasOwnProperty(key)) {
        const prop = dto[key];
        const control = (form || this.form).controls[key];
        if (control && control.metadata.refType) {
          if (control.metadata.type === "array") {
            instance[key] = prop.map(x =>
              this.patch(
                new control.metadata.refType(),
                x,
                FormUtils.getFormDeclaration(control.metadata.refType)
              )
            );
          } else {
            instance[key] = this.patch(
              new control.metadata.refType(),
              prop,
              FormUtils.getFormDeclaration(control.metadata.refType)
            );
          }
        } else {
          if (
            control &&
            control.metadata.type === "datetime" &&
            control.metadata.format
          ) {
            instance[key] = prop
              ? dayjs(prop).format(control.metadata.format)
              : prop;
          } else {
            instance[key] = prop;
          }
        }
      }
    }

    return instance;
  }
}
