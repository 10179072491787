import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ContainerComponent } from './container/container.component';
import { pageContainer } from './page-container/page-container.component';
@NgModule({
  imports: [CommonModule, FormsModule],
  entryComponents: [ContainerComponent, pageContainer],
  declarations: [ContainerComponent, pageContainer],
  exports: [ContainerComponent, pageContainer]
})
export class ContainerModule {}
