import { Injectable, ErrorHandler, isDevMode } from '@angular/core';
import { msg } from '../interfaces/core-messages.service';

@Injectable()
export class OCErrorHandler extends ErrorHandler {
    handleError(error: any): void {
        if (error.rejection) {
            this.handleError(error.rejection);
        } else {
            if (isDevMode()) {
                setTimeout(() => msg.runtimeError(error));
            }
            super.handleError(error);
        }
    }
}
