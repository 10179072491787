import { BootstrapMetaData } from "../MetaData";
import { Injectable } from "@angular/core";
import { MetaDataStore } from "./metaData.store";
import { NrtQuery } from "@core/core.module/services/storage/NrtQuery";

@Injectable({ providedIn: "root" })
export class MetaDataQuery extends NrtQuery<BootstrapMetaData> {
  constructor(protected store: MetaDataStore) {
    super(store);
  }
}
