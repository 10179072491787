import { FormArray } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IFormFieldMetadata } from './IFormFieldMetadata';
import { TranslocoService } from '@ngneat/transloco';
import { dirtyCheck } from '../operators/dirtyCheck';
import { INrtAbstractControl } from './INrtAbstractControl';
import { NrtMissingTranslationHandler } from './NrtMissingTranslationHandler';

export class NrtFormArray extends FormArray implements INrtAbstractControl {
  private _translateSub: Subscription;
  private _metadata: IFormFieldMetadata;
  get metadata(): Readonly<IFormFieldMetadata> {
    return this._metadata;
  }

  name: string;

  private _controls: INrtAbstractControl[];
  private _controlsMap: Map<string, INrtAbstractControl> = new Map();

  get controlsMap(): Map<string, INrtAbstractControl> {
    return this._controlsMap;
  }

  set controls(val: INrtAbstractControl[]) {
    this._controls = val;
    this.metadata?.key && (this._controlsMap = new Map(this._controls.map(x => [x.value[this.metadata.key], x])));
  }

  get controls(): INrtAbstractControl[] {
    return this._controls;
  }

  private _controls$: BehaviorSubject<
    INrtAbstractControl[]
  > = new BehaviorSubject([]);

  // private _store$ = new BehaviorSubject(undefined);
  // store$: Observable<any> = this._store$.asObservable();

  // isDirty$ = (store$?: Observable<any>) =>
  //   this.valueChanges.pipe(dirtyCheck(store$ || this.store$));

  controls$: Observable<
    INrtAbstractControl[]
  > = this._controls$.asObservable().pipe(
    map(x => {
      return Object.values(x).sort((a, b) => {
        return a.metadata &&
          b.metadata &&
          (a.metadata.order || 0) < (b.metadata.order || 0)
          ? -1
          : 1;
      });
    })
  );

  constructor(
    name?: string,
    controls?: INrtAbstractControl[],
    metadata?: IFormFieldMetadata
  ) {
    super(controls || []);
    this.name = name;

    metadata && metadata.defaultValue && this.setValue(metadata.defaultValue);
    this._metadata = metadata || {};

    controls && this._controls$.next(Object.values(controls));
    this.metadata?.key && (this._controlsMap = new Map(controls.map(x => [x.value[this.metadata.key], x])));
  }

  // translate(translateService?: TranslocoService): this {
  //   this.controls.forEach(c => {
  //     c.translate(translateService);
  //   });

  //   return this.translate(translateService);
  // }

  private translateInternal(
    translateService?: TranslocoService,
    props: string[] = ["title", "placeholder"]
  ) {
    props.forEach(item => {
      const meta = this._metadata[item];

      meta &&
        (this._metadata[item] = translateService
          ? translateService.translate(meta)
          : NrtMissingTranslationHandler.extractFallback(meta));
    });
  }

  translate(translateService?: TranslocoService) {
    this._translateSub && this._translateSub.unsubscribe();

    if (translateService && !this._translateSub) {
      this._translateSub = translateService.langChanges$.subscribe(x => {
        this.translateInternal(translateService);
      });
    }

    this.translateInternal(translateService);

    return this;
  }

  clone() {
    const arr = [];
    this.controls.forEach(c => {
      arr.push(c.clone());
    });

    return new NrtFormArray(this.name, [...this.controls]);
  }

  destroy(controls?: INrtAbstractControl[]) {
    const ctrls = controls || this.controls;

    for (const ctrlKey in ctrls) {
      for (const key in ctrls[ctrlKey]) {
        if (this.controls.hasOwnProperty(key)) {
          const element = ctrls[key];

          element.destroy();
        }
      }
    }
  }

  patchValue(
    value: any[],
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ) {
    super.patchValue(value, options);
  }

  addControl(control: INrtAbstractControl) {
    super.push(control);
    this.metadata.key && this._controlsMap?.set(control.value[this.metadata.key], control);
    this._controls$.next(Object.values(this.controls));
  }

  markAllAsDirty(controls?: { [key: string]: INrtAbstractControl }) {
    const ctrls = controls || this.controls;

    for (const ctrlKey in ctrls) {
      for (const key in ctrls[ctrlKey]) {
        if (this.controls.hasOwnProperty(key)) {
          const element = ctrls[key];

          element.markAsDirty();
        }
      }
    }
  }

}
