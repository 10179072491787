export namespace DateTime {

    /**
        * Converts Newton.Json string and Asp.Net string into Date.
    */
    export function ToDate(obj: string): Date {
        let res: Date = <any>obj;
        if (obj.length >= 19
            && obj[4] === '-'
            && obj[7] === '-'
            && obj[10] === 'T'
            && obj[13] === ':'
            && obj[16] === ':'
        ) {
            try { res = new Date(obj); } catch (e) { }
        } else if (obj.indexOf('/Date(') === 0) {
            // tslint:disable-next-line:radix
            try { res = new Date(parseInt(obj.substr(6))); } catch (e) { }
        }
        return res;
    }

    export function toUTC(date: Date): Date {
        var now_utc =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
        date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
       
        return new Date(now_utc);
    }
}


