import {
  Injectable,
  Compiler,
  Injector,
  Type,
  ViewContainerRef,
  NgModuleFactory,
  Inject,
  TemplateRef
} from "@angular/core";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { tap, filter, withLatestFrom } from "rxjs/operators";
import { filterNil } from "@datorama/akita";

@Injectable({ providedIn: "root" })
export class SubHeaderLoaderService {
  private _template = new Subject<TemplateRef<any>>();
  private _header = new Subject<ViewContainerRef>();

  private _sub = combineLatest(this._template, this._header)
    .subscribe(([template, headerContainer]) => {
      if (template) {
        headerContainer.clear();
        const view = headerContainer.createEmbeddedView(template);
        view.detectChanges();
      }
    });

  set template(val: TemplateRef<any>) {
    this._template.next(val);
  }

  constructor() {}

  load(headerContainer: ViewContainerRef) {
    this._header.next(headerContainer);
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
