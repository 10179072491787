import { Injectable, InjectionToken, Inject, Optional } from "@angular/core";
import {
  BehaviorSubject,
  merge,
  from,
  Observable,
  Subject,
  interval,
  of,
  empty
} from "rxjs";
import { SwUpdate } from "@angular/service-worker";
import { distinctUntilChanged } from "rxjs/operators/distinctUntilChanged";
import {
  concatMap,
  exhaustMap,
  tap,
  mapTo,
  startWith,
  withLatestFrom
} from "rxjs/operators";

export const APP_VERSION = new InjectionToken<string>("APP_VERSION");

export declare type UpdateOptions = true | "reload";

@Injectable()
export class UpdateService {
  private currentAppVersion = localStorage.getItem("APP_VERSION");

  private _updateAvailable$ = new Subject();
  private _initiateUpdate$ = new Subject<UpdateOptions>();
  private _swCheckForUpdates$ = new Subject();

  private _swUpdateAvailable$ = this.swUpdate.available.pipe(startWith(false));

  updateAvailable$ = merge(
    this._swUpdateAvailable$,
    this._updateAvailable$
  ).pipe(distinctUntilChanged());

  private _updatingApp$ = this._initiateUpdate$.pipe(
    withLatestFrom(this._swUpdateAvailable$),
    exhaustMap(([upd, isSwUpdAvail]) => {
      return from(
        this.swUpdate.isEnabled && isSwUpdAvail
          ? this.swUpdate.activateUpdate()
          : Promise.resolve()
      ).pipe(tap(() => upd === "reload" && window.location.reload()));
    })
  );

  private _checkingUpdates$ = this._swCheckForUpdates$.pipe(
    exhaustMap(() => from(this.swUpdate.checkForUpdate()))
  );

  constructor(
    @Optional() @Inject(APP_VERSION) private appVersion: string,
    private swUpdate: SwUpdate,
    private clearStorage = true
  ) {
    this._updatingApp$.subscribe();
    this._checkingUpdates$.subscribe();
    this.updateAvailable$.subscribe();

    // setTimeout(() => {
    //   this._updateAvailable$.next(true);
    // }, 2000);
  }

  checkForUpdates() {
    //this.update.checkForUpdate();
    this.swUpdate.isEnabled && this._swCheckForUpdates$.next(true);

    if (!this.currentAppVersion) {
      this.currentAppVersion = this.appVersion;
    } else {
      if (this.currentAppVersion !== this.appVersion) {
        if (this.clearStorage) {
          const store = window["akita_store"];
          store ? store.clear() : localStorage.removeItem("AkitaStores");
        }
        this._updateAvailable$.next(true);
      }
    }

    localStorage.setItem("APP_VERSION", this.appVersion);
  }

  startUpdate(options: UpdateOptions = "reload"): Observable<void> {
    this._initiateUpdate$.next(options);
    return this._updatingApp$;
  }
}
