import {
  InjectableType,
  ɵComponentType as ComponentType,
  ɵDirectiveType as DirectiveType,
  ɵComponentDef as ComponentDef,
  ɵDirectiveDef as DirectiveDef
} from '@angular/core';

export const IS_DECORATOR_APPLIED = '__decoratorApplied';
export const DESTROY_SUBJECT_NAME = '__takeUntilDestroy$';

export function isInjectableType(target: any): target is InjectableType<unknown> {
  return !!target.ɵprov;
}

export function getDef<T>(
  type: ComponentType<T> | DirectiveType<T>
): ComponentDef<T> | DirectiveDef<T> {
  return  (type as DirectiveType<T>).ɵdir || (type as ComponentType<T>).ɵcmp;
}

export function ensureClassIsDecorated(instance: any): void {
  const constructor = instance.constructor;
  const providerOrDef = isInjectableType(constructor) ? constructor : getDef(constructor);
  const missingDecorator = !(providerOrDef as any)[IS_DECORATOR_APPLIED];

  if (missingDecorator) {
      throw new Error('untilDestroyed operator cannot be used inside directives or ' +
          'components or providers that are not decorated with UntilDestroy decorator');
  }
}
