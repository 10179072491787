import { getInjectableInstance } from "@core/core.module/utils/root-injector";
import { BrandingSettings } from "./BrandingSettings";

export declare type BrandingClient =
  | "tp"
  | "offercraft"
  | "naskila"
  | "elite"
  | "bally"
  | "coyote"
  | "rwlv";
export declare type BrandingAction =
  | "add"
  | "edit"
  | "external"
  | "management"
  | "behavior";

export declare type TBrandingSettings<
  T = BrandingSettings
> = T extends BrandingSettings ? T : never;

export abstract class Branding {
  private static _brandingSettings: TBrandingSettings;

  static get<T>(selector: (sett: TBrandingSettings) => T): T {
    Branding._brandingSettings =
      Branding._brandingSettings || getInjectableInstance(BrandingSettings);
    return selector(Branding._brandingSettings);
  }
}
