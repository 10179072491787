export enum MetadataType {
  ClientAccount = "ClientAccount",
  PlayerProfileSettings = "PlayerProfileSettings",
  SignUpFields = "SignUpFields",
  SignUpOptions = "SignUpOptions",
  SsoProviders = "SsoProviders",
  UserDataMaskingPendingDays = "UserDataMaskingPendingDays",
  TermsAndConditions = "TermsAndConditions",
  GoogleAnalytics = "GoogleAnalytics",
  ExternalRewards = "ExternalRewards",
  ExternalProfile = "ExternalProfile",
  GoogleTagManager = "GoogleTagManager",
  Branding = "Branding",
  RewardsManagement = "RewardsManagement",
  WorksReport = "WorksReport",
  KioskMode = "KioskMode",
  KioskLobbyToken = "KioskLobbyToken",
  KioskMedallionsLobbyConfig = "KioskMedallionsLobbyConfig",
  KioskMilestonesConfig = "KioskMilestonesConfig",
  PlayerPortalSettings = "PlayerPortalSettings"
}
