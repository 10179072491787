import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PlayerProfile } from "@common/models/PlayerProfile";
import { ApiMethod } from "@core/core.module/decorators/api-method.decorator";
import { ApiService } from "@core/core.module/decorators/api-service.decorator";
import { IPlayerProfileCheckResult } from "@core/core.module/services/IMetaData";
import { NrtApiService } from "@core/core.module/services/NrtApiService";
import { NrtHttpRequestOptions } from "@core/core.module/services/NrtHttpRequestOptions";
import { environment } from "projects/player-portal/player-portal-client/src/environments/environment";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { ProfileQuery } from "./storage/profile.query";
import { ProfileStore } from "./storage/profile.store";

@Injectable({ providedIn: "root" })
@ApiService(environment.API_ENDPOINT + "/api/profile/")
export class ProfileService extends NrtApiService {
  constructor(
    protected http: HttpClient,
    public store: ProfileStore,
    public query: ProfileQuery //private auth: AuthService
  ) {
    super(http, { store, query });
  }

  @ApiMethod({
    method: "GET",
    route: "",
    cache: true
  })
  public getProfile$(opts?: NrtHttpRequestOptions): Observable<PlayerProfile> {
    if (!!this.store.getValue()?.currentProfile) {
      return this.query.current$.pipe(map(x => x.currentProfile), take(1));
    } else {
      return this.storeRequest$<PlayerProfile>("currentProfile", null, opts).pipe();
    }
  }

  @ApiMethod({
    method: "GET",
    route: "check/?externalId={externalId}&hash={hash}"
  })
  public checkProfile$(
    externalId: string,
    hash: string
  ): Observable<IPlayerProfileCheckResult> {
    return this.request$({ externalId, hash });
  }

  @ApiMethod({
    method: "PUT",
    route: ""
  })
  public update$(profile: PlayerProfile): Observable<PlayerProfile> {
    return this.storeRequest$("currentProfile", { $raw: profile });
  }

  @ApiMethod({
    method: "POST",
    route: "sms"
  })
  public sendSms$(
    rewardName: string,
    rewardCode: string,
    phoneNumber: string
  ): Observable<any> {
    return this.request$({ rewardName, rewardCode, phoneNumber });
  }

  @ApiMethod({
    method: "DELETE",
    route: ""
  })
  public deleteProfile$() {
    return this.request$();
  }

}
