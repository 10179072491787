import { Injectable } from "@angular/core";
import {
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  CanActivate
} from "@angular/router";
import { Observable, of, EMPTY, Subject, combineLatest } from "rxjs";
import { DataService } from "./data.service";
import {
  switchMap,
  map,
  tap,
  distinctUntilChanged,
  finalize,
  takeUntil,
  mapTo,
  exhaustMap,
  withLatestFrom
} from "rxjs/operators";
import { AuthService } from "@core/core.module/jwt/auth.service";
import { PlayerProfile } from "projects/player-portal/common/models/PlayerProfile";
import { filterNil } from "@datorama/akita";
import { pagesToggleService } from "@core/pages-ui.module/services/toggler.service";

@Injectable({
  providedIn: "root"
})
export class OptimisticDataActivator implements CanActivate {
  private _load$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private dataService: DataService,
    public auth: AuthService,
    private toggler: pagesToggleService
  ) {
    this._loader.subscribe();
  }

  private _loader = combineLatest(
    this._load$,
    this.auth.currentProfile$.pipe(filterNil)
  ).pipe(
    exhaustMap(([, profile]) => {
      if (profile.isVerified) {
        !this.dataService.hasCache && this.toggler.setLoading(true);

        return this.dataService.loadData$().pipe(
          takeUntil(this.auth.userLogout$),
          finalize(() => {
            this.toggler.setLoading(false);
          })
        );
      } else {
        return EMPTY;
      }
    })
  );

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    this._load$.next(true);
    return of(true);
  }
}
