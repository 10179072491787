<div *ngIf="{ loading: loading$ | async } as q">
  <div>
    <div class="wrap-loader" [@fadeAnimation]="q.loading">
      <!-- <section class="loader-container">

      <div class="se-pre-con">
        <svg

          width="300px"
          height="200px"
          viewBox="0 0 187.3 93.7"
          preserveAspectRatio="xMidYMid meet"
        >
          <path
            stroke="#ededed"
            id="outline"
            fill="none"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 				c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
          />
          <path
            id="outline-bg"
            opacity="0.3"
            fill="none"
            stroke="#ededed"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 				c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
          />
        </svg>
      </div>
    </section>

    <div class="loader"></div> -->

      <div class="bubble-loader" *ngIf="!brandedLoaderImg">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <img
        class="branded-loader"
        src="{{ brandedLoaderImg }}"
        *ngIf="brandedLoaderImg"
      />

      <div *ngIf="metaDataQuery?.current?.currentBranding !== 'rwlv'; else SimpleLoader" class="loader-text">
        {{ (loadingText$ | async) }}
      </div>
    </div>
  </div>
</div>
<ng-template #SimpleLoader>
  <div class="loader-text">Loading</div>
</ng-template>
