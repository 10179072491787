import { StoreConfig, Store } from "@datorama/akita";
import { Injectable } from "@angular/core";
import { RewardStateSort } from "@common/models/RewardStates";
import produce from "immer";

export enum Themes {
  "light",
  "dark"
}
export enum Layouts {
  "slim",
  "card"
}
export enum Languages {
  "en-US",
  "es-MX",
  "en-ZW"
}

export type ThemType = keyof typeof Themes;
export type LayoutType = keyof typeof Layouts;
export type LanguageType = keyof typeof Languages;

export interface ISettings {
  theme: ThemType;
  layout: LayoutType;
  autoNightMode: boolean;
  language: LanguageType | string;
  hotRewardDays: number | string;
  themeSettings?: { [key: string]: string };
  defaultSort?: RewardStateSort;
}

export const defaultSortParams: RewardStateSort = {
  expirationDate: {
    name: "EXPIRATION_DATE",
    reverse: false,
    sortFunc: x => new Date(x.expirationDate).getTime()
  },
  name: {
    name: "NAME",
    sortFunc: x => x.name.toLowerCase()
  },
  rewardCode: {
    name: "REWARD_CODE",
    sortFunc: x => x.rewardCode.toLowerCase()
  }
};

export const defaultSettings = {
  theme: "light",
  layout: "card",
  autoNightMode: false,
  language: "en-US",
  hotRewardDays: 3,
  defaultSort: defaultSortParams
} as ISettings;

@StoreConfig({ name: "settings" })
@Injectable({ providedIn: "root" })
export class SettingsStore extends Store<ISettings> {
  constructor() {
    super(defaultSettings);
  }

  akitaPreUpdate(prev: ISettings, next: ISettings) {
    return produce((draft: ISettings) => {
      Object.keys(defaultSortParams).forEach(s => {
        draft.defaultSort[s].sortFunc = defaultSortParams[s].sortFunc;
      });
    })(next);
  }
}
