import { Type, InjectionToken } from "@angular/core";
import { isEmpty, isNotEmpty } from "../utils/utils";
import { rootInjector } from "../utils/root-injector";

/**
 * Этот декоратор используется для облегчённого создания синглтонов сервисов для слабосвязанных между собой программных модулей.
 * Идея в следующем: предположим у нас есть некоторый сервис, который используется в разных модулях, но реализация которого может
 * отличаться. Классическим вариантом использования являеся определение специального InjectionToken, описывающего интерфейс сервиса,
 * реализация этого интерфейса в одном из программных модулей с последующим импортом данного интерфейса в разделе `providers` настройки модуля:
 *      @NgModule({
            providers: [
                { provide: TRANSLATE_SERVICE_TOKEN, useClass: AppTranslateService }
            ],
        })
        export class OCTranslocoModule { ... }
 * после чего данный сервис инъектируется в остальные модули по его InjectionToken:
 *      constructor(@Inject(TRANSLATE_SERVICE_TOKEN) translate: ITranslateService) { ... }
 *
 * Для того, чтобы упростить доступ к сервису, не используя инъекции, можно создать сервис-синглтон, который будет
 * имплементировать интерфейс сервиса и проксировать вызовы методов этого интерфейса на реальный сервис:
 *
 *  class DefaultTranslateService implements IOCTranslateService {
 *      currentLang: string = '';
 *      use(lang: string, permanent?: boolean): Observable<any> {  }
 *      instant(key: string | string[], interpolateParams: object): any { return key; }
 *  }
 *  export class CoreTranslateService implements IOCTranslateService {
 *      currentLang: string;
 *      use(lang: string, permanent?: boolean): Observable<any> { throw new Error('Not implemented'); }
 *      instant(key: string | string[], interpolateParams: object): any { throw new Error('Not implemented'); }
 *
 *      @SetupSignleton(TRANSLATE_SERVICE_TOKEN, DefaultTranslateService) static instance: DefaultTranslateService;
 *  }
 *  export const translate: IOCTranslateService = new CoreTranslateService();
 *
 * Теперь доступ к сервису может осуществляться напрямую через синглтон `translate`, причём,
 * если реализации этого сервиса нет ни в одном из модулей - будет использоваться реализация `DefaultTranslateService`.
 * Единственное ОБЯЗАТЕЛЬНОЕ условие корректной настройки: все простые свойства интерфейса сервиса в `DefaultTranslateService`
 * должны иметь значения по умолчанию. В противном случае данное свойство проксироваться НЕ будет.
 *
 * @param token токен для инъекции сервиса
 * @param defaultImplementation тип реализации сервиса по умолчанию
 */
export const SetupSignleton = (
  token: InjectionToken<any>,
  defaultImplementation?: Type<any>
) => (target: any, propertyKey: string) => {
  let defImplInstance;
  if (typeof defaultImplementation === "function") {
    defImplInstance = new defaultImplementation();
    target["__default"] = defImplInstance;
  }

  // Creating methods proxy
  const impl = defImplInstance || target.prototype;
  const descrs = Object.getOwnPropertyDescriptors(impl);

  for (const key in descrs) {
    let descr = descrs[key]; // Object.getOwnPropertyDescriptor(impl, key);
    if (isEmpty(descr) && typeof defaultImplementation === "function") {
      descr = Object.getOwnPropertyDescriptor(
        defaultImplementation.prototype,
        key
      );
    }

    if (isNotEmpty(descr)) {
      if (typeof descr.value === "function") {
        // This is a function
        Object.defineProperty(target.prototype, key, {
          get: () => (...args) => target[propertyKey][key](...args)
        });
      } else if ("value" in descr) {
        // This is a simple propery
        Object.defineProperty(target.prototype, key, {
          get: () => target[propertyKey][key],
          set: v => (target[propertyKey][key] = v)
        });
      } else {
        if (typeof descr.get === "function") {
          // this is a property getter
          Object.defineProperty(target.prototype, key, {
            get: () => target[propertyKey][key]
          });
        }
        if (typeof descr.set === "function") {
          // this is a property setter
          Object.defineProperty(target.prototype, key, {
            set: v => (target[propertyKey][key] = v)
          });
        }
      }
    }
  }

  // Creating getter and setter of the singleton instance property
  const descriptor = <PropertyDescriptor>{
    configurable: true,
    enumerable: true,
    get: function() {
      let res = target["__instance"];
      if (!res) {
        res = rootInjector?.get(token, null);
        target["__instance"] = res || target["__default"];
      }
      return res;
    },
    set: function(value) {
      target["__instance"] = value;
    }
  };
  Object.defineProperty(target, propertyKey, descriptor);
};
