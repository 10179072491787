import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { UntilDestroy } from "@core/core.module/decorators/until-destroy.decorator";
import { AuthService } from "@core/core.module/jwt/auth.service";
import { untilDestroyed } from "@core/core.module/utils/take-until-destroy/take-until-destroy";
import { pagesToggleService } from "@core/pages-ui.module/services/toggler.service";
import { Observable } from "rxjs";
import { CountdownCircleService } from "../countdown-circle/countdown-circle.service";
import { LogoutConfirmationService } from "./logout-confirmation.service";

@UntilDestroy()
@Component({
  selector: "app-logout-confirmation",
  templateUrl: "./logout-confirmation.component.html",
  styleUrls: ["./logout-confirmation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutConfirmationComponent implements OnInit, OnDestroy {
  display$: Observable<"open" | "close">;
  timerValue: number;
  intervalId;
  intervalIds = [];

  constructor(
    private logoutConfirmationService: LogoutConfirmationService,
    private countdownCircleService: CountdownCircleService,
    public toggler: pagesToggleService,
    private authService: AuthService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnDestroy() {}

  ngOnInit() {
    this.display$ = this.logoutConfirmationService.watch();
    this.display$.pipe(untilDestroyed(this)).subscribe(value => {
      if (value === "open") {
        this.initTimer();
      }
      if (value === "close") {
        this.clearIntervals();
      }
    });
  }

  initTimer() {
    let seconds = 10;
    this.intervalId = setInterval(() => {
      seconds -= 1;
      if (seconds === 0) {
        this.close();
      }
      this.timerValue = seconds;
      this.cd.detectChanges();
    }, 1000);
    this.intervalIds.push(this.intervalId);
  }

  close() {
    this.clearIntervals();
    this.logoutConfirmationService.close();
    this.authService.backToCHS();
    this.cd.detectChanges();
  }

  clearIntervals() {
    this.intervalIds.forEach(item => {
      {
        clearInterval(item);
      }
    });
  }

  continue() {
    this.clearIntervals();
    this.countdownCircleService.resetCountdown();
    this.logoutConfirmationService.close();
    this.cd.detectChanges();
  }
}
