import { NgModule, ComponentFactoryResolver, Injector } from "@angular/core";
import { WatermarkDirective } from "./watermark.directive";
import { WatermarkTemplateComponent } from "./watermark.template";
@NgModule({
  declarations: [WatermarkTemplateComponent, WatermarkDirective],
  entryComponents: [WatermarkTemplateComponent],
  exports: [WatermarkDirective]
})
export class WatermarkModule {
  constructor(
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.componentFactoryResolver
      .resolveComponentFactory(WatermarkTemplateComponent)
      .create(this.injector);
  }
}
