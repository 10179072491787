<ng-container [ngSwitch]="IconType">
  <svg
    class="feather"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    *ngSwitchCase="'fi'"
  >
    <use
      attr.xlink:href="assets/fonts/feather/feather-sprite.svg#{{ IconName }}"
    />
  </svg>
  <ng-container *ngSwitchCase="'letter'">
    {{ IconName }}
  </ng-container>

  <ng-container *ngSwitchCase="'clr'">
    <ng-container *ngIf="IconName">
      <clr-icon
        [attr.shape]="IconName"
        [attr.size]="Size"
        [attr.dir]="Direction"
        [attr.class]="Class"
      ></clr-icon>
    </ng-container>
  </ng-container>

  <i *ngSwitchDefault class="{{ IconType }} {{ IconType }}-{{ IconName }}"></i>
</ng-container>
