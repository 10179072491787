import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { clientMapping } from "@core/core.module/branding/branding.mapping";
import { ApiService } from "@core/core.module/decorators/api-service.decorator";
import { statefull } from "@core/core.module/operators/statefull";
import {
  IBrandingMetadata,
  IClientAccountMetadata,
  IDaysDeletingMetadata,
  IExternalProfileMetadata,
  IExternalRewardsMetadata,
  IFieldMetadata,
  IGoogleAnalyticsMetadata,
  IGoogleTagManagerMetadata,
  IKioskLobbyTokenMetadata,
  IKioskModeMetadata,
  IMedallionsConfigMetadata,
  IMetaDataService,
  IPlayerPortalSettingsMetadata,
  IPlayerSettingsMetadata,
  ISignUpOptionsMetadata,
  ISocialNetworkProvider,
  ITermsAndConditionsMetadata
} from "@core/core.module/services/IMetaData";
import { BaseMetaDataService } from "projects/player-portal/common/services/api/baseMetaData.service";
import { MetadataType } from "projects/player-portal/common/services/api/MetadataType.enum";
import { environment } from "projects/player-portal/player-portal-client/src/environments/environment";
import { combineLatest, empty, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ProfileService } from "../profile/profile.service";
import { BootstrapMetaData } from "./MetaData";
import { MetaDataQuery } from "./storage/metaData.query";
import { MetaDataStore } from "./storage/metaData.store";

@Injectable({ providedIn: "root" })
@ApiService(environment.API_ENDPOINT + "/api/MetaData/")
export class MetaDataService extends BaseMetaDataService
  implements IMetaDataService {
  externalRewards$ = this.getMetadata$<IExternalRewardsMetadata>(
    MetadataType.ExternalRewards
  ).pipe(statefull);

  externalProfile$ = this.getMetadata$<IExternalProfileMetadata>(
    MetadataType.ExternalProfile
  ).pipe(statefull);

  termsAndConditions$ = this.getMetadata$<ITermsAndConditionsMetadata>(
    MetadataType.TermsAndConditions
  ).pipe(statefull);

  profileCheck$ = (externalId, hash) =>
    this.profile.checkProfile$(externalId, hash);
  signUpFields$ = this.getSignUpFields$().pipe(statefull);
  playerSettings$ = this.getMetadata$<IPlayerSettingsMetadata>(
    MetadataType.PlayerProfileSettings
  ).pipe(statefull);

  daysDeleting$ = this.getMetadata$<IDaysDeletingMetadata>(
    MetadataType.UserDataMaskingPendingDays
  ).pipe(statefull);

  googleAnalytics$ = this.getMetadata$<{
    [MetadataType.GoogleAnalytics]: IGoogleAnalyticsMetadata;
    [MetadataType.GoogleTagManager]: IGoogleTagManagerMetadata;
  }>(MetadataType.GoogleAnalytics, MetadataType.GoogleTagManager).pipe(
    catchError((err, c) => empty()),
    statefull
  );

  signUpOptions$ = this.getMetadata$<ISignUpOptionsMetadata>(
    MetadataType.SignUpOptions
  ).pipe(statefull);

  ssoProviders$ = this.getMetadata$<ISocialNetworkProvider>(
    MetadataType.SsoProviders
  ).pipe(statefull);

  get brand(): string {
    return this.query.current.currentBranding;
  }

  constructor(
    protected http: HttpClient,
    public store: MetaDataStore,
    public query: MetaDataQuery,
    private profile: ProfileService
  ) {
    super(http, { store, query });
  }

  public getBootstrapMetaData$(): Observable<BootstrapMetaData> {
    const keys = [
      //MetadataType.ClientAccount,
      MetadataType.SsoProviders,
      MetadataType.ExternalProfile,
      MetadataType.ExternalRewards,
      MetadataType.Branding,
      MetadataType.KioskMode,
      MetadataType.KioskLobbyToken,
      MetadataType.PlayerPortalSettings,
      MetadataType.KioskMedallionsLobbyConfig
    ];

    const clientAccountKey = [MetadataType.ClientAccount];

    return combineLatest([
      this.getMetadata$(...keys),
      this.getMetadata$(...clientAccountKey)
    ]).pipe(
      map(
        ([mdata, ca]: [
          {
            [type: string]: ISocialNetworkProvider[] &
              IExternalProfileMetadata &
              IExternalRewardsMetadata &
              IBrandingMetadata &
              IKioskModeMetadata &
              IKioskLobbyTokenMetadata &
              IMedallionsConfigMetadata &
              IPlayerPortalSettingsMetadata;
          },
          IClientAccountMetadata
        ]) => {
          const md = {
            clientAccount: ca,
            ssoProviders: mdata[MetadataType.SsoProviders],
            currentBranding: clientMapping[ca.account.hash.toUpperCase()],
            externalProfile: mdata[MetadataType.ExternalProfile],
            externalRewards: mdata[MetadataType.ExternalRewards],
            branding: mdata[MetadataType.Branding],
            kioskMode: mdata[MetadataType.KioskMode],
            lobbyToken: mdata[MetadataType.KioskLobbyToken],
            medallionsConfig: mdata[MetadataType.KioskMedallionsLobbyConfig],
            playerPortalSettings: mdata[MetadataType.PlayerPortalSettings]
          };

          this.store.update(md);
          return md;
        }
      )
    );
  }

  public getSignUpFields$(): Observable<IFieldMetadata[]> {
    return this.getMetadata$<{ fields: IFieldMetadata[] }>(
      MetadataType.SignUpFields
    ).pipe(map(x => x.fields)) as Observable<IFieldMetadata[]>;
  }

  public getKioskLobbyToken$(): Observable<any> {
    return this.getMetadata$(...[MetadataType.KioskLobbyToken]).pipe(
      map((kioskLobbyTokenMetaData: IKioskLobbyTokenMetadata) => {
        const md: Partial<BootstrapMetaData> = {
          lobbyToken: kioskLobbyTokenMetaData
        };
        this.store.update(md);
        return md;
      })
    );
  }

  public getMedallionsConfig$(): Observable<IMedallionsConfigMetadata> {
    return this.getMetadata$(...[MetadataType.KioskMedallionsLobbyConfig]).pipe(
      map((kioskMedallionsLobbyConfigMetaData: IMedallionsConfigMetadata) => {
        const md: Partial<BootstrapMetaData> = {
          medallionsConfig: kioskMedallionsLobbyConfigMetaData
        };
        this.store.update(md);
        return md.medallionsConfig;
      })
    );
  }

  // public getPlayerSettings$(): Observable<IPlayerSettingsMetadata> {
  //   return this.getMetadata$<IPlayerSettingsMetadata>(
  //     MetadataType.PlayerProfileSettings
  //   ) as Observable<IPlayerSettingsMetadata>;
  // }

  // public getDaysDataDeleting$(): Observable<IDaysDeletingMetadata> {
  //   return this.getMetadata$<IDaysDeletingMetadata>(
  //     MetadataType.UserDataMaskingPendingDays
  //   ) as Observable<IDaysDeletingMetadata>;
  // }

  // public getTermsAndConds$(): Observable<ITermsAndConditionsMetadata> {
  //   return this.getMetadata$<ITermsAndConditionsMetadata>(
  //     MetadataType.TermsAndConditions
  //   ) as Observable<ITermsAndConditionsMetadata>;
  // }
}
