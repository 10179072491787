import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable, NgZone, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { pagesToggleService } from "@core/pages-ui.module/services/toggler.service";
import { TranslocoService } from "@ngneat/transloco";
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from "rxjs";
import { catchError, mapTo, shareReplay, tap } from "rxjs/operators";
import { Branding } from "../branding/branding";
import { msg } from "../interfaces/core-messages.service";
import { UserAuthInterface } from "../interfaces/user-auth.interface";
import { AppModeService } from "../services/app-mode.service";
import { OCErrorHandler } from "./../services/error-handler";
import { AuthData } from "./AuthData";
import {
  API_ENDPOINT,
  AUTH_ENDPOINT,
  CLIENT_ID,
  LOGIN_PATH,
  REMOTE_AUTH_ENDPOINT,
  R_TOKEN_FLAG
} from "./injection-tokens";
import { IUserPermissions } from "./IUserPermissions";

export enum RemoteLoginStatus {
  SUCCESS = "success",
  AUTH_ERROR = "unauthorized",
  ERROR = "error"
}

export interface IRemoteLoginData {
  status: RemoteLoginStatus;
  description?: string;
}

export declare type RemoteLoginFunc = (
  managerToken: string,
  playerId: string
) => Promise<IRemoteLoginData>;

@Injectable({
  providedIn: "root"
})
export class AuthService {
  readonly JWT_TOKEN = "JWT_TOKEN";
  readonly X_AUTH = "X-Auth";

  private _authData$ = new BehaviorSubject<AuthData>(this.authData);
  authData$ = this._authData$.asObservable();

  private _currentProfile$ = new ReplaySubject<any>();
  currentProfile$ = this._currentProfile$.asObservable();

  permissions$ = this.getUserPermissions().pipe(shareReplay(1));

  //get currentProfile():any { return this._currentProfile$.getValue(); }
  setCurrentProfile(playerProfile: any) {
    this._currentProfile$.next(playerProfile);
  }

  private _doUserLogin$ = new ReplaySubject<boolean>();
  userLogin$ = this._doUserLogin$.asObservable();

  private _doUserLogout$ = new Subject<boolean>();
  userLogout$ = this._doUserLogout$.asObservable();

  isRemoteLogin: boolean = false;

  private headers = {
    headers: {
      //'X-Target': 'devtest3',
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json"
    }
  };

  private jsonHeaders = {
    headers: {
      //'X-Target': 'devtest3',
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json"
    }
  };

  private pseudoLocale: string = localStorage.getItem("Pseudo-Locale");

  private setLocaleHeader() {
    if (this.pseudoLocale || this.translateService.getActiveLang()) {
      this.headers.headers["Locale"] =
        this.pseudoLocale || this.translateService.getActiveLang();
      this.jsonHeaders.headers["Locale"] =
        this.pseudoLocale || this.translateService.getActiveLang();
    }
  }

  //private _loginPath?: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandler: OCErrorHandler,
    private zone: NgZone,
    private appModeService: AppModeService,
    public toggler: pagesToggleService,
    @Inject(AUTH_ENDPOINT) private authUrl: string,
    @Inject(R_TOKEN_FLAG) private rTokenFlag: string,
    @Optional() @Inject(REMOTE_AUTH_ENDPOINT) private remoteAuthUrl: string,
    @Inject(API_ENDPOINT) private apiUrl: string,
    @Optional() @Inject(CLIENT_ID) private clientId?: string,
    @Optional() private translateService?: TranslocoService,
  ) {
    const xTarget = localStorage.getItem("X-Target");
    if (xTarget) {
      this.headers.headers["X-Target"] = xTarget;
      this.jsonHeaders.headers["X-Target"] = xTarget;
    }

    this.isRemoteLogin = this.authData && !!this.authData["as:client_id"];
    this.isLoggedIn && this._doUserLogin$.next(true);
  }

  get loginPath() {
    return Branding.get(s => s?.behavior?.appPaths?.loginPath)
  }

  login(
    user: { username: string; password: string },
    isOkta: boolean = false,
    grant_type: string = "password"
  ): Observable<boolean> {
    if (isOkta) {
      this.toggler.setLoading(true);
      return this.http
        .post<any>(
          `${this.authUrl}/api/externalAuth/okta`,
          {
            username: user.username,
            password: user.password,
            authRequestType: "internal"
          },
          this.jsonHeaders
        )
        .pipe(
          tap((tokens: any) => {
            if (tokens?.redirectUrl) {
              window.location = tokens?.redirectUrl;
            } else {
              console.log("wrong redirectUrl");
            }
          }),
          mapTo(false)
        );
    } else {
      return this.http
        .post<AuthData>(
          `${this.authUrl}/oauth/token`,
          `grant_type=${grant_type}&username=${encodeURIComponent(
            user.username
          )}&password=${encodeURIComponent(user.password)}&client_id=${this.clientId
          }`,
          this.headers
        )
        .pipe(
          tap(tokens => {
            this.isRemoteLogin = false;
            this.doLoginUser(tokens);
          }),
          mapTo(true)
        );
    }
  }

  loginByCardNumber(cardNumber: string, pin: string): Observable<boolean> {
    return this.http
      .post<AuthData>(
        `${this.authUrl}/oauth/token`,
        `grant_type=card_pin&card_number=${encodeURIComponent(
          cardNumber
        )}&pin=${encodeURIComponent(pin)}&client_id=${this.clientId}`,
        this.headers
      )
      .pipe(
        tap(tokens => {
          this.isRemoteLogin = false;
          this.doLoginUser(tokens);
        }),
        mapTo(true)
      );
  }

  loginByDeviceHash(patronId: string, h: string, terminalId?: string): Observable<boolean> {
    let suffix = (!terminalId) ? '' : `&terminal_id=${encodeURIComponent(terminalId)}`
    return this.http
      .post<AuthData>(
        `${this.authUrl}/oauth/token`,
        `grant_type=device_hash&patron_id=${encodeURIComponent(
          patronId
        )}&device_hash=${encodeURIComponent(h)}&client_id=${this.clientId}` + suffix,
        this.headers
      )
      .pipe(
        tap(tokens => {
          this.isRemoteLogin = false;
          this.doLoginUser(tokens);
        }),
        mapTo(true)
      );
  }

  loginWithSocialNetwork(
    accessToken: string,
    userId: string,
    socialNetwork: string
  ): Observable<AuthData> {
    let socialNetworkPrefix = socialNetwork.toLowerCase();

    return this.http
      .post<AuthData>(
        `${this.authUrl}/oauth/token`,
        `grant_type=${socialNetworkPrefix}_access&access_token=${accessToken}&client_id=${this.clientId}&user_id=${userId}`,
        this.headers
      )
      .pipe(
        tap(tokens => {
          this.isRemoteLogin = false;
          this.doLoginUser(tokens);
        })
      );
  }

  linkProfileWithSocialNetwork(
    accessToken: string,
    userId: string,
    socialNetwork: string
  ): Observable<boolean> {
    return this.http
      .post<any>(
        `${this.authUrl}/api/externalAuth/link${socialNetwork}Acc`,
        { access_token: accessToken, user_id: userId },
        this.jsonHeaders
      )
      .pipe(
        catchError((error: any) => {
          return of(error.error);
        }),
        tap(playerProfile => {
          if (playerProfile[`has${socialNetwork}Profile`]) {
            this.setCurrentProfile(playerProfile);
          }
        })
      );
  }

  unlinkSocialNetwork(socialNetwork: string) {
    return this.http
      .delete<any>(
        `${this.authUrl}/api/externalAuth/unlink${socialNetwork}Acc`,
        this.headers
      )
      .pipe(
        tap(playerProfile => {
          this.setCurrentProfile(playerProfile);
        }),
        mapTo(true)
      );
  }

  confirmEmail(hash: string, token: string) {
    let bodyData = {};
    bodyData["hash"] = hash;
    bodyData["token"] = token;

    this.setLocaleHeader();

    return this.http
      .post<any>(
        `${this.apiUrl}/api/profile/confirmEmail`,
        bodyData,
        this.jsonHeaders
      )
      .pipe(
        mapTo({
          succeeded: true
        }),
        catchError(error => {
          return of(this.getErrorFromResponse(error.error));
        })
      );
  }

  requestResetPassword(username: string) {
    this.setLocaleHeader();

    return this.http
      .post<any>(
        `${this.apiUrl}/api/profile/resetPassword`,
        { username: username },
        this.jsonHeaders
      )
      .pipe(
        catchError(error => {
          return of(this.getErrorFromResponse(error.error));
        })
      );
  }

  resetPassword(
    hash: string,
    token: string,
    password: string,
    confirmPassword: string
  ) {
    let bodyData = {};
    bodyData["hash"] = hash;
    bodyData["token"] = token;
    bodyData["password"] = password;
    bodyData["confirmPassword"] = confirmPassword;

    this.setLocaleHeader();

    return this.http
      .post<any>(
        `${this.apiUrl}/api/profile/updatePassword`,
        bodyData,
        this.jsonHeaders
      )
      .pipe(
        catchError(error => {
          return of(this.getErrorFromResponse(error.error));
        })
      );
  }

  refreshToken() {
    return this.http
      .post<any>(
        `${this.isRemoteLogin ? this.remoteAuthUrl : this.authUrl + "/oauth"
        }/token`,
        `grant_type=refresh_token&refresh_token=${this.getRefreshToken()}&client_id=${this.clientId
        }`,
        this.headers
      )
      .pipe(
        // catchError((e, c) => {
        //   this.doLogoutUser();
        //   return c;
        // }),
        tap((tokens: AuthData) => {
          //this.storeJwtToken(tokens.access_token);
          this.storeTokens(tokens);
        })
      );
  }

  loginByRToken(r_token: string): Observable<boolean> {
    return this.http
      .post<any>(
        this.remoteAuthUrl + "/token",
        `grant_type=r_token&rtoken=${r_token}&client_id=${this.clientId}`,
        this.headers
      )
      .pipe(
        tap(tokens => {
          this.isRemoteLogin = true;
          this.doLoginUser(tokens);
        }),
        mapTo(true)
      );
  }

  loginByOktaIdToken$(idToken: string) {
    return this.http
      .post<any>(
        this.authUrl + "/oauth/token",
        `grant_type=external_okta_idtoken_access&client_id=${this.clientId}&id_token=${idToken}`,
        this.headers
      )
      .pipe(
        tap((tokens: AuthData) => {
          this.isRemoteLogin = false;
          this.doLoginUser(tokens);
          //this.storeOktaIdToken(tokens.id_token);
        }),
        mapTo(true)
      );
  }

  loginByOktaCode$(code: string) {
    return this.http
      .post<any>(
        this.authUrl + "/oauth/token",
        `grant_type=external_okta_code_access&client_id=${this.clientId}&code=${code}`,
        this.headers
      )
      .pipe(
        tap((tokens: AuthData) => {
          this.isRemoteLogin = false;
          this.doLoginUser(tokens);
          //this.storeOktaIdToken(tokens.id_token);
        }),
        mapTo(true)
      );
  }

  backToCHS() {
    this.toggler.setLoading(true);
    if (this.appModeService.isKioskMode()) {
      location.href = "http://exitkiosk";
    } else {
      this.doLogoutUser();
    }
  }

  loginByManager(
    managerToken: string,
    userId: string
  ): Observable<IRemoteLoginData> {
    this.reset();
    return this.http
      .post<AuthData>(
        `${this.authUrl}/oauth/token`,
        `grant_type=token-exchange&actor_token=${encodeURIComponent(
          managerToken
        )}&impersonated_sub=${encodeURIComponent(
          userId
        )}&client_id=player-portal`,
        this.headers
      )
      .pipe(
        tap(tokens => {
          this.doLoginUser(tokens, true);
        }),
        mapTo({ status: RemoteLoginStatus.SUCCESS }),
        catchError(error => {
          let res: IRemoteLoginData = {
            status: RemoteLoginStatus.ERROR,
            description: "Unknown error"
          };
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              res = { status: RemoteLoginStatus.AUTH_ERROR };
            } else {
              if (error?.error?.error_description) {
                res = {
                  status: RemoteLoginStatus.ERROR,
                  description: error.error.error_description
                };
                msg.error(error.error.error_description);
              }
            }
          }

          return of(res);
        })
      );
  }

  logout(silent?: boolean) {
    const params = `token_type_hint=refresh_token&token=${this.getRefreshToken()}&client_id=${this.clientId
      }`;

    const request = this.isRemoteLogin
      ? this.http.post<any>(
        `${this.remoteAuthUrl}/revoke/?logout=1`,
        params,
        this.headers
      )
      : this.http.post<any>(
        `${this.authUrl}/oauth/revoke/?logout`,
        params,
        this.headers
      );

    return request.pipe(
      tap(() => this.doLogoutUser(silent)),
      mapTo(true)
    );
  }



  signUp(user: UserAuthInterface) {
    this.setLocaleHeader();
    return this.http.post<any>(`${this.apiUrl}/api/profile/signup`, user, this.jsonHeaders)
      .pipe(
        catchError(error => {
          return of(this.getErrorFromResponse(error.error));
        })
      );
  }

  autoSignUp(
    username: string,
    password: string,
    externalId: string,
    hash: string,
    email?: string,
    phone?: string,
    hasSmsOptInFlag?: boolean,
    hasEmailOptInFlag?: boolean
  ) {
    let bodyData = {};
    bodyData["username"] = username;
    bodyData["password"] = password;
    bodyData["externalId"] = externalId;
    bodyData["hash"] = hash;

    bodyData["email"] = email;
    bodyData["phone"] = phone;

    bodyData["hasSmsOptInFlag"] = hasSmsOptInFlag;
    bodyData["hasEmailOptInFlag"] = hasEmailOptInFlag;

    this.setLocaleHeader();

    return this.http
      .post<any>(
        `${this.apiUrl}/api/profile/autoSignUp`,
        bodyData,
        this.jsonHeaders
      )
      .pipe(
        catchError(error => {
          return of(this.getErrorFromResponse(error.error));
        })
      );
  }

  requestRecoverAccount(email: string) {
    const recoveryRequest = {
      identifier: email
    };
    return this.http
      .post<any>(
        `${this.apiUrl}/api/profile/recoveryPlayerLink`,
        recoveryRequest,
        this.jsonHeaders
      )
      .pipe(
        catchError(error => {
          return of(this.getErrorFromResponse(error.error));
        })
      );
  }

  recoverAccount(email: string, token: string) {
    return this.http
      .post<any>(
        `${this.apiUrl
        }/api/profile/recoveryPlayer?identifier=${encodeURIComponent(
          email
        )}&token=${encodeURIComponent(token)}`,
        null,
        this.jsonHeaders
      )
      .pipe(
        catchError(error => {
          return of(this.getErrorFromResponse(error.error));
        })
      );
  }

  getUserPermissions(): Observable<IUserPermissions> {
    return this.http.get(`${this.remoteAuthUrl}/api/users/permissions`).pipe(
      catchError(error => {
        return of(this.getErrorFromResponse(error.error || error));
      })
    );
  }

  private getErrorFromResponse(error: any): any {
    return {
      error:
        error &&
        error.errors &&
        Array.isArray(error.errors) &&
        error.errors.map((err: { message: any }) => err.message)
    };
  }

  get isLoggedIn() {
    return !!this.jwtToken;
  }

  private _authData: AuthData;
  get authData(): AuthData {
    if (!this._authData) {
      const tokens = localStorage.getItem(this.JWT_TOKEN);
      tokens && (this._authData = JSON.parse(tokens) as AuthData);
    }
    return this._authData;
  }

  private doLoginUser(tokens: AuthData, skipLocalStorage?: boolean) {
    this.storeTokens(tokens, skipLocalStorage);
    this._doUserLogin$.next(true);
    //this._currentProfile$.next(tokens);
  }

  reset() {
    this.setCurrentProfile(null);
    this.removeTokens();
    this.clearOktaCredentials();
  }

  public doLogoutUser(silent?: boolean) {
    this.doSilentLogout();

    if (!silent && this.loginPath) {
      if (this.loginPath.startsWith("http")) {
        this.zone.run(() => {
          window.location.replace(this.loginPath);
        });
      } else {
        this.zone.run(() => {
          this.router.navigateByUrl(this.loginPath);
        });
      }
    }
  }

  public doSilentLogout() {
    this.reset();
    sessionStorage.removeItem(this.rTokenFlag);
    localStorage.removeItem("AkitaStores"); //TODO

    this._doUserLogout$.next(true);
  }

  get jwtToken() {
    return this.authData && this.authData.access_token;
  }

  private getRefreshToken() {
    return this.authData && this.authData.refresh_token;
  }

  private storeTokens(tokens: AuthData, skipLocalStorage?: boolean) {
    this._authData = tokens;
    this._authData$.next(this._authData);
    !skipLocalStorage &&
      localStorage.setItem(this.JWT_TOKEN, JSON.stringify(tokens));
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
  }

  storeOktaIdToken(id_token: string) {
    localStorage.setItem(this.X_AUTH, id_token);
  }

  getOktaIdToken(): string {
    return localStorage.getItem(this.X_AUTH);
  }

  clearOktaCredentials() {
    localStorage.removeItem(this.X_AUTH);
  }
}
