import { Component, NgZone, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "blank-layouts",
  template: `
    <div class="lang-buttons">
      <nrt-language-select></nrt-language-select>
    </div>
    <router-outlet></router-outlet>
  `,
  styles: [
    `
      .lang-buttons {
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 100;
      }
    `
  ],
  encapsulation: ViewEncapsulation.None
})
export class PortalBlankComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.route.queryParams
      .pipe(distinctUntilChanged())
      .subscribe(queryParams => {
        if (queryParams.h && queryParams.patronId)
          if (~this.route.url[0].path.indexOf("dummy1")) {
            /* this.router.navigate(
            [`device?h=${queryParams.h}&patronId=${queryParams.patronId}`],
            {
              state: { h: queryParams.h, patronId: queryParams.patronId },
              replaceUrl: true
            }
          ); */
            this.zone.run(() =>
              this.router.navigate(
                [`dummy?h=${queryParams.h}&patronId=${queryParams.patronId}`],
                {
                  state: { h: queryParams.h, patronId: queryParams.patronId },
                  replaceUrl: true
                }
              )
            );
          } else {
            this.zone.run(() =>
              this.router.navigate(
                [`device?h=${queryParams.h}&patronId=${queryParams.patronId}`],
                {
                  state: { h: queryParams.h, patronId: queryParams.patronId },
                  replaceUrl: true
                }
              )
            );
          }
      });
  }
}
