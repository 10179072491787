import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";

@Component({
  selector: "outer-badges",
  template: `
    <iframe src="http://localhost:4200/okta-token/badges/eyJraWQiOiJVbzJJdlhrRVA1RUdsOTN5clFuWmI5TWN1bVl4Rjc3S2JCaEJWVDI3RWRrIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHV1aW52bzFJcXpBbE5WQTFkNiIsIm5hbWUiOiJBdmF0YXIgVGVzdCIsInZlciI6MSwiaXNzIjoiaHR0cHM6Ly9yZXNvcnRzd29ybGRleHRlcm5hbC5va3RhcHJldmlldy5jb20vb2F1dGgyL2RlZmF1bHQiLCJhdWQiOiIwb2FobnAxNmNYelpwSE1zaDFkNiIsImlhdCI6MTYyMzkyNjMwOSwiZXhwIjoxNjIzOTI5OTA5LCJqdGkiOiJJRC5CV0ZhZnRQZ21PaTYyZ2Q3aURFdm90RndxUkQ0azg2U3VwaDJidWk0N2hjIiwiYW1yIjpbInB3ZCJdLCJub25jZSI6IjEyMzQiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ0ZXN0LjEwNjEzN0Byd2xhc3ZlZ2FzLmNvbSIsImF1dGhfdGltZSI6MTYyMzkyNjMwOCwiYXRfaGFzaCI6IkpVamIyZUUxRW5naC1nNWVGVjVWTEEiLCJyd2x2UGxheWVySUQiOiIyMDAwNzEyMiJ9.HL5X6OdJDnNJYvQ1c22xovafVWukIsB-7LgTP5rF6B3cxa0NtzStaM11aJkBUff52FmKpbCz_e51Jl1nLKztuaT6upVwb6kJYlFm3LKq8unkQTbCv9TSaNwJhdTaZU8MAdNsovgZ1CECWTtb5VuPmSTIwOFQgbSGD2c8ZwOT7mkpPh4_RUP-_XATOm2bWmp0c8W6qGAL0F9TE9Q609yHRH7tdGXuVqWMGjlrYeFM9wxs4Oik9nm5rngiC6jUWO2q8UjW1PDgBO2mABWuaJJyd0ZX1XBALfRQbnBGMZsnthgDp9uE6bpQp5nCXRg3yQg0OlVG9OcDaT1oR6JPkBxr1A&access_token=eyJraWQiOiJVbzJJdlhrRVA1RUdsOTN5clFuWmI5TWN1bVl4Rjc3S2JCaEJWVDI3RWRrIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULl9aWEdPeUxuY094X0NpQjJqRll3dDhxVFhMcXFLb3VEQ1d6TXplXzRQa0EiLCJpc3MiOiJodHRwczovL3Jlc29ydHN3b3JsZGV4dGVybmFsLm9rdGFwcmV2aWV3LmNvbS9vYXV0aDIvZGVmYXVsdCIsImF1ZCI6ImFwaTovL2RlZmF1bHQiLCJpYXQiOjE2MjM5MjYzMDksImV4cCI6MTYyMzkyOTkwOSwiY2lkIjoiMG9haG5wMTZjWHpacEhNc2gxZDYiLCJ1aWQiOiIwMHV1aW52bzFJcXpBbE5WQTFkNiIsInNjcCI6WyJvcGVuaWQiLCJwcm9maWxlIl0sInN1YiI6InRlc3QuMTA2MTM3QHJ3bGFzdmVnYXMuY29tIn0.Pq4ZcDpme8Px5KwRFZsDHZrYe3ICA16V-VO6hBYo772x4ZoronKnD15yrwRvP04SKZ96KjEq5y3QCywQIjAxWF9HycKuOUxd2ItJkkQwljwRezxG9xsE1cbLytlRVZmcwWNmFWOzCJqVpWBFz1f7oTtpliNvSGcq0kT-kSoeBZD3-g1G8r7C4bQBdIge8uNFKlYcdQmjLzw-7HQBhLVUPKByqLGrX-nk2LzYQO96cl_aiXcUzX0BnIxaVpJvIQyoXJ0K4R8nn-TsXnyDp7_LURgXztad41QoooHl6jXHnbHillf_FzNSi9XLl3DBk1yM5hvg5YSQjk2oqCKQufhkQA"></iframe>
  `,
  styles: [`
      iframe {
        height: 300px;
        width: 300px;
        resize: both;
        overflow: auto;
      }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom
})
export class IframeTestComponent implements OnInit, OnDestroy {
  constructor(
  ) {}

  ngOnInit() {

  }

  ngAfterViewInit() {}

  ngOnDestroy() {}
}
