import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@core/core.module/jwt/auth.service";
import { AppModeService } from "@core/core.module/services/app-mode.service";
import { distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "device-hash-resolver",
  styles: [],
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceHashResolverComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private appModeService: AppModeService
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.route.queryParams
      .pipe(distinctUntilChanged())
      .subscribe(queryParams => {
        this.authService.doSilentLogout();
        this.appModeService.setKioskMode();
        this.router.navigate(["session/device-login"], {
          state: { h: queryParams.h, patronId: queryParams.patronId, terminalId: queryParams.terminalId },
          replaceUrl: true
        });
      });
  }

  ngOnDestroy() { }
}
