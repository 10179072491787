/**
 * Implementation reference
 * https://codepen.io/Craigtut/pen/dIfzv
 *
 * style:
 * The directive rely on this styles,
 * paste this snippet in your main style

    .ripple{
      overflow:hidden;
      cursor: pointer;
    }

     .ripple-effect{
      position: absolute;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background: white;
      animation: ripple-animation 2.5s;
    }


     @keyframes ripple-animation {
      from {
        transform: scale(1);
        opacity: 0.4;
      }
      to {
        transform: scale(100);
        opacity: 0;
      }
    }

 *
 */
import {
  Directive,
  HostListener,
  ElementRef,
  Renderer2,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';
import { DomHelper } from './dom.helper.model';
import { fromEvent, BehaviorSubject, Subject, merge } from 'rxjs';
import { UntilDestroy } from "@core/core.module/decorators/until-destroy.decorator";
import { untilDestroyed } from "@core/core.module/utils/take-until-destroy/take-until-destroy";
import { withLatestFrom, filter, map, debounceTime, throttleTime } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[appRipple]',
  exportAs: 'appRipple'
})
export class RippleDirective extends DomHelper implements OnDestroy {
  @Input('color') public color = '#ffffff';

  private _showRipple$ = new BehaviorSubject<boolean>(false);

  private _ripple$ = new Subject<Event>();

  @Input() set showRipple(val: boolean) {
    this._showRipple$.next(val);
  }
  /**
   * @important
   * timeout should
   * not be grated than execution of "animation: ripple-animation"
   * in your style
   * @type {number}
   */
  @Input('timeout') public timeout = 1500;

  private nativeElement: { width: number; height: number } = {
    width: 0,
    height: 0
  };

  @HostListener("click", ["$event"])
  click($event) {
    this.ripple($event);
  }

  constructor(private renderer: Renderer2, private el: ElementRef) {
    super();

    renderer.addClass(this.el.nativeElement, 'ripple');
    renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
    renderer.setStyle(this.el.nativeElement, 'position', 'relative');

    merge(fromEvent(this.el.nativeElement, 'click'), this._ripple$)
      .pipe(
        withLatestFrom(this._showRipple$),
        filter(([, ripple]) => !!ripple),
        map(([e]) => e),
        throttleTime(200),
        untilDestroyed(this)
      )
      .subscribe((e: Event) => {
        e.preventDefault();

        this._preventGrow();

        this._removeOldClasess();

        const $div = this.renderer.createElement('div');

        const btnOffset = RippleDirective.offset(this.el.nativeElement);

        const position = RippleDirective._findPosition(e, btnOffset);

        this._applyStyle($div, position);

        this._removeRippleClasses($div);
      });
  }

  public ripple(e: Event) {
    this._ripple$.next(e);
  }

  // @HostListener('click', ['$event']) click(e) {
  //   e.preventDefault();

  //   this._preventGrow();

  //   this._removeOldClasess();

  //   const $div = this.renderer.createElement('div');

  //   const btnOffset = RippleDirective.offset(this.el.nativeElement);

  //   const position = RippleDirective._findPosition(e, btnOffset);

  //   this._applyStyle( $div, position );

  //   this._removeRippleClasses( $div );
  // }

  /**
   * prevent growing native element
   * when new elements are pushed
   * @private
   */
  private _preventGrow() {
    if (this.nativeElement.width === 0 && this.nativeElement.height === 0) {
      this.nativeElement.width = this.el.nativeElement.offsetWidth;
      this.nativeElement.height = this.el.nativeElement.offsetHeight;
    }
    this.renderer.setStyle(
      this.el.nativeElement,
      'width',
      `${this.nativeElement.width}px`
    );
    this.renderer.setStyle(
      this.el.nativeElement,
      'height',
      `${this.nativeElement.height}px`
    );
  }

  /**
   * remove everything related to the ripple effect
   * @param $div
   * @private
   */
  private _removeRippleClasses($div) {
    /**
     * fix bug
     * remove .ripple-effect before removing the actual Dom element
     */
    setTimeout(() => {
      this.renderer.removeClass($div, 'ripple-effect');
    }, this.timeout - 20);

    /**
     * and after 20 milliseconds remove the actual Dom element
     */
    setTimeout(() => {
      this.renderer.removeChild(this.el.nativeElement, $div);
    }, this.timeout);
  }

  private _applyStyle(el, position) {
    this.renderer.addClass(el, 'ripple-effect');
    this.renderer.setStyle(el, 'top', `${position.y}px`);
    this.renderer.setStyle(el, 'left', `${position.x}px`);
    this.renderer.setStyle(el, 'background', this.color);
    this.renderer.setStyle(el, 'opacity', 0.1);
    this.renderer.appendChild(this.el.nativeElement, el);
  }

  /**
   * clean up the native element from .ripple-effect
   * if it is necessary
   * @private
   */
  private _removeOldClasess() {
    this.el.nativeElement.childNodes.forEach(el => {
      const hasClass = RippleDirective.hasClass(el, 'ripple-effect');
      if (hasClass) {
        this.renderer.removeClass(el, 'ripple-effect');
      }
    });
  }

  ngOnDestroy(): void {}
}
