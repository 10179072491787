import { Observable } from "rxjs";

import { scan, map, distinctUntilChanged, share, tap } from "rxjs/operators";

export const bucketFilter = () => (source: Observable<boolean>) => {
  return source.pipe(
    scan(
      (acc, loading) => {
        let _remaining = loading ? ++acc.remaining : --acc.remaining;

        _remaining < 0 && (_remaining = 0);

        return { remaining: _remaining };
      },
      { remaining: 0 }
    ),
    map(x => !!x.remaining),
    distinctUntilChanged(),
    //share()
  );
};
