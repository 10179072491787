import { ViewportScroller } from "@angular/common";
import { ChangeDetectionStrategy, Component, NgZone } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { fadeAnimation } from "@core/core.module/animations/fade.animations";
import { OCBaseComponent } from "@core/core.module/base/base.component";
import { AuthService } from "@core/core.module/jwt/auth.service";
import { pagesToggleService } from "@core/pages-ui.module/services/toggler.service";
import { filter } from "rxjs/operators";
import { MetaDataService } from "../../services/api/clientMetaData/metaData.service";

@Component({
  selector: "home-container",
  templateUrl: "./home-container.html",
  styles: [``],
  animations: [fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeContainerComponent extends OCBaseComponent {
  constructor(
    private scroller: ViewportScroller,
    public auth: AuthService,
    private toggler: pagesToggleService,
    private router: Router,
    private clientMetaData: MetaDataService,
    private zone: NgZone
  ) {
    super();

    //this.toggler.setLoading(true);
    // this.auth.currentProfile$
    //   .pipe(
    //     map(x => x as PlayerProfile),
    //     tap(x => x && x.isVerified === false && this.toggler.setLoading(false)),
    //     //filter(x => x && x.isVerified),
    //     //take(1),
    //     //mergeMap(() => this.data.loadData$()),
    //     untilDestroyed(this)
    //   )
    //   .subscribe();
  }

  // animFinished($event) {
  //   this.scroller.scrollToPosition([0, 0]);
  // }

  ngAfterViewInit(): void {
    const brand = this.clientMetaData.query.current.currentBranding;

    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(x => {
        if (
          brand &&
          this.router.routerState.snapshot.url === "/condensed/home" //TODO
        ) {
          this.zone.run(() =>
            this.router.navigateByUrl(`condensed/home/${brand}`)
          );
        }
      });
  }
}
