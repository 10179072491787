import { Location } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from "@angular/core";
import { ActivatedRoute, ActivationEnd, Router } from "@angular/router";
import { PortalOverlayService } from "@common/services/portal-overlay.service";
import { fadeAnimation } from "@core/core.module/animations/fade.animations";
import { routeSlideAnimations } from "@core/core.module/animations/route-slide.animations";
import { UntilDestroy } from "@core/core.module/decorators/until-destroy.decorator";
import { dialog, msg } from "@core/core.module/interfaces";
import { AuthService } from "@core/core.module/jwt/auth.service";
import { IDLE_TIME } from "@core/core.module/jwt/injection-tokens";
import { AppModeService } from "@core/core.module/services/app-mode.service";
import { METADATA } from "@core/core.module/services/IMetaData";
import { NrtQuery } from "@core/core.module/services/storage/NrtQuery";
import { untilDestroyed } from "@core/core.module/utils/take-until-destroy/take-until-destroy";
import { RootLayout } from "@core/pages-ui.module/layouts/root/root.component";
import { pagesToggleService } from "@core/pages-ui.module/services/toggler.service";
import { filterNil } from "@datorama/akita";
import { TranslocoService } from "@ngneat/transloco";
import { ModalDirective } from "ngx-bootstrap/modal";
import { from, fromEvent, merge, of } from "rxjs";
import {
  bufferTime,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  withLatestFrom
} from "rxjs/operators";
import { LogoutAlertModalComponent } from "../../components/logout-alert-modal/logout-alert-modal.component";
import { BootstrapMetaData } from "../../services/api/clientMetaData/MetaData";
import { LocationsService } from "../../services/api/locations/locations.service";
import { ProfileService } from "../../services/api/profile/profile.service";
import { ProfileTiersService } from "../../services/api/profileTiers/profileTiers.service";
import { RewardsService } from "../../services/api/rewards/rewards.service";
import { SubHeaderLoaderService } from "../sub-header-loader.service";

@UntilDestroy()
@Component({
  selector: "condensed-layout",
  templateUrl: "./condensed-layout.component.html",
  styleUrls: ["./condensed-layout.component.scss"],
  animations: [fadeAnimation, routeSlideAnimations],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CondensedLayoutComponent extends RootLayout
  implements OnInit, AfterViewInit {
  userName: string;
  @ViewChild("menuModal", { static: false }) menuModal: ModalDirective;

  protected isHeaderPinned = true;
  @Input() contentClass = "";

  protected menuOpened = false;

  menu: {
    label: string;
    routerLink: string;
    thumbNailClass: string;
  }[];

  //_loading$ = new BehaviorSubject<boolean>(true);
  loading$ = this.toggler.loading.pipe(delay(1), distinctUntilChanged()); // this._loading$.pipe(/* delay(1),  */distinctUntilChanged());

  idleTime = this.appModeService.isKioskMode()
    ? this.idleTimes?.logoutIdleTimeKiosk
    : this.idleTimes?.logoutIdleTimePlayerPortal

  @ViewChild("subHeader", { read: ViewContainerRef, static: false })
  subHeader: ViewContainerRef;

  isTabletMode = this.appModeService.isTabletMode();

  @ViewChild("headroom") headroomElement;

  constructor(
    protected auth: AuthService,
    protected toggler: pagesToggleService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected tiers: ProfileTiersService,
    protected locations: LocationsService,
    protected rewards: RewardsService,
    //private clientMetaData: MetaDataService,
    private translate: TranslocoService,
    private location: Location,
    private profile: ProfileService,
    protected cd: ChangeDetectorRef,
    private subHeaderService: SubHeaderLoaderService,
    public portalOverlayService: PortalOverlayService,
    private appModeService: AppModeService,
    @Inject(METADATA) public metaDataQuery: NrtQuery<BootstrapMetaData>,
    @Inject(IDLE_TIME) private idleTimes
  ) {
    super(toggler, router, route, auth);

    this.updateMenu();
    this.translate.langChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateMenu();
    });

    if (this.isTabletMode) {
      this.logoutAfterIdleTime();
    }
  }

  brand$ = this.metaDataQuery.select(x => x.currentBranding);

  updateMenu() {
    this.translate
      .selectTranslate(["MENU.HOME", "MENU.PROFILE", "MENU.SETTINGS"])
      .pipe(
        map(x => Object.values(x) as string[]),
        withLatestFrom(this.brand$),
        map(([[home, profile, settings], currentBranding]) => [
          {
            label: home,
            routerLink: `home/${currentBranding}`,
            thumbNailClass: "text-white"
          },
          {
            label: profile,
            routerLink: "profile",
            thumbNailClass: "text-white"
          },
          {
            label: settings,
            routerLink: "settings",
            thumbNailClass: "text-white"
          }
        ]),
        untilDestroyed(this)
      )
      .subscribe(x => {
        this.menu = x;
      });
  }

  ngOnInit() {
    super.ngOnInit();

    this.auth.currentProfile$.pipe(untilDestroyed(this)).subscribe(x => {
      this.userName = x && x.username;
    });
  }

  logoutDialog() {
    dialog
      .confirm(
        this.translate.translate("MESSAGES.LOGOUT_CONFIRMATION_MSG"),
        this.translate.translate("MESSAGES.CONFIRMATION")
      )
      .then(x => {
        x && this.logoutUser();
      });
  }

  logoutUser() {
    this.toggler.setLoading(true);
    super
      .logout()
      .pipe(
        finalize(() => this.toggler.setLoading(false)),
        untilDestroyed(this)
      )
      .subscribe(isLoggedOut => {
        if (isLoggedOut) {
          this.tiers.store.reset();
          this.locations.store.reset();
          this.rewards.store.reset();
          this.profile.store.reset();
        }
      });
  }

  // unpinEvent() {
  //   this.headroomElement.innerStyle.transform = "translateY(-100%) !important"; //hotfix for headroom/angular 9 (#4729) remove after library updates
  // }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
    this.menuModal.toggle();
  }

  isLinkActive(menuItem: {
    label: string;
    routerLink: string;
    thumbNailClass: string;
  }) {
    return this.router.url.includes(menuItem.routerLink);
  }

  linkClick(link) {
    //this.zone.run(() => {
    this.router.navigate([link], { relativeTo: this.route });
    setTimeout(() => this.menuModal.hide(), 200);
    //});
  }

  back() {
    //this.zone.run(() => {
    this._backTo
      ? this.router.navigate([this._backTo], { relativeTo: this.route })
      : this.location.back();
    //});
  }

  logoutAfterIdleTime() {
    const activityStreams$ = merge(
      fromEvent(document, "click"),
      fromEvent(document, "keydown"),
      fromEvent(document, "mousemove"),
      fromEvent(document, "touchstart"),
      fromEvent(document, "scroll")
    ).pipe(debounceTime(250), untilDestroyed(this));

    of(this.idleTime)
      .pipe(
        filterNil,
        switchMap(time => {
          return activityStreams$.pipe(
            bufferTime(time * 1000),
            distinctUntilChanged(),
            switchMap(x => {
              if (x && !x.length) {
                const logoutAlert = dialog.showModal(
                  LogoutAlertModalComponent,
                  {
                    animated: true,
                    ignoreBackdropClick: true
                  }
                );

                return from(logoutAlert.result);
              }
              return of({ isStay: true });
            }),
            untilDestroyed(this)
          );
        })
      )
      .subscribe(x => {
        if (x && !x.isStay) {
          this.logoutUser();
          msg.info(
            this.translate.translate("SESSION.SESSION_ENDED") ||
              "Your Session Ended"
          );
        }
      });
  }

  ngAfterViewInit(): void {
    this.subHeaderService.load(this.subHeader);

    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof ActivationEnd &&
            event.snapshot.children.length == 0
        ),
        untilDestroyed(this)
      )
      .subscribe(event => {
        if (!event["snapshot"].data.SUB_HEADER) {
          this.subHeader.clear();
        }
      });

    this.cd.detectChanges();
  }
}
