import { OverlayContainer } from "@angular/cdk/overlay";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class DynamicOverlayContainer extends OverlayContainer {
  private _overlayContainerChanged$ = new Subject<HTMLElement>();
  public overlayContainerChanged$ = this._overlayContainerChanged$.asObservable();

  public setContainerElement(containerElement: HTMLElement): void {
    this._containerElement = containerElement;
    this._overlayContainerChanged$.next(this._containerElement);
  }

  detach() {
    delete this._containerElement;
    this._overlayContainerChanged$.next();
  }
}
