import {
  TranslocoMissingHandler,
  TranslocoService,
  TRANSLOCO_MISSING_HANDLER,
  DefaultTranspiler,
  TRANSLOCO_TRANSPILER,
  HashMap,
  Translation
} from "@ngneat/transloco";
import { isDevMode, Injectable, Injector } from "@angular/core";
import { of } from "rxjs";
import { delay } from "rxjs/operators";

@Injectable()
export class MessageFormatTranspiler extends DefaultTranspiler {
  transpile(
    value: any,
    params: HashMap<any> = {},
    translation: Translation
  ): any {
    return super.transpile(value, params, translation);
  }
}

@Injectable({ providedIn: "root" })
export class NrtMissingTranslationHandler implements TranslocoMissingHandler {
  private missing: Record<string, string> = {};
  private warned = new Set();
  translateService: TranslocoService;

  constructor(private injector: Injector) {}

  static extractFallback(key: string): string {
    if (key && key.startsWith("@")) {
      const options = key.split("|");
      if (options.length > 1) {
        return options[1];
      }
    }

    return key;
  }

  handle(key: string) {
    const translateService =
      this.translateService ||
      (this.translateService = this.injector.get(TranslocoService));

    if (key && key.startsWith("@")) {
      const options = key.split("|");
      if (options.length === 1) {
        return translateService.translate(key.substr(1)) || key;
      } else if (options.length > 1) {
        const keyi = options[0].substr(1);
        let trans = translateService.translate(keyi);

        if (trans === keyi) {
          this.missing[keyi] = options[1];
          console.table(this.missing);
          return options[1];
        }

        return trans;
      }
    }

    if (isDevMode() && !this.warned.has(key)) {
      console.warn(
        `Missing translation for ${key}.${
          this.missing[key] ? "Fallback" + this.missing[key] : ""
        }`
      );

      this.warned.add(key);
    }

    return this.missing[key] || key;
  }
}

export const TranslocoCustomMissingHandler = {
  provide: TRANSLOCO_MISSING_HANDLER,
  useClass: NrtMissingTranslationHandler
};

export const TranslocoTranspiler = {
  provide: TRANSLOCO_TRANSPILER,
  useClass: MessageFormatTranspiler
};
