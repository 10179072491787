import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { MetaDataService } from '@client-services/api/clientMetaData/metaData.service';
import { UntilDestroy } from '@core/core.module/decorators/until-destroy.decorator';
import { untilDestroyed } from '@core/core.module/utils/take-until-destroy/take-until-destroy';
import { LoadingStages } from '@core/pages-ui.module/services/loading-stages.enum';
import { pagesToggleService } from '@core/pages-ui.module/services/toggler.service';
import { tap } from 'rxjs/operators';
import { METADATA } from '@core/core.module/services/IMetaData';
import { NrtQuery } from '@core/core.module/services/storage/NrtQuery';
import { BootstrapMetaData } from '@client-services/api/clientMetaData/MetaData';

@UntilDestroy()
@Component({
  selector: 'oc-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
  //encapsulation: ViewEncapsulation.ShadowDom,
  animations: [
    trigger('fadeAnimation', [
      state(
        'false',
        style({
          opacity: '0',
          //display: "none",
          visibility: 'hidden'
        })
      ),
      state(
        'true',
        style({
          opacity: '1',
          display: 'flex'
        })
      ),
      transition('false => true', animate('250ms ease-in')),
      transition('true => false', animate('250ms ease-out'))
    ])
  ]
})
export class PageLoaderComponent implements OnInit, OnDestroy {
  loading$ = this.toggler.loadingBucket$.pipe(
    tap(x => !x && this.toggler.setLoadingStage(LoadingStages.NONE))
  );

  loadingText$ = this.toggler.loadingText$;

  brandedLoaderImg: string;

  constructor(
    protected toggler: pagesToggleService,
    private router: Router,
    private zone: NgZone,
    private metaDataService: MetaDataService,
    @Inject(METADATA) public metaDataQuery: NrtQuery<BootstrapMetaData>
  ) {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.toggler.setLoading(true);
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.toggler.setLoading(false);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.brandedLoaderImg = this.metaDataService.query.current?.branding?.themes?.default?.settings[
      'app-loader-img'
      ];
  }

  ngOnDestroy(): void {
  }
}
