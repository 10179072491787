import { Type } from '@angular/core';

/**
 * Adds a service to `appServices` array.
 * Then this service could be added to bootstrap inside `providers` section.
 * The sample of using in app.module.ts:
 * @NgModule({
 *      providers: [
 *          appServices,
 *          ...
 *      ],
 *      ...
 *  })
 *  export class AppModule { }
 */
export const AppService = () => target => {
    if (!appServices.includes(target)) {
        appServices.push(target);
    }
}

/**
 * List of all components marked by @AppService decorator.
 */
export const appServices: Type<any>[] = [];
