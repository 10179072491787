import { HttpClient } from "@angular/common/http";
import { IEntityId } from "@core/core.module/base/IEntityId";
import { IEntityKey } from "@core/core.module/base/IEntityKey";
import { ApiMethod } from "@core/core.module/decorators/api-method.decorator";
import { ISerializer } from "@core/core.module/services/ISerializer";
import {
  applyTransaction,
  EntityState,
  getEntityType,
  getIDType,
  OrArray
} from "@datorama/akita";
import { Observable } from "rxjs/Observable";
import { map, tap } from "rxjs/operators";
import { isBoolean } from "util";
import { IDataQueryModel } from "./IDataQueryModel";
import { IPagedResult } from "./IPagedResult";
import { NrtApiService } from "./NrtApiService";
import { NrtHttpRequestOptions } from "./NrtHttpRequestOptions";
import { NrtEntityStorage } from "./storage/NrtEntityStorage";
import { NrtEntityStore } from "./storage/NrtEntityStore";
import { NrtQueryEntity } from "./storage/NrtQueryEntity";

export abstract class NrtEntityApiService<
  TEntity extends IEntityId | IEntityKey = any,
  TState extends EntityState<TEntity> = any
> extends NrtApiService {
  get store(): NrtEntityStore<TState> {
    return this.storage.store as NrtEntityStore<TState>;
  }

  get query(): NrtQueryEntity<TState, TEntity> {
    return this.storage.query as NrtQueryEntity<TState, TEntity>;
  }

  identityKey: (entity: TEntity) => getIDType<TState> = e => {
    if (e["id"]) {
      return e["id"];
    } else {
      throw new Error("IdentityKey Id is undefined");
    }
  };
  isPristine: boolean = true;

  constructor(
    protected http?: HttpClient,
    protected storageOrName?: string | NrtEntityStorage<TState, TEntity>,
    public serializer?: ISerializer<TEntity>
  ) {
    super(http);

    if (typeof this.storageOrName === "string") {
      this.storage = this.createStore(this.storageOrName);
    } else {
      this.storage = this.storageOrName as NrtEntityStorage;
    }
  }

  private createStore(storeName: string): NrtEntityStorage {
    const store = new NrtEntityStore({ total: 0 } as any, {
      name: storeName,
      idKey: "__id"
    });
    const query = new NrtQueryEntity(store);
    return { store, query };
  }

  getEntity$(params?: any, options?: NrtHttpRequestOptions) {
    this.store && this.store.setLoading(true);
    return super.request$(params, options).pipe(
      // startWith(
      //   this.query &&
      //     this.query.getEntity((params && this.identityKey(params)) || null)
      // ),
      tap((x: any) => {
        if (x && this.store) {
          this.store.upsert(this.identityKey(x as TEntity), { ...x });
          this.store.setLoading(false);
        }
      }),
      map(
        x =>
          ((x && this.serializer && this.serializer.fromDTO(x)) || x) as TEntity
      )
    );
  }

  upsertEntity$(params?: any, options?: NrtHttpRequestOptions) {
    this.store && this.store.setLoading(true);
    return super.request$(params, options).pipe(
      tap((x: any) => {
        if (x && this.store) {
          this.store.upsert(this.identityKey(x as TEntity), x);
          this.store.setLoading(false);
        }
      }),
      map(
        x =>
          ((x && this.serializer && this.serializer.fromDTO(x)) || x) as TEntity
      )
    );
  }

  getEntityArray$<T = TEntity>(
    params?: any,
    options?: NrtHttpRequestOptions
  ): Observable<TEntity[]> {
    this.store && this.store.setLoading(true);
    return super.request$(params, options).pipe(
      //startWith(this.query ? this.query.getAll() : []),
      tap((x: TEntity[]) => {
        if (x && this.store) {
          applyTransaction(() => {
            this.store.setLoading(false);
            //if (this.isPristine) {
            this.store.set(
              x.map((d: TEntity) => {
                return ({
                  ...d,
                  ["__id"]: this.identityKey(d)
                } as unknown) as getEntityType<TState>;
              })
            );
            //this.isPristine = false;
            // } else {
            //   x.forEach(d => {
            //     const key = this.identityKey(d as TEntity);
            //     this.store.upsert(key, { ...d, ["__id"]: key });
            //   });
            // }
          });
        }
      }),
      map((data: TEntity[]) =>
        data && this.serializer
          ? data.map(item => {
              return ((item &&
                this.serializer &&
                this.serializer.fromDTO(item)) ||
                item) as T & TEntity;
            })
          : data
      )
    );
  }

  getEntityPagedArray$(
    params: Partial<IDataQueryModel & Record<string, any>>,
    options?: any
  ): Observable<IPagedResult<TEntity>> {
    let reqParams = Object.assign({}, params);
    //if (reqParams.filters) {
    if (reqParams.filterParams && reqParams.filterParams.length) {
      reqParams.filterParams.map(
        x => isBoolean(x.param) && (reqParams[x.value] = x.param)
      );
      delete reqParams.filterParams;
    }
    // else {
    //   reqParams.filters.map(x => reqParams[x] = true);
    // }
    // delete reqParams.filters;
    //}

    // if (this.store) {
    //   //entities = entities || this.query.getAll();
    //   entities =
    //     entities ||
    //     (reqParams.sortBy
    //       ? this.query.getAll({
    //           sortBy: reqParams.sortBy as keyof TEntity,
    //           sortByOrder: reqParams.isDescending ? Order.DESC : Order.ASC
    //         })
    //       : this.query.getAll());
    //   this.store.setLoading(true);
    // }
    return super.request$<IPagedResult<TEntity>>(reqParams, options).pipe(
      // startWith({
      //   total: entities.length,
      //   data: entities
      // }),
      tap(curr => {
        if (curr && curr.data && this.store) {
          applyTransaction(() => {
            this.store.setLoading(false);
            this.store.update(({ total: curr.total } as unknown) as TState);
            //if (this.isPristine) {
            this.store.set(
              curr.data.map((d: TEntity) => {
                return ({
                  ...d,
                  ["__id"]: this.identityKey(d as TEntity)
                } as unknown) as getEntityType<TState>;
              })
            );
            this.isPristine = false;
            // } else {
            //   curr.data.forEach(d => {
            //     const key = this.identityKey(d as TEntity);
            //     this.store.upsert(key, {
            //       ...d,
            //       ["__id"]: key
            //     });
            //   });
            // }
          });
        }
      }),
      map((pagedResult: IPagedResult<TEntity>) => {
        pagedResult &&
          (pagedResult.data = pagedResult.data
            ? pagedResult.data.map(item => {
                return (
                  (item && this.serializer && this.serializer.fromDTO(item)) ||
                  item
                );
              })
            : []);
        return pagedResult;
      })
    );
  }

  @ApiMethod({ method: "DELETE", route: "{id}" })
  public delete$(
    id: string & OrArray<getIDType<TState>>,
    options?: NrtHttpRequestOptions
  ) {
    return this.request$({ id }, options).pipe(
      tap(x => this.store && this.store.remove(id))
    );
  }

  @ApiMethod({ method: "POST" })
  public create$(profile: Partial<TEntity>): Observable<TEntity> {
    return this.upsertEntity$(
      this.serializer ? this.serializer.toDTO(profile) : profile
    );
  }

  @ApiMethod({ method: "PUT", route: "{id}" })
  public update$(id: string, profile: Partial<TEntity>): Observable<TEntity> {
    return this.upsertEntity$({
      id: id,
      $raw: this.serializer ? this.serializer.toDTO(profile) : profile
    });
  }

  public get$<T>(id: string): Observable<T & TEntity>;
  @ApiMethod({ method: "GET", route: "{id}" })
  public get$(id: string): Observable<TEntity> {
    return this.getEntity$({ id });
  }

  @ApiMethod({ method: "GET" })
  public getPagedList$(
    params: IDataQueryModel
  ): Observable<IPagedResult<TEntity>> {
    return this.getEntityPagedArray$(params);
  }

  @ApiMethod({ method: "GET" })
  public getList$(): Observable<TEntity[]> {
    return this.getEntityArray$();
  }

  storeRequest$<T>(
    storeObjectKey: keyof TState,
    params?: any,
    options?: NrtHttpRequestOptions
  ): Observable<T> {
    return super.storeRequest$(storeObjectKey as string, params, options);
  }
}
