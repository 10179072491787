export interface ICacheOptionsInterface {
  /**
   * Expires timestamp
   */
  expires?: number;

  /**
   * Max age in seconds
   */
  maxAge?: number;

  /**
   * Tag for this key
   */
  tag?: string;
}

export const enum CacheStorages {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  MEMORY,
  INDEXED_DB
}

export interface ICacheService {
  /**
   * Set data to cache
   * @param key
   * @param value
   * @param options
   */
  set(key: string, value: any, options?: ICacheOptionsInterface): void;

  /**
   * Get data from cache
   * @param key
   * @returns
   */
  get(key: string): any;

  /**
   * Check if value exists
   * @param key
   * @returns
   */
  exists(key: string): boolean;

  /**
   * Remove item from cache
   * @param key
   */
  remove(key: string): void;

  /**
   * Remove all from cache
   */
  removeAll(): void;

  /**
   * Get all tag data
   * @param tag
   * @returns
   */
  getTagData(tag: string): any[];

  /**
   * Create a new instance of cache with needed storage
   * @param type
   * @returns
   */
  useStorage(type: CacheStorages): ICacheService;

  /**
   * Remove all by tag
   * @param tag
   */
  removeTag(tag: string): void;

  /**
   * Set global cache key prefix
   * @param prefix
   */
  setGlobalPrefix(prefix: string): void;
}

export declare type TCacheOptions = {
  storage?: 'memory' | 'localStorage' | 'session' | ICacheService;
  /**
   * Время жизни данных в кеше в секундах
   */
  ttl?: number;

  tag?: string;
  /**
   * Кешировать ошибки запросов
   */
  cacheErrors?: boolean;
};

export interface IApiMethodMetadata {
    /**
     * Name of HTTP-method.
     * If not set - for methods starts with Load and Get it will be 'GET',
     * with Delete and Remove - 'DELETE',
     * others - 'POST'.
     */
    method?: string;

    /**
     * Name of WebApi route.
     * If parameters transfers by url - they must be included in route as 'routeAddress/{param1}/{param2}...'
     */
    route?: string;

  /**
   * Indicates how to transfer parameters or request. false - by url, true - by request body.
   * For methods GET/DELETE/HEAD/OPTIONS - ignoring (always by url)
   * For POST/PUT/PATCH by default uses body (parameters transfered by url will be excluded from transfering object)
   * In other cases by default is false.
   * If useBody equals "raw" the value will be passed in body as is.
   */
  useBody?: boolean | 'raw';

    /**
     * Name of calling method
     */
    caller?: string;

    /**
     * Gets the caching options.
     * If 'cache' value in false, null or undefined - no caching
     * If 'cache' is true - the DefaultCacheOptions will be apllied
     * If 'cache' has a string value - the DefaultCacheOptions will be apllied with Tag = the 'cache' value.
     */
    cache?: TCacheOptions | boolean;

    /**
     * If defined - overrides API URL.
     */
    apiUrl?: string;
}

export class ApiMethodMetadata implements IApiMethodMetadata {
  /**
   * Name of HTTP-method.
   * If not set - for methods starts with Load and Get it will be 'GET',
   * with Delete and Remove - 'DELETE',
   * others - 'POST'.
   */
  method: string;

  /**
   * Name of WebApi route.
   * If parameters transfers by url - they must be included in route as 'routeAddress/{param1}/{param2}...'
   */
  route: string;

  /**
   * Indicates how to transfer parameters or request. false - by url, true - by request body.
   * For methods GET/DELETE/HEAD/OPTIONS - ignoring (always by url)
   * For POST/PUT/PATCH by default uses body (parameters transfered by url will be excluded from transfering object)
   * In other cases by default is false.
   */
  useBody: boolean | 'raw';

  /**
   * If defined - overrides API URL.
   */
  apiUrl?: string;

  constructor(metadata: IApiMethodMetadata) {
    if (metadata) {
      this.method = metadata.method;
      this.route = metadata.route;
      this.useBody = metadata.useBody;
    }
  }
}

export const ApiMethod = (metadata?: IApiMethodMetadata) => (
  target: any,
  propertyKey: string,
  descriptor: PropertyDescriptor
) => {
  const oldFn = descriptor.value;
  let func = function() {
    metadata = metadata || {};
    metadata.caller = propertyKey;
    this['__apiMethod'] = metadata;

    return oldFn.apply(this, arguments);
  };
  func['__metadata'] = metadata;
  descriptor.value = func;
};
