import { Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject, combineLatest, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class CountdownCircleService {
  private _countdownTicking$ = new ReplaySubject<boolean>(1);
  countdownTicking$ = this._countdownTicking$.asObservable();

  private _isCountdownReset$ = new Subject<boolean>();
  isCountdownReset$ = this._isCountdownReset$.asObservable();

  constructor() {}

  startCountdown() {
    this._countdownTicking$.next(true);
  }

  stopCountdown() {
    this._countdownTicking$.next(false);
  }

  resetCountdown() {
    this._isCountdownReset$.next(true);
  }
}
