import { HttpClient } from "@angular/common/http";
import { Optional } from "@angular/core";
import { msg } from "@core/core.module/interfaces/core-messages.service";
import { OCApiService } from "@core/core.module/services/api.service";
import { TranslocoService } from "@ngneat/transloco";
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import { catchError } from "rxjs/internal/operators/catchError";
import {
  filter,
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
  timeout
} from "rxjs/operators";
import { OCBaseComponent } from "../base/base.component";
import { IApiMethodMetadata } from "../decorators";
import { rootInjector$ } from "../utils/root-injector";
import { HttpStandartRequestOptions } from "./HttpStandartRequestOptions";
import { NrtHttpRequestOptions } from "./NrtHttpRequestOptions";
import { NrtEntityStorage } from "./storage/NrtEntityStorage";
import { NrtStorage } from "./storage/NrtStorage";

//export const skipCache = () => (source: Observable<any>) => source.pipe(last());

export interface IHttpCustomRequestOptions extends HttpStandartRequestOptions {
  skipError?: boolean;
}

export const startLoader = <T>(
  instance: BehaviorSubject<boolean> | OCBaseComponent,
  timeoutSec?: number
) => (source: Observable<T>) => {
  const finalize$ = new Subject();

  const loader$ =
    instance instanceof OCBaseComponent ? instance.loader$ : instance;

  timeoutSec &&
    loader$
      .pipe(
        filter(loading => !loading),
        timeout(timeoutSec),
        catchError(() => {
          loader$.next(false);
          return of();
        }),
        takeUntil(finalize$)
      )
      .subscribe();

  const propagateLoader = () => {
    !loader$.value && loader$.next(true);
  };

  return new Observable<T>(observer => {
    propagateLoader();
    const sub = source
      .pipe(
        tap(_ => propagateLoader()),
        finalize(() => {
          loader$.next(false);
          finalize$.next();
        })
      )
      .subscribe(observer);

    return sub;
  });
};

export const stopLoader = (
  instance: BehaviorSubject<boolean> | OCBaseComponent
) => (source: Observable<any>) => {
  const loader$ =
    instance instanceof OCBaseComponent ? instance.loader$ : instance;

  return source.pipe(tap(_ => loader$.next(false)));
};

export abstract class NrtApiService extends OCApiService {
  private xTarget: string;
  private pseudoLocale: string;

  translateService: TranslocoService;

  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public loading$: Observable<boolean> = this._loading$.asObservable();

  public startLoading() {
    this._loading$.next(true);
  }
  public stopLoading() {
    this._loading$.next(false);
  }

  constructor(
    @Optional() protected http?: HttpClient,
    //@Inject(forwardRef(() => NrtStorage))
    @Optional() protected storage?: NrtStorage | NrtEntityStorage
  ) {
    super(http);

    const xTarget = localStorage.getItem("X-Target");
    xTarget && (this.xTarget = xTarget);

    const pseudoLocale = localStorage.getItem("Pseudo-Locale");
    pseudoLocale && (this.pseudoLocale = pseudoLocale);
  }

  request$<T>(
    params?: any,
    options?: NrtHttpRequestOptions,
    descr?: IApiMethodMetadata
  ): Observable<T> {
    const headers = {};
    if (this.xTarget) {
      headers["X-Target"] = this.xTarget;
    }

    //important! create base request in a synchronous flow
    const baseReq$ = super.request$<T>(
      params,
      Object.assign(options || {}, { headers: headers }),
      descr
    );
    const req = rootInjector$.pipe(
      first(),
      tap(injector => {
        this.translateService =
          this.translateService || injector?.get(TranslocoService, null);
        if (
          this.translateService &&
          (this.pseudoLocale || this.translateService.getActiveLang())
        ) {
          headers["Locale"] =
            this.pseudoLocale || this.translateService.getActiveLang();
        }
      }),
      switchMap(() => {
        return baseReq$.pipe(
          catchError<T, any>(err => {
            if (!options || !options.skipErrorMessage) {
              if (
                err &&
                err.error &&
                err.error.errors &&
                err.error.errors.length
              ) {
                (err.error.errors as any[]).forEach(m =>
                  msg.error(m.message || m)
                );
              } else {
                msg.error(err.message);
              }
            }
            return throwError(err);
          }),
          map(x => x as T)
        );
      })
    );

    return req;
  }

  storeRequest$<T>(
    storeObjectKey: string,
    params?: any,
    options?: NrtHttpRequestOptions
  ): Observable<T> {
    return this.request$<T>(params, options).pipe(
      tap(x => {
        x && this.storage.store.update(s => ({ ...s, [storeObjectKey]: x }));
      })
    );
  }
}
