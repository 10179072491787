import { ApiMethod } from "@core/core.module/decorators/api-method.decorator";
import { IMetaData } from "@core/core.module/services/IMetaData";
import { NrtApiService } from "@core/core.module/services/NrtApiService";
import { from, Observable } from "rxjs";
import { last, map, scan, switchMap } from "rxjs/operators";
import { MetadataType } from "./MetadataType.enum";

export abstract class BaseMetaDataService extends NrtApiService {
  @ApiMethod({
    method: "GET",
    route: "{type}",
    cache: true
  })
  public getMetadata$<T>(...types: MetadataType[]): Observable<T> {
    if (types.length === 1) {
      return this.request$<IMetaData<T>>(
        { type: types[0] },
        { skipErrorMessage: true }
      ).pipe(map(x => x.metadata));
    } else {
      return this.request$<IMetaData<any>[]>(
        { type: types.join(",") },
        { skipErrorMessage: true }
      ).pipe(
        switchMap(x => from(x)),
        scan((acc, curr) => {
          acc[curr.type] = curr.metadata;
          return acc;
        }, {} as T),
        last()
      );
    }
  }
}
