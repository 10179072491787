import {
  Overlay,
  OverlayRef,
  OverlayContainer
} from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable, Type } from "@angular/core";
import { MessageContainerComponent } from "./message-container.component";
import {
  MessageData,
  MessageDataFilled,
  MessageDataOptions
} from "./message.definitions";
import { DynamicOverlayContainer } from "@core/core.module/base/overlay/dynamic-overlay-container";

// TODO: remove MessageData generic type as it has no contributon in typing
export abstract class MessageBaseService<
  ContainerClass extends MessageContainerComponent,
  TMessageData extends MessageData
> {
  protected _counter = 0; // Id counter for messages
  protected _container: ContainerClass;

  overlayRef: OverlayRef;

  constructor(
    protected overlay: Overlay,
    protected overlayContainer: OverlayContainer,
    protected containerClass: Type<ContainerClass>,
    private _idPrefix: string = ""
  ) {
    this.createOverlay();

    if (overlayContainer instanceof DynamicOverlayContainer) {
      (overlayContainer as DynamicOverlayContainer).overlayContainerChanged$.subscribe(
        el => {
          this.createOverlay();
        }
      );
    }
  }

  private createOverlay() {
    this.overlayRef && this.overlayRef.dispose();
    this.overlayRef = this.overlay.create();
    this._container = this.overlayRef.attach(
      new ComponentPortal(this.containerClass)
    ).instance;
  }

  remove(messageId?: string): void {
    if (messageId) {
      this._container.removeMessage(messageId);
    } else {
      this._container.removeMessageAll();
    }
  }

  createMessage(
    message: TMessageData,
    options?: MessageDataOptions
  ): MessageDataFilled {
    // TODO: spread on literal has been disallow on latest proposal
    const resultMessage: MessageDataFilled = {
      ...message,
      ...{
        messageId: this._generateMessageId(),
        options,
        createdAt: new Date()
      }
    };
    this._container.createMessage(resultMessage);

    return resultMessage;
  }

  protected _generateMessageId(): string {
    return this._idPrefix + this._counter++;
  }
}

@Injectable({ providedIn: "root" })
export class MessageService extends MessageBaseService<
  MessageContainerComponent,
  MessageData
> {
  constructor(overlay: Overlay, overlayContainer: OverlayContainer) {
    super(overlay, overlayContainer, MessageContainerComponent, "message-");
  }

  // Shortcut methods
  success(content: string, options?: MessageDataOptions): MessageDataFilled {
    return this.createMessage({ type: "success", content }, options);
  }

  error(content: string, options?: MessageDataOptions): MessageDataFilled {
    return this.createMessage({ type: "error", content }, options);
  }

  info(content: string, options?: MessageDataOptions): MessageDataFilled {
    return this.createMessage({ type: "info", content }, options);
  }

  warning(content: string, options?: MessageDataOptions): MessageDataFilled {
    return this.createMessage({ type: "warning", content }, options);
  }

  create(
    type: string,
    content: string,
    options?: MessageDataOptions
  ): MessageDataFilled {
    return this.createMessage({ type, content }, options);
  }
}
