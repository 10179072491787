import { InjectionToken } from "@angular/core";
import { Query } from "@datorama/akita";
import { Observable } from "rxjs";
import { IFormFieldMetadata } from "../base/IFormFieldMetadata";

export const METADATA = new InjectionToken<
  Query<IMetaData<IClientAccountMetadata>>
>("MetaData");
export const METADATA_SERVICE = new InjectionToken<IMetaDataService>(
  "MetaDataService"
);

export interface IPlayerSettingsMetadata {
  sections: ISectionMetadata[];
}

export interface ISectionMetadata {
  key: string;
  maxAllowedCount: number;
  type: string;
  items: ISectionsItemMetadata[];
}

export interface ISectionsItemMetadata {
  key: number;
  selected: boolean;
}

export interface ITermsAndConditionsMetadata {
  html: string;
}

export interface IDaysDeletingMetadata {
  days: number;
}

export interface IGoogleAnalyticsMetadata {
  googleAnalyticsKey: string;
}

export interface IGoogleTagManagerMetadata {
  googleTagManagerKey: string;
}
export interface IRewardsManagementMetadata {
  columns: IFormFieldMetadata[];
}

export interface IWorksReportMetadata {
  columns: IFormFieldMetadata[];
}

export interface ISignUpOptionsMetadata {
  allowAnonymousSignUp: boolean;
  allowPasswordRecover: boolean;
  allowAccountRecover: boolean;
}

export interface IFieldMetadata {
  fieldName: string;
  order?: number;
  readonly?: boolean;
  label?: string;
  //validators?: ValidatorType[];
}

export interface IAccountMetadata {
  hash: string;
  name: string;
  clientLogoUrl: string;
  datetimeFormat: number;
  smsResubscribeNumber: string;
  csvColumnSeparator: string;
}

export interface IKioskLobbyTokenMetadata {
  accessToken: string;
}

export interface KioskMilestone {
  trips: number;
  points: number;
  milestone: string;
  benefit: string;
  guestValue: number;
  imageUrl: string;
}

export interface IKioskMilestonesConfigMetadata {
  milestones: KioskMilestone[];
}

export interface ILocalizationMetadata {
  locale: string;
  title: string;
}

export interface IClientAccountMetadata {
  account: IAccountMetadata;
  localizations: ILocalizationMetadata[];
}

export interface ILocalizationMetadata {
  locale: string;
  title: string;
}

export interface IMetaData<T> {
  //clientAccount: IClientAccountMetadata;

  type: string;
  metadata: T;

  //currentBranding: string;
  //features?: IMetaDataFeatures[];
}

export interface IMetaDataService {
  signUpOptions$: Observable<ISignUpOptionsMetadata>;
  ssoProviders$: Observable<ISocialNetworkProvider>;
  signUpFields$: Observable<IFieldMetadata[]>;
  playerSettings$: Observable<IPlayerSettingsMetadata>;
  daysDeleting$: Observable<IDaysDeletingMetadata>;
  profileCheck$: (
    externalId: string,
    hash: string
  ) => Observable<IPlayerProfileCheckResult>;
  termsAndConditions$: Observable<ITermsAndConditionsMetadata>;
}

// export interface IMetaDataFeatures {
//   name: string;
//   settings: ISocialNetworkProviderSettings; //TODO: settings can be extended?
// }

// export interface ISocialNetworkProviderSettings {
//   providers?: ISocialNetworkProvider[];
// }

export interface ISocialNetworkProvider {
  authEndpoint?: any;
  type: string;
  appId: string;
  isEnabled: boolean;
}

export interface IPlayerProfileCheckResult {
  isEmailSpecified: boolean;
  isPhoneSpecified: boolean;
  phoneNumber?: string;
  isPlayerSignedOn?: boolean;
}

export interface IExternalProfileMetadata {
  tierDetailsEndpoint: ITierDetailsEndpoint;
  behaviorType?: string;
  useApiMock?: boolean;
}

export interface IExternalRewardsMetadata {
  rewardListEndpoint: IExternalRewardsListEndpoint;
  rewardInfoEndpoint: IExternalRewardsInfoEndpoint;
}

export interface IExternalRewardsListEndpoint {
  endpointUri: string;
  queryOptions: any;
  hasPaginationSupport: boolean;
  pageSize: number;
}

export interface IExternalRewardsInfoEndpoint {
  endpointUri: string;
  queryOptions: any;
  hasPaginationSupport: boolean;
  pageSize: number;
}

export interface ITierDetailsEndpoint {
  endpointUri: string;
  queryOptions: any;
  hasPaginationSupport: boolean;
  pageSize: number;
}

export interface IBrandingMetadata {
  iconUri: string;
  logoUri: string;
  backgroundImageUri: string;
  topSectionBackgroundImageUri?: string;
  mainSectionBackgroundImageUri?: string;
  screenSaverImageUris: string[];
  offersLearnMoreUri?: string;
  themes: IBrandingThemes;
  externalOffersIconsUri?: string;
  externalDrawingsIconsUri?: string;
}

export interface IBrandingThemes {
  light: IBrandingTheme;
  dark: IBrandingTheme;
  default?: IBrandingTheme;
  //themes: Record<string, Record<string, string>>;
}

export interface IBrandingTheme {
  cssProps?: { [key: string]: string };
  settings?: { [key: string]: string };
}

export interface IPortalLogoutIdleMetadata {
  LogoutIdleTimeKiosk: string;
  LogoutIdleTimePlayerPortal: string;
}

export interface IKioskModeMetadata {
  logoutIdleTimeKiosk: string;
  logoutIdleTimePlayerPortal: string;
}

export interface IPlayerPortalSettingsMetadata {
  unauthorizedRedirectUri: string;
  accessToken: string;
}

export interface IMedallionsConfigMetadata {
  accessToken: string;
  gameApiKey: string;
  groupsKey: string;
  groups: string[];
}

export interface ILobbyConfigMetadata {
  AccessToken: string;
  GameApiKey?: string;
  GroupsKey?: string;
  Groups?: string[];
}
