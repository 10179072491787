<!-- <div class="btn-group btn-group-sm">
  <button
    type="button"
    class="btn btn-secondary"
    [(ngModel)]="lang"
    btnRadio="en-US"
  >
    EN
  </button>
  <button
    type="button"
    class="btn btn-secondary"
    [(ngModel)]="lang"
    btnRadio="es-MX"
  >
    SPA
  </button>
</div> -->

<div
  class="form-group row"
  *ngIf="control && metaDataQuery?.current$ | async as meta"
  [ngClass]="{ required: control.required }"
  [class.has-error]="control.invalid && (control.dirty || control.touched)"
  pgFormGroupDefault
>
  <div
    *ngIf="meta?.clientAccount?.localizations?.length > 1"
    class="p-l-5 radio radio-primary"
    [ngStyle]="{ filter: invert ? 'invert(1) grayscale(1)' : '' }"
  >
    <ng-container *ngFor="let r of meta?.clientAccount?.localizations">
      <input
        type="radio"
        [value]="r.locale"
        [attr.id]="r.locale"
        [attr.name]="control.name"
        [formControl]="control"
      />
      <label [attr.for]="r.locale">{{ r.title }}</label>
    </ng-container>

    <!-- <input
      type="radio"
      value="en-US"
      id="en"
      [attr.name]="control.name"
      [formControl]="control"
    />
    <label for="en">EN</label>
    <input
      type="radio"
      value="es-MX"
      id="es"
      [attr.name]="control.name"
      [formControl]="control"
    />
    <label for="es">SPA</label> -->

    <!-- <ng-container *ngTemplateOutlet="errors; context: { control: control }"></ng-container> -->
  </div>
</div>
