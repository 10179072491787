import {
  Compiler,
  NgModuleFactory,
  Type,
  NgModuleRef,
  Injector
} from "@angular/core";
import { addChildInjector } from "./root-injector";

export class LazyModuleFactory<T = any> extends NgModuleFactory<T> {
  moduleType: Type<T>;

  private static _loadedModules: { [key: string]: NgModuleRef<any> } = {};

  private _moduleRef: NgModuleRef<T>;

  private static _compiler = Compiler.ɵfac();

  constructor(private module: Type<T> | (() => Promise<Type<T>>), private cacheKey?: string) {
    super();
  }

  static produce<T>(module: Type<T> | (() => Promise<Type<T>>), cacheKey?: string): () => Promise<LazyModuleFactory<T>> {
    return async () => {
      const factory = new LazyModuleFactory(module, cacheKey);
      return await factory.resolve();
    };
  }

  private async resolve() {
    !this.moduleType && (this.moduleType =
      typeof this.module === "function"
        ? await (this.module as () => Promise<Type<T>>)()
        : this.module);

    return this;
  }

  create(parentInjector: Injector): NgModuleRef<T> {

    const key = this.cacheKey || this.moduleType.name || this.module.name || this.module.toString();
    const cached = LazyModuleFactory._loadedModules[key];

    if (cached) {
      addChildInjector(cached.injector);
      return cached;
    } else {
      const m = LazyModuleFactory._compiler.compileModuleSync(this.moduleType);
      this._moduleRef = m.create(parentInjector);
      addChildInjector(this._moduleRef.injector);
      LazyModuleFactory._loadedModules[key] = this._moduleRef;

      return this._moduleRef;
    }
  }
}
