import { DataSourcesService } from "./dataSources.service";
import { Injectable } from "@angular/core";
import { combineLatest, Observable, Subject } from "rxjs";
import { pagesToggleService } from "@core/pages-ui.module/services/toggler.service";
import { tap, takeUntil, exhaustMap } from "rxjs/operators";
import { AuthService } from "@core/core.module/jwt/auth.service";
import { cacheClean$ } from "@core/cache.module/cache.interceptor";
import { LoadingStages } from "@core/pages-ui.module/services/loading-stages.enum";

@Injectable({ providedIn: "root" })
export class DataService {
  private _hasCache: boolean = false;
  get hasCache() {
    return this._hasCache;
  }

  private _load$ = new Subject();

  constructor(
    protected toggler: pagesToggleService,
    private dataSources: DataSourcesService,
    private auth: AuthService
  ) {
    this.auth.userLogout$.subscribe(() => (this._hasCache = false));

    this._load$.pipe(exhaustMap(() => this.loadData$(true))).subscribe();
  }

  queueForceLoadData() {
    this._load$.next(true);
  }

  loadData$<T extends Array<any>>(clearCache?: boolean): Observable<T> {
    clearCache && cacheClean$.next(true);
    this.toggler.setLoadingStage(LoadingStages.DATA);

    return combineLatest<T>(
      this.dataSources.clientSources.map(ds => {
        return ds.produce();
      })
    ).pipe(
      tap(datas => {
        !this._hasCache &&
          datas.every(_ => _ !== undefined) &&
          (this._hasCache = true);

        this._hasCache && this.toggler.setLoading(false);
      }),
      takeUntil(this.auth.userLogout$)
    );
  }
}
