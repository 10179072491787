<ng-container *ngIf="display$ | async as display">
  <section [class.open]="display === 'open'" (click)="close()">
    <div (click)="$event.stopPropagation()">
      <div class="modal-text">
        <h1>Do you need more time?</h1>
        <h1 class="seconds">{{ timerValue }}</h1>
      </div>
      <div class="modal-actions">
        <button type="button" class="continue" (click)="continue()">Yes</button>
        <button type="button" class="close" (click)="close()">No</button>
      </div>
    </div>
  </section>
</ng-container>
