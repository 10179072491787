import { Injectable } from "@angular/core";
import { ClientDataSource } from "@core/core.module/services/dataSource/ClientDataSource";


@Injectable({ providedIn: "root" })
export class DataSourcesService {
  constructor() {}

  private _clientSources: ClientDataSource[];

  get clientSources(): ClientDataSource[] {
    return this._clientSources;
  }

  setClientSources(...sources: ClientDataSource[]) {
    this._clientSources = sources;
  }
}
