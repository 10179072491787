import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ensureClassIsDecorated, DESTROY_SUBJECT_NAME } from './common';

function isFunction(value) {
  return typeof value === 'function';
}

export const untilDestroyed = (
  componentInstance,
  destroyMethodName = 'ngOnDestroy'
) => <T>(source: Observable<T>) => {
  const originalDestroy = componentInstance[destroyMethodName];
  if (isFunction(originalDestroy) === false) {
    throw new Error(
      `${
        componentInstance.constructor.name
      } is using untilDestroyed but doesn't implement ${destroyMethodName}`
    );
  }

  ensureClassIsDecorated(componentInstance);

  if (!componentInstance[DESTROY_SUBJECT_NAME]) {
    componentInstance[DESTROY_SUBJECT_NAME] = new Subject();

    // componentInstance[destroyMethodName] = function() {
    //   isFunction(originalDestroy) && originalDestroy.apply(this, arguments);
    //   componentInstance[subjectName].next(true);
    //   componentInstance[subjectName].complete();
    // };
  }
  return source.pipe(takeUntil<T>(componentInstance[DESTROY_SUBJECT_NAME]));
};
