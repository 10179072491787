import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { pgCardModule } from "@core/pages-ui.module/components/card/card.module";
import { LogoutAlertModalComponent } from "./logout-alert-modal.component";
import { BsModalService, ModalModule } from "ngx-bootstrap/modal";
import { pgIconModule } from "@core/pages-ui.module/components/icon/menu-icon.module";
import { TranslocoModule } from "@ngneat/transloco";

@NgModule({
  imports: [
    ModalModule,
    CommonModule,
    pgCardModule,
    pgIconModule,
    TranslocoModule
  ],
  declarations: [LogoutAlertModalComponent],
  entryComponents: [LogoutAlertModalComponent],
  providers: [BsModalService]
})
export class LogoutAlertModalModule {}
