/// <reference types="google.analytics" />
/// <reference path="../../typings.d.ts" />

import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AssetsLoader } from '@core/core.module/utils/AssetsLoader';
import { from, of, throwError, Subject, Observable, combineLatest } from 'rxjs';
import {
  switchMap,
  tap,
  retry,
  delay,
  first,
  debounceTime,
  share,
  map,
  withLatestFrom,
  shareReplay,
  take,
  filter
} from 'rxjs/operators';
import { AuthService } from '@core/core.module/jwt/auth.service';
import { filterNil } from '@datorama/akita';
import { AuthData } from '@core/core.module/jwt/AuthData';
import { MetaDataService } from './api/clientMetaData/metaData.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private _body = document.getElementsByTagName('body')[0];
  private _head = document.getElementsByTagName('head')[0];

  private _keys$ = this.metadata.googleAnalytics$.pipe(
    filterNil,
    take(1),
    shareReplay(1)
  );

  private _tagManager$ = this._keys$.pipe(
    filter(x => x && !!x.GoogleTagManager && !!x.GoogleTagManager.googleTagManagerKey),
    map(x => x.GoogleTagManager.googleTagManagerKey),
    filterNil,
    tap(key => {
      AssetsLoader.injectScript(
        this._head,
        `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${key}');`,
        true
      );

      this._body.insertAdjacentHTML(
        'afterbegin',
        `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${key}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`
      );
    })
  );

  private _gaTracker$: Observable<
    Readonly<UniversalAnalytics.Tracker>
  > = this._keys$
    .pipe(
      filter(x => x && !!x.GoogleAnalytics && !!x.GoogleAnalytics.googleAnalyticsKey),
      map(x => x.GoogleAnalytics.googleAnalyticsKey),
      filterNil,
      tap(key => {
        window.dataLayer = window.dataLayer || [];
        const gtag = function(...args) {
          window.dataLayer.push(arguments);
        };

        gtag('js', new Date());
        gtag('config', key);
      }),
      switchMap(key =>
        from(
          AssetsLoader.loadScript(
            this._head,
            `https://www.googletagmanager.com/gtag/js?id=${key}`,
            true,
            true
          )
        )
      )
    )
    .pipe(
      // switchMap(() => {
      //   return of(false).pipe(
      //     delay(250),
      //     switchMap(_ =>
      //       !(window.ga && window.ga.getAll)
      //         ? throwError('GA is not initialized')
      //         : of(true)
      //     ),
      //     retry(10)
      //   );
      // }),
      // map(_ => ga.getAll()[0]),
      // tap(_ => console.log('GA is initialized!')),
      take(1),
      shareReplay(1)
    );

  constructor(
    private router: Router,
    private authService: AuthService,
    private metadata: MetaDataService
  ) {
    this._gaTracker$.subscribe(/*{
      complete: () => this._initEvents(),
      error: err => console.warn(err)
    }*/);

    this._tagManager$.subscribe();

    //this.eventTracker$.subscribe();
  }

  // private _initEvents() {
  //   combineLatest([this.router.events, this._gaTracker$]).subscribe(
  //     ([event, tracker]) => {
  //       if (event instanceof NavigationEnd) {
  //         tracker.set('page', event.urlAfterRedirects);
  //         tracker.send('pageview');
  //       }
  //     }
  //   );
  // }

  // private _eventTracker$ = new Subject<{}>();
  // eventTracker$ = this._eventTracker$.pipe(
  //   withLatestFrom(this._gaTracker$),
  //   debounceTime(400),
  //   tap(([event, tracker]) => tracker.send('event', event)),
  //   share()
  // );

  // sendEvent(category: string, action: string, label: string) {
  //   this._eventTracker$.next({
  //     eventCategory: category,
  //     eventAction: action,
  //     eventLabel: label
  //   });
  // }
}
