<ng-container *ngIf="enabled">
  <div *ngIf="type === 'bar'; else circleBlock">
    <div
      *ngIf="indeterminate; else determineBarBlock"
      class="progress"
      [class.progress-small]="!thick"
    >
      <svg
        [ngStyle]="{ height: barHeight + 'px' }"
        version="1.1"
        id="pagesloading"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 15 1"
        enable-background="new 0 0 15 1"
        preserveAspectRatio="none"
      >
        <circle
          r="1px"
          cx="0px"
          cy="0.5px"
          [ngStyle]="{ fill: 'var(--color-' + color + ')' }"
        >
          <animate
            attributeName="r"
            begin="0.2s"
            from="11"
            to="0"
            dur="1.2s"
            repeatCount="indefinite"
          />
          <animateTransform
            attributeName="transform"
            type="translate"
            values="-35 0;2 0;17 0;20 0"
            dur="1.2s"
            begin="0"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </div>
    <ng-template #determineBarBlock>
      <div class="progress" [class.progress-small]="!thick">
        <div
          class="progress-bar progress-bar-{{ color }}"
          [ngStyle]="{
            width: (value * 100) / max + '%',
            height: barHeight + 'px'
          }"
        ></div>
      </div>
    </ng-template>
  </div>
  <ng-template #circleBlock>
    <div
      *ngIf="type === 'circleready'; else circleBlockReady"
      [ngStyle]="{ width: size + 'px', height: size + 'px', margin: '0 auto' }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        [ngStyle]="{
          width: size + 'px',
          height: size + 'px',
          margin: '0 auto'
        }"
      >
        <circle
          fill="none"
          opacity="1"
          [ngStyle]="{
            stroke: 'var(--color-' + color + ')',
            r: radius,
            'stroke-width': lineWidth,
            cx: cx,
            cy: cx
          }"
        />
      </svg>
    </div>
  </ng-template>
  <ng-template #circleBlockReady>
    <div
      class="progress-circle- progress-circle-indeterminate"
      [ngStyle]="{ width: size + 'px', height: size + 'px', margin: '0 auto' }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        [ngStyle]="{
          width: size + 'px',
          height: size + 'px',
          margin: '0 auto'
        }"
      >
        <g [attr.transform]="'translate(' + cx + ',' + cx + ') rotate(-90)'">
          <circle
            fill="none"
            stroke-dashoffset="0"
            [ngStyle]="{
              stroke: 'var(--color-' + color + ')',
              r: radius,
              'stroke-width': lineWidth,
              'stroke-dasharray': cicle,
              'stroke-linecap': 'round'
            }"
          >
            <animate
              attributeName="stroke-dashoffset"
              values="360;140"
              dur="2.2s"
              keyTimes="0;1"
              calcMode="spline"
              fill="freeze"
              keySplines="0.41,0.314,0.8,0.54"
              repeatCount="indefinite"
              begin="0"
            />
            <animateTransform
              attributeName="transform"
              type="rotate"
              values="0;274;360"
              keyTimes="0;0.74;1"
              calcMode="linear"
              dur="2.2s"
              repeatCount="indefinite"
              begin="0"
            />
          </circle>
        </g>
      </svg>
      <div *ngIf="indeterminate; else determineBlock"></div>
      <ng-template #determineBlock>
        <div
          class="pie"
          [ngStyle]="value2 && { clip: 'rect(auto, auto, auto, auto)' }"
        >
          <div
            class="left-side half-circle"
            [ngStyle]="value2 && { transform: 'rotate(' + value + 'deg)' }"
          ></div>
          <div
            class="right-side half-circle"
            [ngStyle]="{
              transform:
                value2 === true ? 'rotate(180deg)' : 'rotate(' + value + 'deg)'
            }"
          ></div>
        </div>
        <div class="shadow"></div>
      </ng-template>
    </div>
  </ng-template>
</ng-container>
