import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Portal } from '@angular/cdk/portal';


@Injectable({providedIn: 'root'})
export class PortalOverlayService {

  private _overlayTemplate$ = new BehaviorSubject<Portal<any>>(null);
  public overlayTemplate$ = this._overlayTemplate$.asObservable();

  constructor( ) { }

  setPortal(portal: Portal<any>) {
    this._overlayTemplate$.next(portal);
  }

  clearPortal() {
    this._overlayTemplate$.next(null);
  }

}
