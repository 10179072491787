<div
  [@enterLeave]="Message.state"
  class="custom-message pgn push-on-sidebar-open pgn-{{ _options.Style }}"
>
  <div class="alert alert-{{ Message.type }}">
    <div
      *ngIf="_options.Style === 'bar'; else determineBlock"
      [class.container]="_enableHorizontalContainer"
    >
      <div>
        <span>{{ Message.content }}</span>
        <button class="close" (click)="onClickClose()" type="button">
          <!-- <span aria-hidden="true">×</span><span class="sr-only">Close</span> -->
          <pg-menu-icon IconName="times" [Size]="22"></pg-menu-icon>
        </button>
      </div>
    </div>
    <ng-template #determineBlock>
      <div *ngIf="_options.Style === 'circle'; else standardBlock">
        <div class="pgn-message">
          <div>
            <p class="bold" *ngIf="_options.Title">{{ _options.Title }}</p>
            <p>{{ Message.content }}</p>
          </div>
        </div>
        <button type="button" class="close" (click)="onClickClose()">
          <!-- <span aria-hidden="true">×</span><span class="sr-only">Close</span> -->
          <pg-menu-icon IconName="times" [Size]="22"></pg-menu-icon>
        </button>
      </div>
    </ng-template>
    <ng-template #standardBlock>
      <div>
        <span>{{ Message.content }}</span>
        <button class="close" (click)="onClickClose()" type="button">
          <!-- <span aria-hidden="true">×</span><span class="sr-only">Close</span> -->
          <pg-menu-icon IconName="times" [Size]="22"></pg-menu-icon>
        </button>
      </div>
    </ng-template>
  </div>
</div>
