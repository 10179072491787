import { HttpClient } from "@angular/common/http";
import {
  Translation,
  TRANSLOCO_LOADER,
  TranslocoLoader
} from "@ngneat/transloco";
import { Injectable, InjectionToken, Inject, Optional } from "@angular/core";

export const LOCALES_PATH = new InjectionToken<string>('locales');

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient, @Inject(LOCALES_PATH) @Optional() private path?: string) {}

  getTranslation(langPath: string) {
    return this.http.get<Translation>(`/${ this.path || 'assets/l10n' }/${langPath}.json`);
  }
}

export const translocoLoader = {
  provide: TRANSLOCO_LOADER,
  useClass: TranslocoHttpLoader
};
