import { LoginOpt } from "@core/social-login.module/auth.service";

export enum SocialLoginAction {
  LINK = "Continue",
  LOGIN = "Login",
  SIGNUP = "Sign Up"
}
export enum SocialNetwork {
  FACEBOOK = "Facebook",
  GOOGLE = "Google"
}

export const googleLoginOptions: LoginOpt = {
  scope: "openid email profile"
};
