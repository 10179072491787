import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { LanguageSelectModule } from "@core/core.module/components/language-select/language-select.module";
import { ContainerModule } from "@core/pages-ui.module/components/container/container.module";
import { pgHeaderModule } from "@core/pages-ui.module/components/header/pg-header.module";
import { pgIconModule } from "@core/pages-ui.module/components/icon/menu-icon.module";
import { RootLayout } from "@core/pages-ui.module/layouts/root/root.component";
import { HeadroomModule } from "@ctrl/ngx-headroom";
import { TranslocoModule } from "@ngneat/transloco";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { ModalModule } from "ngx-bootstrap/modal";
import { LogoutAlertModalModule } from "../components/logout-alert-modal/logout-alert-modal.module";
import { PageLoaderModule } from "../components/page-loader/page-loader.module";
import { PortalBlankComponent } from "./blank/blank.component";
import { CondensedLayoutComponent } from "./condensed/condensed-layout.component";
import { MobileLayoutComponent } from "./mobile/mobile-layout.component";
import { RippleModule } from "@core/core.module/directives/ripple/ripple.module";
import { pgSelectModule } from '@core/pages-ui.module/components/select/select.module';

@NgModule({
  imports: [
    RouterModule,
    pgIconModule,
    //LayoutsModule,
    BrowserAnimationsModule,
    HeadroomModule,
    CommonModule,
    ContainerModule,
    FormsModule,
    ButtonsModule.forRoot(),
    //BsDropdownModule.forRoot(),
    //PerfectScrollbarModule,
    //pgListViewModule,
    TranslocoModule,
    LanguageSelectModule,
    ModalModule.forRoot(),
    pgHeaderModule,
    PageLoaderModule,
    LogoutAlertModalModule,
    PortalModule,
    RippleModule,
    pgSelectModule,
    //TabsModule.forRoot(),
    //pgTabsModule
  ],
  declarations: [
    PortalBlankComponent,
    CondensedLayoutComponent,
    MobileLayoutComponent,
    RootLayout
  ],
  entryComponents: [
    PortalBlankComponent,
    CondensedLayoutComponent,
    MobileLayoutComponent
  ],
  exports: [
    PortalBlankComponent,
    CondensedLayoutComponent,
    MobileLayoutComponent
  ]
})
export class ClientLayoutsModule {}
