import { Input, OnDestroy } from "@angular/core";
import {
  Optional,
  ViewContainerRef,
  Component,
  Injector,
  ChangeDetectorRef
} from "@angular/core";
import { rootInjector } from "../utils/root-injector";
import { Subject } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { NrtFormGroup } from "./NrtFormGroup";
import { NrtFormControl } from "./NrtFormControl";
import { guid } from "../utils/utils";
import { Sizing } from "../utils/Sizing";

export class OCBaseComponent implements OnDestroy {
  private _guid;
  public get guid(): string {
    this._guid = this._guid || guid();
    return this._guid;
  }
  public set guid(guid: string) {
    this._guid = guid;
  }

  @Input() set isLoading(val: boolean) {
    this.loader$.next(val);
  }

  // private _id;
  // public get id(): string {
  //   this._id = this._id || getRandomString();
  //   return this._id;
  // }
  // public set id(id : string) {
  //   this._id = id;
  // }

  public __takeUntilDestroy$: Subject<boolean> = new Subject();
  public destroy$ = this.__takeUntilDestroy$.asObservable();

  public get parentComponent(): Component {
    if (this.viewContainer) {
      return <Component>(<any>this.viewContainer.injector).view.component;
    }

    return null;
  }

  public loader$ = new BehaviorSubject<boolean>(false);

  error$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  titleKey$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  subtitleKey$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  protected cd?: ChangeDetectorRef;
  protected viewContainer: ViewContainerRef;
  public screen = Sizing.instance;

  injectorInstance?: Injector;

  public get changeDetector() {
    return this.cd;
  }

  constructor(@Optional() protected injector?: Injector) {
    if (injector) {
      this.cd = injector.get(ChangeDetectorRef);
      this.viewContainer = injector.get(ViewContainerRef);
    } else {
      this.injectorInstance = rootInjector;
    }
  }

  getNrtFormControl(form: NrtFormGroup, key: string): NrtFormControl {
    return form.get(key) as NrtFormControl;
  }

  getNrtFormControlMetadata(form: NrtFormGroup, key: string) {
    return this.getNrtFormControl(form, key).metadata;
  }

  ngOnDestroy() {
    this.__takeUntilDestroy$.next(true);
    this.__takeUntilDestroy$.complete();
    this.loader$.unsubscribe();
  }
}
