import { Injectable } from "@angular/core";
import { asyncScheduler, fromEvent } from "rxjs";
import {
  startWith,
  throttleTime,
  shareReplay,
  distinctUntilChanged,
  map
} from "rxjs/operators";

import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";

export type ScreenSize = "xs" | "sm" | "md" | "lg" | "xl";

export class Sizing {
  readonly breakpoints = [0, 576, 768, 992, 1200];
  readonly sizes = ["xs", "sm", "md", "lg", "xl"];

  resize$ = fromEvent(window, "resize").pipe(
    startWith({
      width: window.innerWidth,
      height: window.innerHeight
    }),
    map(() => ({
      width: window.innerWidth,
      height: window.innerHeight
    })),
    throttleTime(100, asyncScheduler, {
      leading: false,
      trailing: true
    }),
    shareReplay(1)
  );

  private breakpoints$ = this.resize$.pipe(
    map(size => {
      for (let i = 1; i < this.breakpoints.length; i++) {
        const br = this.breakpoints[i];
        if (size.width < br) {
          return { size: this.sizes[i - 1], idx: i - 1 };
        }
      }

      return { size: "xl", idx: 4 };
    }),
    distinctUntilChanged()
  );

  is$ = (breakpoint: ScreenSize) =>
    this.breakpoints$.pipe(
      map(br => this.sizes.indexOf(breakpoint) <= br.idx),
      distinctUntilChanged(),
      shareReplay(1)
    );

  exact$ = (breakpoint: ScreenSize) =>
    this.breakpoints$.pipe(
      map(br => this.sizes.indexOf(breakpoint) === br.idx),
      distinctUntilChanged(),
      shareReplay(1)
    );

  isXs$ = this.is$("xs");
  isSm$ = this.is$("sm");
  isMd$ = this.is$("md");
  isLg$ = this.is$("lg");
  isXl$ = this.is$("xl");

  private static _instance: Sizing;

  static get instance(): Sizing {
    return Sizing._instance || new Sizing();
  }
}
