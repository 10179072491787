import { WatermarkModule } from "./directives/watermark/watermark.module";
import { AnimationsService } from "./animations/animations.service";
/*
    Angular
*/
import { NgModule, Injector } from "@angular/core";
//import { Http, HttpModule, XHRBackend, RequestOptions } from "@angular/http";

/*
    App
*/
import { Reflection } from "./utils/Reflection";

import { OCHttp } from "./services/http.service";
import { OCErrorHandler } from "./services/error-handler";

import { LoaderDirective } from "./directives/loader/loader.directive";

import { PipesModule } from "./pipes/pipes.module";
import { HttpHandler, HttpClientModule, HttpClient } from "@angular/common/http";

export function factory(backend: HttpHandler/* , defaultOptions: RequestOptions */) {
  const res = new OCHttp(backend);
  return res;
}

@NgModule({
  declarations: [LoaderDirective],
  exports: [PipesModule, LoaderDirective, WatermarkModule],
  imports: [PipesModule, HttpClientModule],
  providers: [
    // { provide: ErrorHandler, useClass: OCErrorHandler },
    {
      provide: HttpClient,
      useFactory: factory,
      deps: [HttpHandler]
    },
    AnimationsService,
    OCErrorHandler
  ]
})
export class OCCoreModule {
  constructor(public injector: Injector) {
    Reflection.Injector = injector;
  }
}
