import { Injectable } from '@angular/core';
import { SettingsStore, ISettings, defaultSettings } from './settings.store';
import { NrtQuery } from '@core/core.module/services/storage/NrtQuery';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SettingsQuery extends NrtQuery<ISettings> {
  //override
  get current(): ISettings {
    let x = this.getValue();
    return {
      ...defaultSettings,
      ...x,

    };
  }

  //override
  get current$(): Observable<ISettings> {
    return this.select(x => x).pipe(
      map(x => ({
        ...defaultSettings,
        ...x,
      }))
    );
  }

  constructor(protected store: SettingsStore) {
    super(store);
  }
}
