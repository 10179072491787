import { Type } from "@angular/core";
import { IFormControlsFactory } from "@core/core.module/base/IFormControlsFactory";
import { FieldType } from "@core/core.module/base/IFormFieldMetadata";

export declare type SelfObjectType = "array" | "dictionary";
export declare type AppFormConfig = { title?: string, type?: SelfObjectType; refType?: Type<any> | FieldType };

/**
 * Adds a service to `appForms` array.
 */
export function AppForm(name: string, config?: AppFormConfig) {
  return (target: Type<any>) => {
    let form = appForms.get(target);
    if (!form) {
      form = {
        entityName: name,
        entity: target,
        controls: {},
        config: config
      };
      appForms.set(target, form);
    }

    form.entityName = name;
    form.config = config;
  };
}

export declare type AppFormDeclaration = {
  entityName: string;
  entity: any;
  controls: { [controlName: string]: IFormControlsFactory };
  config?: AppFormConfig;
};
/**
 * List of all components marked by @AppService decorator.
 */
export const appForms: Map<any, AppFormDeclaration> = new Map();
