import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HomeContainerComponent } from "./home-container";
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from "@angular/router";
import { AlertModule } from "ngx-bootstrap/alert";

@NgModule({
  imports: [CommonModule, RouterModule, TranslocoModule, AlertModule.forRoot()],
  declarations: [HomeContainerComponent]
})
export class HomeModule {}
