  <div
    class="justify-content-center p-t-50 m-t-20"
    *ngIf="(this.auth.currentProfile$ | async)?.isVerified === false"
  >
    <alert type="warning">
      <strong>{{ 'MESSAGES.PENDING.MESSAGE' | transloco }} </strong>{{
      'MESSAGES.PENDING.TITLE' | transloco }}
    </alert>
  </div>

  <router-outlet></router-outlet>
