import { NgModule } from "@angular/core";
import { OCConsolePipe } from "./console.pipe";
import { OCFormatPipe } from "./format.pipe";
import { FilterPipe } from "./filter.pipe";
import { SafeHtml } from "./safe-html.pipe";
import { SafeStyle } from "./safe-style.pipe";
import { SafeScript } from "./safe-script.pipe";
import { SafeUrl } from "./safe-url.pipe";
import { MapToArrayPipe } from "./mapToArray.pipe";
import { RxMerge } from "./rx-merge.pipe";
import { RxThrottle } from "./rx-throttle.pipe";
import { RxRandom } from "./rx-random.pipe";

@NgModule({
  declarations: [
    OCConsolePipe,
    OCFormatPipe,
    FilterPipe,
    SafeHtml,
    SafeStyle,
    SafeScript,
    SafeUrl,
    MapToArrayPipe,
    RxMerge,
    RxThrottle,
    RxRandom
  ],
  exports: [
    OCConsolePipe,
    OCFormatPipe,
    FilterPipe,
    SafeHtml,
    SafeStyle,
    SafeScript,
    SafeUrl,
    MapToArrayPipe,
    RxMerge,
    RxThrottle,
    RxRandom
  ]
})
export class PipesModule {}
