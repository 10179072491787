import { IBrandingAction } from "./IBrandingAction";
import {
  IBrandingAddSettings,
  IBrandingEditSettings,
  IBrandingExternalSettings,
  IBrandingManagementSettings,
  IBrandingBehaviorSettings
} from "./IBrandingSettings";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MetaDataService } from "@client-services/api/clientMetaData/metaData.service";
import { LOGIN_PATH } from "@core/core.module/jwt/injection-tokens";
import { rootInjector } from "@core/core.module/utils/root-injector";
import { AuthService } from "@core/core.module/jwt/auth.service";

@Injectable()
export class BrandingSettings implements IBrandingAction {
  constructor() {
  }

  add?: IBrandingAddSettings;
  edit?: IBrandingEditSettings;
  external?: IBrandingExternalSettings;
  management?: IBrandingManagementSettings;

  behavior?: IBrandingBehaviorSettings = {
  };
}
