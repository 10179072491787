import { FormControl, ValidationErrors } from "@angular/forms";
import { TranslocoService } from "@ngneat/transloco";
import { Subject, Subscription } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { IFormFieldMetadata } from "./IFormFieldMetadata";
import { INrtAbstractControl } from "./INrtAbstractControl";
import { INrtValidator } from "./INrtValidator";
import { NrtMissingTranslationHandler } from "./NrtMissingTranslationHandler";

export class NrtFormControl extends FormControl implements INrtAbstractControl {
  private _metadata: IFormFieldMetadata;
  get metadata(): Readonly<IFormFieldMetadata> {
    return this._metadata;
  }
  private _metadataInitial: Readonly<IFormFieldMetadata>;

  name: string;

  private _validators: INrtValidator[] = [];

  get validators(): Readonly<INrtValidator[]> {
    return this._validators;
  }

  prevValue: Readonly<any>;
  private _translateSub: Subscription;

  private _destroy$ = new Subject<string>();
  destroy$ = this._destroy$.pipe(
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  constructor(name: string, metadata?: IFormFieldMetadata) {
    super();
    metadata && this.setValue(metadata.defaultValue);
    this._metadataInitial = { ...metadata };

    this.name = name;
    this._metadata = metadata || {};
    this.prevValue = this.value;
  }

  get errorMessages(): string[] {
    const messages = [];
    if (!this.valid) {
      for (const error in this.errors) {
        const validator = this._validators.find(x => x.name === error);

        if (validator) {
          validator.message &&
            messages.push(validator.translation || validator.message);
        } else {
          const err = this.errors[error];
          err && err.message && messages.push(err.translation || err.message);
        }
      }

      for (const error in this._controlErrors) {
        const err = this._controlErrors[error];
        err && err.message && messages.push(err.translation || err.message);
      }
    }

    return messages;
  }

  private _controlErrors = {};

  setCustomErrors(errors?: ValidationErrors): void {
    this._controlErrors = errors ? { ...this._controlErrors, ...errors } : {};
  }

  get required(): boolean {
    return !!this._validators.find(x => x.name === "required");
  }

  patchValue(
    value: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ): void {
    super.patchValue(value, options);
    this.prevValue = value;
  }

  updateMetadata(metadata: Partial<IFormFieldMetadata>): this {
    this._metadata = { ...this._metadata, ...metadata };
    this.updateValueAndValidity();
    return this;
  }

  setValue(
    value: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ): void {
    super.setValue(value, options);
    this.prevValue = value;
  }

  public getRawValue() {
    return this.getRawValue();
  }

  clone(name?: string): NrtFormControl {
    var ctrl = new NrtFormControl(name || this.name, this.metadata);
    ctrl.assignValidators(this._validators);
    return ctrl;
  }

  assignValidators(validators: INrtValidator[]) {
    this._validators.push(...validators);

    this.setValidators(this._validators.map(x => x.validator));
  }

  toggleValidator(
    isDisabled: boolean,
    opts?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ) {
    this.setValidators(null);
    if (!isDisabled) {
      this.setValidators(this._validators.map(x => x.validator));
    }

    this.updateValueAndValidity(opts);
  }

  // filterValidators(validators: Partial<INrtValidator>[]) {
  //   this._validators = this._validators.filter(val => {
  //     const validator = validators.find(x => x.name === val.name);
  //     return !!validator;
  //   });

  //   this.setValidators(this._validators.map(x => x.validator));
  // }

  private translateInternal(
    translateService?: TranslocoService,
    props: string[] = ["title", "placeholder"]
  ) {
    props.forEach(item => {
      const meta = this._metadataInitial[item];

      meta &&
        (this._metadata[item] = translateService
          ? translateService.translate(meta)
          : NrtMissingTranslationHandler.extractFallback(meta));
    });

    this._validators.forEach(
      (v, i) =>
        (v.translation = translateService
          ? translateService.translate(v.message)
          : NrtMissingTranslationHandler.extractFallback(v.message))
    );
  }

  translate(translateService?: TranslocoService) {
    this._translateSub && this._translateSub.unsubscribe();

    if (translateService && !this._translateSub) {
      this._translateSub = translateService.langChanges$.subscribe(x => {
        this.translateInternal(translateService);
      });
    }

    this.translateInternal(translateService);
  }

  clearValidators() {
    this._validators = [];
    super.clearValidators();
  }

  clearAsyncValidators() {
    this._validators = [];
    super.clearAsyncValidators();
  }

  disableControl() {
    this.updateMetadata({ disabled: true });
    this.disable();
    this.clearValidators();
  }

  destroy() {
    this._translateSub && this._translateSub.unsubscribe();
    this._destroy$.next(this.name);
    this._destroy$.complete();
    this._destroy$.unsubscribe();
  }
}
