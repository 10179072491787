import { Injectable } from "@angular/core";

enum APP_MODE {
  KIOSK = "KIOSK_MODE",
  TABLET = "TABLET_MODE",
  EXTERNAL_VIEW = "EXTERNAL_VIEW_MODE"
}

@Injectable({
  providedIn: "root"
})
export class AppModeService {
  constructor() {}

  isTabletMode(): boolean {
    return !!localStorage.getItem(APP_MODE.TABLET);
  }

  isKioskMode(): boolean {
    return !!localStorage.getItem(APP_MODE.KIOSK);
  }

  isExternalViewMode(): boolean {
    return !!localStorage.getItem(APP_MODE.EXTERNAL_VIEW);
  }

  setTabletMode() {
    localStorage.setItem(APP_MODE.TABLET, "1");
  }

  setKioskMode() {
    localStorage.setItem(APP_MODE.KIOSK, "1");
  }

  setExternalViewMode() {
    localStorage.setItem(APP_MODE.EXTERNAL_VIEW, "1");
  }
}
