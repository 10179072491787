import { Injectable } from "@angular/core";
import { NrtQuery } from "@core/core.module/services/storage/NrtQuery";
import { ProfileStore } from "./profile.store";
import { IProfileState } from "./profile.state";

@Injectable({providedIn: 'root'})
export class ProfileQuery extends NrtQuery<IProfileState>{
  constructor(protected store: ProfileStore) {
    super(store);
  }
}
