import { KeyboardModule } from '@core/core.module/components/keyboard/keyboard.module';
import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { pgOptionComponent } from "./option.component";
import { OptionPipe } from "./option.pipe";
import { pgSelectComponent } from "./select.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { pgIconModule } from "../icon/menu-icon.module";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,
    DragDropModule,
    pgIconModule,
    PerfectScrollbarModule,
    KeyboardModule
  ],
  declarations: [OptionPipe, pgOptionComponent, pgSelectComponent],
  exports: [OptionPipe, pgOptionComponent, pgSelectComponent]
})
export class pgSelectModule {}
