import { StoreConfig } from "@datorama/akita";
import { Injectable } from "@angular/core";
import { NrtStore } from "@core/core.module/services/storage/NrtStore";
import { BootstrapMetaData } from "../MetaData";

export const defaultSettings: Partial<BootstrapMetaData> = {};

@StoreConfig({ name: "meta-data" })
@Injectable({ providedIn: "root" })
export class MetaDataStore extends NrtStore<BootstrapMetaData> {
  constructor() {
    super(defaultSettings);
  }
}
