import { HttpClient } from "@angular/common/http";
import { Optional } from "@angular/core";
import { IApiMethodMetadata } from "../decorators/api-method.decorator";
import { OCHttp } from "../services/http.service";
import { ApiServiceMetadata } from "../decorators/api-service.decorator";
import { Reflection } from "../utils/Reflection";
import { isString, isNotEmpty } from "../utils/utils";
import { Json } from "../utils/Json";
import * as dayjs_ from "dayjs";
import { Observable } from "rxjs";
import { NrtHttpRequestOptions } from "./NrtHttpRequestOptions";

const dayjs = dayjs_;

export const defaultTTL = parseInt(localStorage.getItem("CACHE_TTL")) || 100000;
export const cachedUrls = new Map<string, number>();
//export const skipCacheUrls = new Set<string>();

/**
 * Basic API controller
 */
export class OCApiService {
  protected serviceDescription: ApiServiceMetadata;

  public url: string;
  constructor(@Optional() protected http?: HttpClient) {
    this.http = http || Reflection.get(HttpClient);
    this.serviceDescription = Reflection.classMetadata(
      this,
      ApiServiceMetadata
    );
    this.url = this.serviceDescription
      ? this.serviceDescription.Url
      : undefined;
  }

  private static updateMethod(method: string, fnName: string) {
    fnName = (fnName || "").toUpperCase();
    if (!method) {
      if (fnName.startsWith("GET") || fnName.startsWith("LOAD")) {
        method = "GET";
      } else if (fnName.startsWith("DELETE") || fnName.startsWith("REMOVE")) {
        method = "DELETE";
      } else {
        method = "POST";
      }
    }
    return method.toUpperCase();
  }

  private static objectToStringParams(
    params: any,
    url: string,
    methodDescriptor: IApiMethodMetadata
  ): string {
    let res = url || "";
    if (methodDescriptor.route && methodDescriptor.route.startsWith("http")) {
      res = methodDescriptor.route;
    } else if (
      methodDescriptor.route &&
      methodDescriptor.route.startsWith("/")
    ) {
      res = methodDescriptor.route.substr(1);
    } else if (methodDescriptor.route && methodDescriptor.route !== "/") {
      if (!methodDescriptor.route.startsWith("/") && !res.endsWith("/")) {
        res = res + "/";
      }
      res = res + methodDescriptor.route;
    }

    if (methodDescriptor.useBody === undefined) {
      methodDescriptor.useBody =
        methodDescriptor.method === "POST" ||
        methodDescriptor.method === "PUT" ||
        methodDescriptor.method === "PATCH";
    } else if (
      methodDescriptor.method === "GET" ||
      methodDescriptor.method === "DELETE" ||
      methodDescriptor.method === "HEAD" ||
      methodDescriptor.method === "OPTIONS"
    ) {
      methodDescriptor.useBody = false;
    }

    let prefix = res.indexOf("?") === -1 ? "?" : "&";
    if (!isString(params)) {
      for (const param in params) {
        if (params.hasOwnProperty(param)) {
          if (param === "$query") {
            res = `${res}${prefix}${param}`;
            prefix = "&";
            delete params[param];
          } else if (res.indexOf(`{${param}}`) !== -1) {
            res = res.replace(
              `{${param}}`,
              this.encodeUriComponent(params[param])
            );
            delete params[param];
          }
        }
      }
      if (!methodDescriptor.useBody) {
        for (const param in params) {
          if (params.hasOwnProperty(param)) {
            res = `${res}${prefix}${param}=${this.encodeUriComponent(
              params[param]
            )}`;
            prefix = "&";
            delete params[param];
          }
        }
      }
    } else if (!methodDescriptor.useBody) {
      res = res + prefix + params;
    }

    if (
      methodDescriptor.useBody === <any>"raw" &&
      Object.keys(params).length === 1
    ) {
      params.$raw = params[Object.keys(params)[0]];
    }
    return OCHttp.updateUrl(res);
  }

  private static encodeUriComponent(obj: any): string {
    let res = "";
    if (isNotEmpty(obj)) {
      if (obj instanceof Date) {
        const day = dayjs(obj);
        if (day.isValid()) {
          obj = day;
        }
        if (dayjs.isDayjs(obj)) {
          res = day.toISOString();
        }
      } else {
        res = res + encodeURIComponent(obj);
      }
    }
    return res;
  }

  public request<T>(
    params?: any,
    options?: NrtHttpRequestOptions,
    methodDescriptor?: IApiMethodMetadata | void
  ): Promise<T> {
    return this.request$<T>(params, options, methodDescriptor).toPromise();
  }

  public request$<T>(
    params?: any,
    options?: NrtHttpRequestOptions,
    methodDescriptor?: IApiMethodMetadata | void
  ): Observable<T> {
    params = params || {};
    const paramsObject =
      params instanceof FormData ? params : Json.clone(params);

    let caller = "";
    methodDescriptor = {
      ...this["__apiMethod"],
      ...methodDescriptor
    } as IApiMethodMetadata;
    // if (!methodDescriptor) {
    //   caller = Reflection.callerName(1);
    //   methodDescriptor = <IApiMethodMetadata>(
    //     (Reflection.memberMetadata(this, caller, ApiMethodMetadata) || {})
    //   );
    // }

    methodDescriptor.route =
      (options && options.route) || methodDescriptor.route;
    caller = methodDescriptor.caller || caller;

    const url = methodDescriptor.apiUrl || this.url;

    methodDescriptor.method = OCApiService.updateMethod(
      methodDescriptor.method,
      caller
    );
    const request = OCApiService.objectToStringParams(
      paramsObject,
      url,
      methodDescriptor
    );
    const body = paramsObject.$raw || paramsObject;

    // this.isCacheInterceptor && methodDescriptor.cache &&
    //   CacheInterceptor.addCachedUrl(request, methodDescriptor.cache);

    // if(options && options.skipCache){
    //   skipCacheUrls.add(request);
    // }

    //methodDescriptor.cache = options ? options.cache : methodDescriptor.cache;

    const cache = options
      ? options.cache !== undefined
        ? options.cache
        : methodDescriptor.cache
      : methodDescriptor.cache;

    if (cache) {
      cachedUrls.set(
        request,
        typeof cache === "boolean" ? defaultTTL : cache.ttl || defaultTTL
      );
    } else {
      cachedUrls.delete(request);
    }

    return this.query$(methodDescriptor.method, request, body, options);
  }

  // tslint:disable-next-line:max-line-length
  public query$<T>(
    method: string,
    request: string,
    body?: any,
    options?: any
  ): Observable<T> {
    let response: Observable<any>;
    switch (method) {
      case "GET":
        response = this.http.get(request, options);
        break;
      case "HEAD":
        response = this.http.head(request, options);
        break;
      case "OPTIONS":
        response = this.http.options(request, options);
        break;
      case "POST":
        response = this.http.post(request, body, options);
        break;
      case "PUT":
        response = this.http.put(request, body, options);
        break;
      case "PATCH":
        response = this.http.patch(request, body, options);
        break;
      case "DELETE":
        response = this.http.delete(request, options);
        break;
      default:
        options = options || {};
        options.method = options.method || method;
        options.body = options.body || body;
        response = this.http.request(request, options);
        break;
    }
    return OCHttp.HandleResponse(response);
  }

  // public get$<T>(id: string): Observable<T> {
  //     return this.request$<T>({ id: id }, { route: '{id}', method: 'GET' });
  // }

  // public getList$<T>(): Observable<T[]> {
  //     return this.request$<T[]>(null, { route: '', method: 'GET' });
  // }

  // public update$<T>(id: string, object: T): Observable<T> {
  //   return this.request$<T>(
  //     { id: id, $raw: object },
  //     { route: '{id}', method: 'PUT' }
  //   );
  // }

  // public create$<T>(object: T): Observable<T> {
  //   return this.request$<T>(object, { method: 'POST' });
  // }
}
