import { EntityState, QueryEntity } from "@datorama/akita";
import { IEntityId } from "../../base/IEntityId";
import { IEntityKey } from "../../base/IEntityKey";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

export class NrtQueryEntity<
  TState extends EntityState<TEntity>,
  TEntity extends IEntityId | IEntityKey
> extends QueryEntity<TState, TEntity> {

  selectActiveSingle(): Observable<TEntity> {
    return (this.selectActive() as Observable<TEntity[]>).pipe(
      map(x => {
        return Array.isArray(x) ? x[0] : x;
      })
    );
  }

  getActiveSingle(): TEntity {
    const active = this.getActive();
    return Array.isArray(active) ? active[0] : active;
  }
}
