import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { OCBaseComponent } from "@core/core.module/base/base.component";
import { NrtFormControl } from "@core/core.module/base/NrtFormControl";
import { UntilDestroy } from "@core/core.module/decorators/until-destroy.decorator";
import { METADATA } from "@core/core.module/services/IMetaData";
import { NrtQuery } from "@core/core.module/services/storage/NrtQuery";
import { untilDestroyed } from "@core/core.module/utils/take-until-destroy/take-until-destroy";
import { TranslocoService } from "@ngneat/transloco";
import { BootstrapMetaData } from "projects/player-portal/player-portal-client/src/app/services/api/clientMetaData/MetaData";
import { filter, mapTo, switchMap } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "nrt-language-select",
  templateUrl: "./language-select.component.html",
  styleUrls: ["./language-select.component.scss"],
  encapsulation: ViewEncapsulation.ShadowDom,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectComponent extends OCBaseComponent
  implements OnInit, AfterViewInit {
  @Input() invert: boolean = false;

  _lang: string = this.translate.getActiveLang();
  // (~["en-US", "es-MX", "en-ZW"].indexOf(this.translate.getActiveLang()) &&
  //   this.translate.getActiveLang()) ||
  // this.translate.getDefaultLang();

  control = new NrtFormControl("lang");

  constructor(
    private translate: TranslocoService,
    protected cd: ChangeDetectorRef,
    @Inject(METADATA) public metaDataQuery: NrtQuery<BootstrapMetaData>
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.translate.langChanges$.pipe(untilDestroyed(this)).subscribe(x => {
      this._lang = x;
      this.control.patchValue(this._lang, { emitEvent: false });
      this.cd.markForCheck();
    });

    this.control.valueChanges
      .pipe(
        filter(lang => this.translate.getActiveLang() !== lang),
        switchMap(lang => this.translate.load(lang).pipe(mapTo(lang))),
        untilDestroyed(this)
      )
      .subscribe(lang => {
        this._lang = lang;
        this.translate.setActiveLang(this._lang);
      });
  }

  ngOnInit() {}
}
