import {
  Component,
  Input,
  ChangeDetectionStrategy,
  SimpleChanges
} from '@angular/core';
@Component({
  selector: 'pg-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressComponent {
  private _cx: number;
  private _cicle: number;
  private _radius: number;
  private _lineWidth: number;
  private _value: number;
  private _value2: any;
  private _color = 'primary';
  private _size = 24;

  @Input() type: 'bar' | 'circleready' | 'circle' = 'circle';
  @Input() barHeight: number = 3;
  @Input() indeterminate: boolean = true;
  @Input() thick: boolean = false;

  @Input() enabled = true;

  @Input() max: number = 100;

  @Input() set value(value: number) {
    if (this.type == 'circle') {
      this._value = (value / 100) * 360;
      if (this.value >= 50) {
        this._value2 = true;
      }
    } else this._value = value;
  }

  get value() {
    return this._value;
  }

  get value2() {
    return this._value2;
  }

  @Input() set color(val: string) {
    val && (this._color = val);
  }

  get color(): string {
    return this._color;
  }

  @Input() set size(val: number) {
    val && (this._size = val);
  }

  get size(): number {
    return this._size;
  }

  @Input() set lineWidth(val: number) {
    this._lineWidth = val;
  }

  get lineWidth(): number {
    return this._lineWidth;
  }

  get radius(): number {
    return this._radius;
  }

  get cicle(): number {
    return this._cicle;
  }

  get cx(): number {
    return this._cx;
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this._lineWidth) {
      if (this.thick == false) {
        this._lineWidth = 2;
      } else this._lineWidth = 6;
    }

    this._radius = this.size / 2 - this._lineWidth;
    this._cx = this.size / 2 - this._lineWidth / 2;
    this._cicle = this.size * 3;
  }

  constructor() {}
}
