<oc-page-loader></oc-page-loader>
<router-outlet></router-outlet>
<app-logout-confirmation style="position: inherit;"></app-logout-confirmation>
<div
  bsModal
  #updateModal="bs-modal"
  [config]="{ ignoreBackdropClick: true }"
  class="update-modal modal fade fill-in"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <!-- <button
  *ngIf="!isMobile"
  class="btn btn-primary btn-rounded btn-print"
  [tooltip]="'COMMON.PRINT' | transloco"
  placement="left"
  (click)="print()"
>
  <pg-menu-icon IconType="clr" IconName="printer" [Size]="24"></pg-menu-icon>
</button> -->

  <!-- <pg-menu-icon
    Class="close-menu-modal"
    IconType="clr"
    IconName="times"
    (click)="updateModal.hide()"
    [Size]="50"
  ></pg-menu-icon> -->

  <div class="modal-dialog modal-lg">
    <div class="modal-content-wrapper">
      <div class="modal-content p-t-10 p-b-10 text-center">
        <h2>{{ "@MESSAGES.UPDATE" | transloco }}</h2>
        <button
          class="btn btn-rounded btn-primary reload-btn m-t-15"
          (click)="reload(true)"
        >
          <ng-container *ngIf="{ sec: remainingSeconds$ | async } as q">
            <span class="update-reload-text" [class.m-r-15]="q.sec === 0"
              >{{
                q.sec > 0
                  ? ("@RELOAD" | transloco) + " " + q.sec
                  : ("@MESSAGES.RELOADING|Reloading..." | transloco)
              }}
            </span>
            <div class="update-loader" *ngIf="q.sec === 0">
              <pg-progress
                #minimalCircleLoading
                [type]="'circle'"
                [color]="'info-lightest'"
                [thick]="false"
                [size]="18"
              >
              </pg-progress>
            </div>
          </ng-container>
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
