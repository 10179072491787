import * as dayjs from 'dayjs';
import { EventEmitter, InjectionToken } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { SetupSignleton } from '../decorators';
import { Observable } from 'rxjs';


export const AUTH_SERVICE_TOKEN = new InjectionToken('IOCAuthService');

export interface IUser {
  userName: string;
}

export enum OCAuthAction {
  LoggedIn = 'loggedIn',
  LoggedOut = 'loggedOut'
}

export interface OCAuthServiceEvent {
  action: OCAuthAction;
  user: IUser;
}

export interface IOCAuthService extends CanActivate {
  /**
   * Признак, что пользователь залогинен
   */
  isLoggedIn: boolean;
  /**
   * Информация о текущем залогиненом пользователе
   */
  loggedUser: IUser;

  /**
   * Признак, что пользователь админ
   */
  isAdmin: boolean;

  /**
   * Признак, что пользователь супер-пользователь
   */
  isSuperUser: boolean;

  /**
   * Информация о логине текущего пользователя
   */
  loggedUserName: string;

  /**
   * Информация о текущем токене залогиненого пользователя
   */
  accessToken: string;

  /**
   * Время до которого текущий токен валиден
   */
  accessTokenExpires: dayjs.Dayjs;

  onAuthEvent: EventEmitter<OCAuthServiceEvent>;

  /**
   * Обновляет информацию о текущем пользователе.
   */
  updateUserInfo(): Promise<IUser>;

  login(userName: string, password: string): Promise<IUser>;
  logout(noReload?: boolean): Promise<void>;
  register(login: string, password: string): Promise<IUser>;
  /*
    restorePassword(email: string): Promise<void>;
    resetPassword(email: string, password: string, code: string): Promise<IUser>;
*/
}

export class DefaultAuthService implements IOCAuthService {


  readonly isLoggedIn: boolean = false;
  readonly loggedUser: IUser = <any>{};
  readonly isAdmin: boolean = false;
  readonly isSuperUser: boolean = false;


  readonly loggedUserName: string = '';
  readonly accessToken: string = '';
  readonly accessTokenExpires: dayjs.Dayjs = undefined;
  readonly onAuthEvent: EventEmitter<OCAuthServiceEvent> = new EventEmitter<
    OCAuthServiceEvent
  >();



  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  updateUserInfo(): Promise<IUser> {
    return Promise.resolve(<any>{});
  }

  login(userName: string, password: string): Promise<IUser> {
    return Promise.resolve(<any>{});
  }

  logout(noReload?: boolean): Promise<void> {
    return Promise.resolve();
  }

  register(login: string, password: string): Promise<IUser> {
    return Promise.resolve(<any>{});
  }
}

export class CoreAuthService implements IOCAuthService {
  @SetupSignleton(AUTH_SERVICE_TOKEN, DefaultAuthService)
  static instance: IOCAuthService = null;

  isLoggedIn: boolean;
  loggedUser: IUser;
  isAdmin: boolean;
  isSuperUser: boolean;
  loggedUserName: string;


  accessToken: string;
  accessTokenExpires: dayjs.Dayjs;
  onAuthEvent: EventEmitter<OCAuthServiceEvent>;

  updateUserInfo(): Promise<IUser> {
    throw new Error('Not implemented');
  }

  login(userName: string, password: string): Promise<IUser> {
    throw new Error('Not implemented');
  }
  logout(noReload?: boolean): Promise<void> {
    throw new Error('Not implemented');
  }
  register(login: string, password: string): Promise<IUser> {
    throw new Error('Not implemented');
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    throw new Error('Not implemented');
  }
}

export const auth: IOCAuthService = new CoreAuthService();

