import { Component, OnInit,Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'pg-menu-icon',
  templateUrl: './menu-icon.component.html',
  styleUrls: ['./menu-icon.component.scss'],
  host:{
    '[class]' : '_classMap'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuIconComponent implements OnInit {
  _classMap: string = '';
  @Input() IconType: string = "clr";
  @Input() IconName: string;
  @Input() Size: number = 16;
  @Input() Direction: 'up' | 'down' | 'left' | 'right';
  @Input() Class: string;

  @Input()
  set ExtraClass(value: string[]){
    if(value !== undefined && value.length)
    this._classMap = this._classMap + value.join(' ');
  }

  constructor() {
    //this._classMap = "icon-thumbnail"
  }

  ngOnInit() {
  }

}
