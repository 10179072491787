import { Injector, Type } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export let rootInjector: Injector;
export let childInjectors: Injector[] = [];

const _rootInjector$: ReplaySubject<Injector> = new ReplaySubject(1);
export const rootInjector$: Observable<Injector> = _rootInjector$.asObservable();

export function setRootInjector(injector: Injector): void {
  rootInjector = injector;
  _rootInjector$.next(rootInjector);
}

export function addChildInjector(injector: Injector): void {
  !childInjectors.includes(injector) && childInjectors.push(injector);
}

export function getInjectableInstance<T>(type: Type<T>): T {
  let rooted = rootInjector.get(type, null);
  if(!rooted) {
    for (const injector of childInjectors) {
      const child = injector.get(type, null);
      if(child) {
        rooted = child;
        break;
      }
    }
  }

  if(!rooted) {
    throw Error("Service instance not found. Check for injectors consistency!");
  }

  return rooted;
}
