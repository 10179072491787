import { ComponentRef, Type, InjectionToken } from "@angular/core";
import { SetupSignleton } from "../decorators/setup-singleton.decorator";
import { Subject } from "rxjs";

export const DIALOGS_SERVICE_TOKEN = new InjectionToken("IOCDialogsService");

export interface IDialog {
  internalClose(componentRef?: ComponentRef<any>);
  show();
}

export interface IOCDialog {
  result: Subject<any>;
}

export interface IOCDialogsService {
  /**
   * Создание всплывающего диалога от semantic.ui на основании типа компонента.
   * @param component тип компонента, который будет отображён во всплывающем диалоге.
   * @param parameters параметры, передаваемые в компонент, как если бы компонент имплементировал интерфейс TParams.
   *                   Дополнительно можно передать параметры открытия всплывающего диалога в специальном поле $dialog.
   */
  show<TDialog, TParams>(
    component: Type<TDialog>,
    parameters?: TParams
  ): TDialog;
  /**
   * Создание модального всплывающего диалога от semantic.ui на основании типа компонента.
   * @param component тип компонента, который будет отображён во всплывающем диалоге.
   * @param parameters параметры, передаваемые в компонент, как если бы компонент имплементировал интерфейс TParams.
   *                   Дополнительно можно передать параметры открытия всплывающего диалога в специальном поле $dialog.
   */
  showModal<TDialog, TParams>(
    component: Type<TDialog>,
    parameters?: TParams,
    lazyModalComponentName?: string
  ): TDialog;

  /**
   * Вывод информационного сообщения во всплывающем диалоге от semantic.ui.
   * @param text текст сообщения (поддерживается HTML-разметка).
   * @param header заголовок сообщения (по умолчанию - Information)
   */
  info(text: string, header?: string): Promise<void>;
  /**
   * Вывод тревожного сообщения во всплывающем диалоге от semantic.ui.
   * @param text текст сообщения (поддерживается HTML-разметка).
   * @param header заголовок сообщения (по умолчанию - Alert)
   */
  alert(text: string, header?: string): Promise<void>;
  /**
   * Вывод предупреждающего сообщения во всплывающем диалоге от semantic.ui.
   * @param text текст сообщения (поддерживается HTML-разметка).
   * @param header заголовок сообщения (по умолчанию - Warning)
   */
  warning(text: string, header?: string): Promise<void>;
  /**
   * Вывод сообщения об ошибке во всплывающем диалоге от semantic.ui.
   * @param text текст сообщения (поддерживается HTML-разметка).
   * @param header заголовок сообщения (по умолчанию - Error)
   */
  error(text: string, header?: string): Promise<void>;
  /**
   * Вывод подтверждающего вопроса вида Да/Нет и вызывает промис только если ответили Да
   * @param text текст вопроса (поддерживается HTML-разметка).
   * @param header заголовок сообщения (по умолчанию - Confirmation)
   */
  confirm(text: string, header?: string): Promise<void | boolean>;
  /**
   * Вывод вопроса вида Да/Нет[/Отмена] и вызывает промис только если ответили Да или Нет
   * @param text текст вопроса (поддерживается HTML-разметка).
   * @param header заголовок сообщения (по умолчанию - Confirmation)
   */
  ask(
    text: string,
    showCancelButton?: boolean,
    header?: string
  ): Promise<boolean>;
  /*

    prompt(text: string, header?: string, value?: string, addParams?: IPopupDialogOptions): Promise<string>;
*/
}

export class DefaultDialogsService implements IOCDialogsService {
  show = () => { throw new Error("Not Implemented!"); };
  showModal = () => { throw new Error("Not Implemented!"); };
  info = () => { throw new Error("Not Implemented!"); };
  alert = () => { throw new Error("Not Implemented!"); };
  warning = () => { throw new Error("Not Implemented!"); };
  error = () => { throw new Error("Not Implemented!"); };
  confirm = () => { throw new Error("Not Implemented!"); };
  ask = () => { throw new Error("Not Implemented!"); };
}

export class CoreDialogsService implements IOCDialogsService {
  show: <TDialog, TParams>(
    component: Type<TDialog>,
    parameters?: TParams
  ) => TDialog;
  showModal: <TDialog, TParams>(
    component: Type<TDialog>,
    parameters?: TParams,
    lazyModalComponentName?: string
  ) => TDialog;
  info: (text: string, header?: string) => Promise<void>;
  alert: (text: string, header?: string) => Promise<void>;
  warning: (text: string, header?: string) => Promise<void>;
  error: (text: string, header?: string) => Promise<void>;
  confirm: (text: string, header?: string) => Promise<any>;

  ask: (
    text: string,
    showCancelButton?: boolean,
    header?: string
  ) => Promise<boolean>;

  @SetupSignleton(DIALOGS_SERVICE_TOKEN, DefaultDialogsService)
  static instance: IOCDialogsService = null;
}

export const dialog: IOCDialogsService = new CoreDialogsService();

export function show<TDialog, TParams>(
  component: Type<TDialog>,
  parameters?: TParams
): TDialog {
  return dialog.show(component, parameters);
}
export function showModal<TDialog, TParams>(
  component: Type<TDialog>,
  parameters?: TParams,
  lazyModalComponentName?: string
): TDialog {
  return dialog.showModal(component, parameters, lazyModalComponentName);
}
