import { Observable } from "rxjs";
import { filter, distinctUntilChanged, shareReplay } from "rxjs/operators";

export function statefull<T>(o: Observable<T>): Observable<T> {
  return o.pipe(
    filter(x => x !== undefined),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
}
