import { OCBaseComponent } from '@core/core.module/base/base.component';
import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { Subject, timer, BehaviorSubject } from "rxjs";
import { UntilDestroy } from "@core/core.module/decorators/until-destroy.decorator";
import { untilDestroyed } from "@core/core.module/utils/take-until-destroy/take-until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NrtFormGroup } from "@core/core.module/base/NrtFormGroup";
import { FormUtils } from "@core/core.module/decorators/forms/form.utils";
import { ManagementMetaDataService } from "projects/player-portal/player-portal-management/src/app/services/api/managementMetaData/managementMetaData.service";
import { EmitLoader } from "@core/core.module/directives/loader/loader.decorator";
import { takeWhile, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: "logout-alert-modal",
  templateUrl: "./logout-alert-modal.component.html",
  styleUrls: ["./logout-alert-modal.component.scss"],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutAlertModalComponent {
  protected _result$ = new Subject<{ isStay: boolean }>();
  public result = this._result$.pipe(untilDestroyed(this)).toPromise();

  private _logoutTime$ = new BehaviorSubject<number>(10);
  public logoutTime$ = this._logoutTime$.asObservable().pipe(untilDestroyed(this));

  private isStopTimer = false;

  @Input() set logoutTime(val: number) {
    this._logoutTime$.next(val);
  }

  get logoutTime() {
    return this._logoutTime$.value;
  }

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    let logoutTime = this.logoutTime;

    timer(0, 1000)
    .pipe(
      takeWhile( () => logoutTime > 0 && !this.isStopTimer),
      tap(() => {
        logoutTime--;
        this._logoutTime$.next(logoutTime)
      }),
      untilDestroyed(this)
    ).subscribe(x => {
      if(this.logoutTime == 0){
        this.isStopTimer = true;
        this.setResult(false);
      }
    })
  }

  ngOnDestroy(): void {}


  private setResult(isStay: boolean) {
    this._result$.next({ isStay });
    this._result$.complete();

    this.bsModalRef.hide();
  }

  confirm() {
    this.setResult(true);
    this.isStopTimer = true;
  }

  closeModal() {
    this.setResult(false);
  }
}
