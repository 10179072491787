import { OCCoreModule } from '@core/core.module/core.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { pgCard } from './card.component';
import { ProgressModule } from '../progress/progress.module';
import { SafeStyle } from '@core/core.module/pipes/safe-style.pipe';
import { RippleModule } from '@core/core.module/directives/ripple/ripple.module';
import { pgIconModule } from '../icon/menu-icon.module';

@NgModule({
  declarations: [pgCard],
  entryComponents: [pgCard],
  exports: [pgCard],
  providers: [SafeStyle],
  imports: [CommonModule, ProgressModule, OCCoreModule,ProgressModule, RippleModule, pgIconModule]
})
export class pgCardModule {

}
