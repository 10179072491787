export const themes = {
  dark: {
    "--brandColor": "#285779",
    "--primaryBackground": "#303030",
    "--primaryColor": "#fff",
    "--secondaryColor": "#fff",
    "--additionalColor": "#999999",
    "--appBarColor": "#303030",
    "--cardBackground": "#424242",
    "--rewardShadowColor": "#212121",
    "--locationBgColor": "#424242",
    "--locationShadowColor": "#212121",
    "--locationHeaderShadowColor": "transparent",
    "--filterNotActiveBg": "#303030",
    "--filterActiveBg": "#424242",
    "--formControlPrimaryBg": "#424242",
    "--formControlSecondaryBg": "#303030",
    "--formControlBorderPrimaryColor": "rgba(255, 255, 255, 0.3)",
    "--formControlBorderSecondaryColor": "rgba(255, 255, 255, 0.6)",
    "--formControlDisabledBg": "#383838",
    "--formControlDisabledColor": "#989898",
    "--progressOverlayBg": "rgba(48, 48, 48, 0.8)",
    "--dropdownItemPadding": "20px",
    "--dropdownItemFontSize": "13.5px",
    "--formNoteColor": "#c9a843",
    "--commonShadow1": "#303030",
    "--commonShadow2": "#242424",
    "--titleColor": "#fff",
    "--modalShadowColor": "#1f1f1f"
  },

  light: {
    "--brandColor": "#285779",
    "--primaryBackground": "#fafafa",
    "--primaryColor": "#2c2c2c",
    "--secondaryColor": "#626262",
    "--additionalColor": "#626262",
    "--appBarColor": "#fafafa",
    "--cardBackground": "#fff",
    "--rewardShadowColor": "#bdbdbd",
    "--locationBgColor": "var(--color-info-lightest)",
    "--locationShadowColor": "#747474",
    "--locationHeaderShadowColor": "#fff",
    "--filterNotActiveBg": "#f0f0f0",
    "--filterActiveBg": "#626262",
    "--formControlPrimaryBg": "#fff",
    "--formControlSecondaryBg": "#f0f0f0",
    "--formControlBorderPrimaryColor": "rgba(0, 0, 0, 0.07)",
    "--formControlBorderSecondaryColor": "rgba(0, 0, 0, 0.1)",
    "--formControlDisabledBg": "#f8f8f8",
    "--formControlDisabledColor": "rgba(98, 98, 98, 0.5)",
    "--progressOverlayBg": "rgba(250, 250, 250, 0.8)",
    "--dropdownItemPadding": "20px",
    "--dropdownItemFontSize": "13.5px",
    "--formNoteColor": "#c9a843",
    "--commonShadow1": "#ffffff",
    "--commonShadow2": "#cccccc",
    "--titleColor": "var(--brandColor)",
    "--modalShadowColor": "#5d5b5b",
    //kiosk theme variables
    "--kioskPrimaryBtnBackground": "#C7001F",
    "--kioskDefaultBtnBackground": "#B6863E",
    "--color-success": "#C7001F",
    "--bannerBackground": "#C7001F",
    "--kioskCardBorderColor": "#707070",
    "--medallionsBarBackground": "#000",
    "--navItemBackground": "white",
    "--navItemActiveBackground": "#C7001F"
  }
};
