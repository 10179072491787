import { Injectable, Inject } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from "@angular/common/http";
import { AuthService } from "./auth.service";
import { catchError, filter, take, switchMap, finalize } from "rxjs/operators";
import { BehaviorSubject, NEVER, of } from "rxjs";
import { throwError } from "rxjs";
import { Observable } from "rxjs";
import { LOGIN_PATH } from "./injection-tokens";
import { AuthData } from "./AuthData";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    public authService: AuthService,
    private router: Router,
    @Inject(LOGIN_PATH) private loginPath?: string
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if(request.url.includes("10.0.2.147")) {
    //   return next.handle(request);
    // }

    const token = this.authService.jwtToken;
    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
          // } else if (error instanceof HttpErrorResponse && error && error.error === 'invalid_grant') {
          //   this.router.navigateByUrl(this.loginPath || 'session/login');
          //   return NEVER;
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    if (request.url.includes("oauth/token")) {
      return request;
    } else {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        catchError(error => {
          if (
            this.loginPath &&
            error instanceof
              HttpErrorResponse /*  &&
            error && error.error &&
            error.error.error === 'invalid_grant' */
          ) {
            this.authService.doLogoutUser();
            return of(null);
          }
          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshing = false;
        }),
        switchMap((token: AuthData) => {
          this.isRefreshing = false;

          if (token) {
            this.refreshTokenSubject.next(token.access_token);
            return next.handle(this.addToken(request, token.access_token));
          } else {
            return of(null);
            //return next.handle(request);
          }
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }
}
