import { DESTROY_SUBJECT_NAME } from './../utils/take-until-destroy/common';
import { getDef, isInjectableType, IS_DECORATOR_APPLIED } from "../utils/take-until-destroy/common";

function decorateProvider(type) {
  type.prototype.ngOnDestroy = decorateNgOnDestroy(type.prototype.ngOnDestroy);
  type[IS_DECORATOR_APPLIED] = true;
}

function decorateDirective(type) {
  const def = getDef(type);
  def['onDestroy' as any] = decorateNgOnDestroy(def.onDestroy);
  def[IS_DECORATOR_APPLIED] = true;
}

function decorateNgOnDestroy(ngOnDestroy) {
  return function () {

    ngOnDestroy && ngOnDestroy.call(this);

    if (this[DESTROY_SUBJECT_NAME]) {
      this[DESTROY_SUBJECT_NAME].next();
      this[DESTROY_SUBJECT_NAME].complete();
    }

  };
}

export function UntilDestroy() {
  return (target) => {
    if (isInjectableType(target)) {
        decorateProvider(target);
    }
    else {
        decorateDirective(target);
    }
  }
};
