import { Injectable } from '@angular/core';
import { SettingsStore, ISettings } from './settings.store';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  constructor(private settingsStore: SettingsStore) {

  }

  setSettings(settings: Partial<ISettings>, skipLayout?: boolean) {
    this.settingsStore.update(state => {
      let currentSettings = {
        ...state,
        ...settings
      };
      return currentSettings;
    });
  }
}
