export const environment = {
  production: true,
  version: '0.0.8',
  // branding: {
  //   "41DB233D47EF": "tp",
  //   XXXX: "elite"
  // },

  API_ENDPOINT: 'https://prod-oc3-player-portal-api.azurewebsites.net',
  AUTH_ENDPOINT: 'https://prod-oc3-auth-api.azurewebsites.net',
  REMOTE_AUTH_ENDPOINT: "https://auth.offercraft.net",
  COBRA_API_ENDPOINT: "https://c-api.reward-access.com",
  COBRA_UI_ENDPOINT: "https://c.reward-access.com",
  CLIENT_ID: "player-portal",
  FB_APP_ID: "347237865865013",
  GOOGLE_APP_ID:
    "350079698726-d7h1fbh5ep1q2k78oej2nfgh5l877vn4.apps.googleusercontent.com"
};
