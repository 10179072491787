import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyboardComponent } from './keyboard.component';
import { AttachKeyboardDirective } from './attach-keyboard.directive';
import { KeyboardService } from './keyboard.service';
import { pgIconModule } from '@core/pages-ui.module/components/icon/menu-icon.module';

// export function provideKeyboardService() {
//   return KeyboardService.Instance// || (new KeyboardService());
// }

@NgModule({
  declarations: [KeyboardComponent, AttachKeyboardDirective],
  entryComponents: [KeyboardComponent],
  exports: [KeyboardComponent, AttachKeyboardDirective],
  //providers: [KeyboardService],
  imports: [
    CommonModule,
    pgIconModule
  ]
})
export class KeyboardModule {

  // static forRoot(): ModuleWithProviders {
  //   return {
  //     ngModule: KeyboardModule,
  //     providers: [
  //       {provide: KeyboardService, useFactory: provideKeyboardService }
  //     ]
  //   };
  // }

 }
