import { AuthService } from './../../../core.module/jwt/auth.service';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  HostListener,
  AfterContentInit} from '@angular/core';
import { Observable } from 'rxjs';
import { pagesToggleService } from '../../services/toggler.service';
//import { Manager, Swipe, DIRECTION_HORIZONTAL } from 'hammerjs';
import {
  Router,
  Event,
  NavigationEnd,
  ActivatedRoute
} from '@angular/router';
import { OCBaseComponent } from '@core/core.module/base/base.component';
import { UntilDestroy } from "@core/core.module/decorators/until-destroy.decorator";
import { untilDestroyed } from "@core/core.module/utils/take-until-destroy/take-until-destroy";
declare var pg: any;

@UntilDestroy()
@Component({
  selector: 'root-layout',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootLayout extends OCBaseComponent
  implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild('root', { static: false })
  root;
  layoutState: string;
  extraLayoutClass: string;
  _boxed: boolean = false;
  _menuPin: boolean = false;
  _enableHorizontalContainer: boolean;
  _pageContainerClass = '';
  _contentClass = '';
  _footer: boolean = true;
  _menuDrawerOpen: boolean = false;
  //Mobile
  _secondarySideBar: boolean = false;
  //Mobile
  _mobileSidebar: boolean = false;
  //Mobile
  _mobileHorizontalMenu: boolean = false;
  _pageTitle: string;
  _allowBack: boolean;
  _backTo: string;
  //Sub layout - eg: email
  _layoutOption: string;

  //_subscriptions: Array<Subscription> = [];

  _layout;
  @Input()
  public contentClass: string = '';

  @Input()
  public pageWrapperClass: string = '';

  @Input()
  public footer: boolean = true;
  menuLinks: any[];

  constructor(
    protected toggler: pagesToggleService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected auth: AuthService
  ) {
    super();

    if (this.layoutState) {
      pg.addClass(document.body, this.layoutState);
    }

    this.toggler.menuPinToggle
      .pipe(untilDestroyed(this))
      .subscribe(state => {
          this._menuPin = !state;
          this.toggleMenuPinInternal();
      });

    this.toggler.pageContainerClass
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        this._pageContainerClass = state;
      });

    this.toggler.contentClass
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        this._contentClass = state;
      });

    this.toggler.bodyLayoutClass
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (state) {
          this.extraLayoutClass = state;
          pg.addClass(document.body, this.extraLayoutClass);
        }
      });

    this.toggler.Applayout.pipe(untilDestroyed(this)).subscribe(state => {
      this.changeLayout(state);
    });

    this.toggler.Footer.pipe(untilDestroyed(this)).subscribe(state => {
      this._footer = state;
    });

    this.toggler.mobileHorizontaMenu
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        this._mobileHorizontalMenu = state;
      });

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        var root = this.router.routerState.snapshot.root;
        while (root) {
          if (root.children && root.children.length) {
            root = root.children[0];
          } else if (root.data) {
            //Custom Route Data here
            this._pageTitle = root.data['title'];
            this._layoutOption = root.data['layoutOption'];
            this._allowBack = root.data['allowBack'];
            this._backTo = root.data['backTo'];
            this._boxed = root.data['boxed'];
            break;
          } else {
            break;
          }
        }
        //Reset Any Extra Layouts added from content pages
        pg.removeClass(document.body, this.extraLayoutClass);
        //Close Sidebar and Horizonta Menu
        if (this._mobileSidebar) {
          this._mobileSidebar = false;
          pg.removeClass(document.body, 'sidebar-open');
          this.toggler.toggleMobileSideBar(this._mobileSidebar);
        }
        this._mobileHorizontalMenu = false;
        this.toggler.toggleMobileHorizontalMenu(this._mobileHorizontalMenu);
        //Scoll Top
        //this.scrollToTop();
      }
    });
  }

  /** @function changeLayout
   *   @description Add Document Layout Class
   */
  changeLayout(type: string) {
    this.layoutState = type;
    if (type) {
      pg.addClass(document.body, type);
    }
  }

  /** @function removeLayout
   *   @description Remove Document Layout Class
   */
  removeLayout(type: string) {
    pg.removeClass(document.body, type);
  }

  ngOnInit() {
    this.route.data.subscribe((data: { menuLinks: any[] }) => {
      this.menuLinks = data.menuLinks;
    });
  }

  logout(silent?: boolean): Observable<boolean> {
    return this.auth
      .logout(silent)
      .pipe(untilDestroyed(this));
  }

  getStartPosition(e) {
    const delta_x = e.deltaX;
    const delta_y = e.deltaY;
    const final_x = e.srcEvent.pageX || e.srcEvent.screenX || 0;
    const final_y = e.srcEvent.pageY || e.srcEvent.screenY || 0;

    return {
      x: final_x - delta_x,
      y: final_y - delta_y
    };
  }

  ngAfterContentInit(): void {
    // const hammer = new Manager(document.body);
    // hammer.add(new Swipe({ direction: DIRECTION_HORIZONTAL }));

    // hammer.on('swipeleft', e => {
    //   if (this._mobileSidebar) {
    //     this.toggleMobileSidebar();
    //     e.srcEvent.stopPropagation();
    //   }
    // });

    // hammer.on('swiperight', e => {
    //   const { x } = this.getStartPosition(e);

    //   if (x >= 0 && x <= 50) {
    //     if (!this._mobileSidebar) {
    //       this.toggleMobileSidebar();
    //       e.srcEvent.stopPropagation();
    //     }
    //   }
    // });
  }

  /** @function scrollToTop
   *   @description Force to scroll to top of page. Used on Route
   */
  scrollToTop() {
    let top = window.pageYOffset;
    if (top == 0) {
      let scroller = document.querySelector('.page-container');
      if (scroller) scroller.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }

  /** @function openQuickView
   *   @description Show Quick View Component / Right Sidebar - Service
   */
  openQuickView($e) {
    $e.preventDefault();
    this.toggler.toggleQuickView();
  }

  /** @function openSearch
   *   @description Show Quick Search Component - Service
   */

  openSearch($e) {
    $e.preventDefault();
    this.toggler.toggleSearch(true);
  }

  /** @function toggleMenuPin
   *   @description Permanently Open / Close Main Sidebar
   */
  toggleMenuPin() {
    this.toggleMenuPinInternal();
    this.toggler.toggleMenuPin(this._menuPin);
  }

  toggleMenuPinInternal() {
    if (pg.isVisibleSm()) {
      this._menuPin = false;
      return;
    }
    if (this._menuPin) {
      pg.removeClass(document.body, 'menu-pin');
      this._menuPin = false;
    } else {
      pg.addClass(document.body, 'menu-pin');
      this._menuPin = true;
    }
  }

  /** @function toggleMenuDrawer
   *   @description Open Main Sidebar Menu Drawer - Service
   */
  toggleMenuDrawer() {
    this._menuDrawerOpen = this._menuDrawerOpen == true ? false : true;
    this.toggler.toggleMenuDrawer();
  }

  /** @function toggleMobileSidebar
   *   @description Open Main Sidebar on Mobile - Service
   */
  toggleMobileSidebar() {
    if (this._mobileSidebar) {
      this._mobileSidebar = false;
      pg.removeClass(document.body, 'sidebar-open');
    } else {
      this._mobileSidebar = true;
      pg.addClass(document.body, 'sidebar-open');
    }
    this.toggler.toggleMobileSideBar(this._mobileSidebar);
  }

  /** @function toggleHorizontalMenuMobile
   *   @description Open Secondary Sidebar on Mobile - Service
   */
  toggleSecondarySideBar() {
    this._secondarySideBar = this._secondarySideBar == true ? false : true;
    this.toggler.toggleSecondarySideBar(this._secondarySideBar);
  }

  /** @function toggleHorizontalMenuMobile
   *   @description Call Horizontal Menu Toggle Service for mobile
   */
  toggleHorizontalMenuMobile() {
    this._mobileHorizontalMenu =
      this._mobileHorizontalMenu == true ? false : true;
    this.toggler.toggleMobileHorizontalMenu(this._mobileHorizontalMenu);
  }

  @HostListener('window:resize', [])
  onResize() {
    this.autoHideMenuPin();
  }

  get isMobile() {
    return window.innerWidth < 1025;
  }

  //Utils
  autoHideMenuPin() {
    if (this.isMobile) {
      if (pg.hasClass(document.body, 'menu-pin')) {
        pg.addClass(document.body, 'menu-unpinned');
        pg.removeClass(document.body, 'menu-pin');
      }
    } else {
      if (pg.hasClass(document.body, 'menu-unpinned')) {
        pg.addClass(document.body, 'menu-pin');
      }
    }
  }
}
