import { Validators } from "@angular/forms";
import { FormUtils } from "../form.utils";

function addValidator(
  target: any,
  propertyKey: string,
  message?: string
) {
  const control = FormUtils.getCreateFormControl(target, propertyKey);

  control.validators.push(
    {
      name: "email",
      validator: Validators.email,
      message: message || "Valid email required"
    }
  );
}

export const EmailValidator = (message?: string) => (
  target: any,
  propertyKey: string
) => {
  addValidator(target, propertyKey, message);
};
