/// <reference path="./typings.d.ts" />
// tslint:disable:no-bitwise
// tslint:disable:no-shadowed-variable

//import * as linq from "linq";
import { Reflection } from "./Reflection";
import * as dayjs_ from "dayjs";
import { Type } from "@angular/core";
import { BehaviorSubject, fromEvent, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
const dayjs = dayjs_;

export function isBoolean(obj) {
  return typeof obj === "boolean";
}
export function isNumber(obj) {
  return typeof obj === "number";
}
export function isString(obj) {
  return typeof obj === "string";
}
export function isFunction(obj) {
  return typeof obj === "function";
}
export function isType(obj) {
  return isFunction(obj);
}
export function isStringMap(obj) {
  return obj !== null && typeof obj === "object";
}
const STRING_MAP_PROTO = Object.getPrototypeOf({});
export function isStrictStringMap(obj) {
  return isStringMap(obj) && Object.getPrototypeOf(obj) === STRING_MAP_PROTO;
}
export function isPromise(obj) {
  // allow any Promise/A+ compliant thenable.
  // It's up to the caller to ensure that obj.then conforms to the spec
  return isNotEmpty(obj) && isFunction(obj.then);
}
export function isArray(obj) {
  return Array.isArray(obj);
}
export function toArray(obj) {
  return Array.isArray(obj) ? obj : !!obj ? [obj] : [];
}
export function isDate(obj) {
  return obj instanceof Date && !isNaN(obj.valueOf());
}

/**
 * Returns true if context equals null or undefined.
 */
export function isEmpty(context: any): boolean {
  return context === undefined || context === null;
}
/**
 * Returns true if context doesn't equal neither null nor undefined.
 */
export function isNotEmpty(obj) {
  return obj !== undefined && obj !== null;
}

export function resolveValue<T>(value: T | (() => T)): T {
  return typeof value === "function" ? (value as () => T)() : value;
}

/**
 * Returns value if context equals null or undefined or context in another case.
 */
export function ifEmpty<T>(context: T, value: T | (() => T)): T {
  return isEmpty(context) ? resolveValue(value) : context;
}
/**
 * Returns value if context doesn't equal neither null nor undefined. Otherwise undefined.
 */
export function ifNotEmpty<T>(context: any, value: T | (() => T)): T {
  return isNotEmpty(context) ? resolveValue(value) : undefined;
}
/**
 * Returns value if context equals null or undefined or context in another case.
 */
export function coalesce<T>(context: T, value: T | (() => T)): T {
  return ifEmpty(context, value);
}

export function isJsObject(o) {
  return o !== null && (typeof o === "function" || typeof o === "object");
}

export function isPrimitive(obj) {
  return !isJsObject(obj);
}
export function hasConstructor(value, type) {
  return value.constructor === type;
}
export function escape(s) {
  return encodeURI(s);
}
export function escapeRegExp(s) {
  return s.replace(/([.*+?^=!:${}()|[\]\/\\])/g, "\\$1");
}
export function getRandomArbitrary(min, max): number {
  return Math.random() * (max - min) + min;
}

export function getRandomInt(min, max): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function guid(): string {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

export function download(
  link: string | Blob,
  type: string,
  filename?: string,
  newPage: boolean = false
) {
  if (link instanceof Blob && filename) {
    const blob = new Blob([link], { type: type });
    link = URL.createObjectURL(blob);
  }

  if (!newPage) {
    const element = document.createElement("a");
    element.setAttribute("href", link as string);
    if (filename) {
      element.setAttribute("download", filename);
    }

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  } else {
    window.open(link as string, filename);
  }
}

export function getRandomString(digitsNum: number = 12) {
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  for (let i = 0; i < digitsNum; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

export function b64ToBlob(
  b64Data: any,
  contentType?: string,
  sliceSize?: number
) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  const byteCharacters = atob(b64Data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

/**
 * Транслитерация кириллицы в URL
 */
export function urlRusLat(str: string): string {
  str = str.toLowerCase(); // все в нижний регистр
  const cyr2LatChars = new Array(
    ["Р°", "a"],
    ["Р±", "b"],
    ["РІ", "v"],
    ["Рі", "g"],
    ["Рґ", "d"],
    ["Рµ", "e"],
    ["С‘", "yo"],
    ["Р¶", "zh"],
    ["Р·", "z"],
    ["Рё", "i"],
    ["Р№", "y"],
    ["Рє", "k"],
    ["Р»", "l"],
    ["Рј", "m"],
    ["РЅ", "n"],
    ["Рѕ", "o"],
    ["Рї", "p"],
    ["СЂ", "r"],
    ["СЃ", "s"],
    ["С‚", "t"],
    ["Сѓ", "u"],
    ["С„", "f"],
    ["С…", "h"],
    ["С†", "c"],
    ["С‡", "ch"],
    ["С€", "sh"],
    ["С‰", "shch"],
    ["СЉ", ""],
    ["С‹", "y"],
    ["СЊ", ""],
    ["СЌ", "e"],
    ["СЋ", "yu"],
    ["СЏ", "ya"],

    ["Рђ", "A"],
    ["Р‘", "B"],
    ["Р’", "V"],
    ["Р“", "G"],
    ["Р”", "D"],
    ["Р•", "E"],
    ["РЃ", "YO"],
    ["Р–", "ZH"],
    ["Р—", "Z"],
    ["Р", "I"],
    ["Р™", "Y"],
    ["Рљ", "K"],
    ["Р›", "L"],
    ["Рњ", "M"],
    ["Рќ", "N"],
    ["Рћ", "O"],
    ["Рџ", "P"],
    ["Р ", "R"],
    ["РЎ", "S"],
    ["Рў", "T"],
    ["РЈ", "U"],
    ["Р¤", "F"],
    ["РҐ", "H"],
    ["Р¦", "C"],
    ["Р§", "CH"],
    ["РЁ", "SH"],
    ["Р©", "SHCH"],
    ["РЄ", ""],
    ["Р«", "Y"],
    ["Р¬", ""],
    ["Р­", "E"],
    ["Р®", "YU"],
    ["РЇ", "YA"],

    ["a", "a"],
    ["b", "b"],
    ["c", "c"],
    ["d", "d"],
    ["e", "e"],
    ["f", "f"],
    ["g", "g"],
    ["h", "h"],
    ["i", "i"],
    ["j", "j"],
    ["k", "k"],
    ["l", "l"],
    ["m", "m"],
    ["n", "n"],
    ["o", "o"],
    ["p", "p"],
    ["q", "q"],
    ["r", "r"],
    ["s", "s"],
    ["t", "t"],
    ["u", "u"],
    ["v", "v"],
    ["w", "w"],
    ["x", "x"],
    ["y", "y"],
    ["z", "z"],

    ["A", "A"],
    ["B", "B"],
    ["C", "C"],
    ["D", "D"],
    ["E", "E"],
    ["F", "F"],
    ["G", "G"],
    ["H", "H"],
    ["I", "I"],
    ["J", "J"],
    ["K", "K"],
    ["L", "L"],
    ["M", "M"],
    ["N", "N"],
    ["O", "O"],
    ["P", "P"],
    ["Q", "Q"],
    ["R", "R"],
    ["S", "S"],
    ["T", "T"],
    ["U", "U"],
    ["V", "V"],
    ["W", "W"],
    ["X", "X"],
    ["Y", "Y"],
    ["Z", "Z"],

    [" ", "_"],
    ["0", "0"],
    ["1", "1"],
    ["2", "2"],
    ["3", "3"],
    ["4", "4"],
    ["5", "5"],
    ["6", "6"],
    ["7", "7"],
    ["8", "8"],
    ["9", "9"],
    ["-", "-"],
    ["/", "/"]
  );

  let newStr = "";

  for (let i = 0; i < str.length; i++) {
    const ch = str.charAt(i);
    let newCh = "";

    for (let j = 0; j < cyr2LatChars.length; j++) {
      if (ch === cyr2LatChars[j][0]) {
        newCh = cyr2LatChars[j][1];
      }
    }
    // Если найдено совпадение, то добавляется соответствие, если нет - пустая строка
    newStr += newCh;
  }
  // Удаляем повторяющие знаки - Именно на них заменяются пробелы.
  // Так же удаляем символы перевода строки, но это наверное уже лишнее
  return newStr.replace(/[_]{2,}/gim, "_").replace(/\n/gim, "");
}

/**
 * Checks if value could be converted into integer number.
 */
export function isIntNumber(val: any): boolean {
  const isInt =
    !isNaN(val) &&
    val.toString().indexOf(".") < 0 &&
    Number(val) === Math.ceil(val);
  let result;
  if (val === "-" || isInt) {
    result = true;
  } else {
    result = false;
  }

  return result;
}

/**
 * Checks if value could be converted into float number.
 */
export function isFloatNumber(val: any) {
  let result;

  if (val === "-" || !isNaN(Number(val))) {
    result = true;
  } else {
    result = false;
  }

  return result;
}

/*
 ** Returns the caret (cursor) position of the specified text field.
 ** Return value range is 0-oField.value.length.
 */
export function getCaretPosition(
  input: HTMLInputElement | HTMLTextAreaElement | any
) {
  // Initialize
  let iCaretPos = 0;

  // IE Support
  if (document["selection"]) {
    // Set focus on the element
    input.focus();

    // To get cursor position, get empty selection range
    const oSel = document["selection"].createRange();

    // Move selection start to 0 position
    oSel.moveStart("character", -input.value.length);

    // The caret position is selection length
    iCaretPos = oSel.text.length;
  } else if (input.selectionStart || input.selectionStart === 0) {
    iCaretPos = input.selectionStart;
  }

  // Return results
  return iCaretPos;
}

/**
 * Returns current decimal separator
 */
export function getDecimalSeparator(): string {
  return (1.1).toLocaleString().substring(1, 2);
}

// ReSharper disable once InconsistentNaming
const debounceHandlers = <{ context: string; timeoutHandler?: any }[]>[];
export function debounce(
  fn: Function,
  debounceTime?: number,
  context?: any
): any {
  if (!isFunction(fn)) {
    clearDebounce(context);
  }

  if (isEmpty(context) && fn) {
    context = Reflection.getFnBody(fn).ToMd5();
  }
  let currentHandler = debounceHandlers.find(v => v.context === context);
  if (isJsObject(currentHandler)) {
    clearTimeout(currentHandler.timeoutHandler);
  } else {
    currentHandler = { context };
    debounceHandlers.push(currentHandler);
  }
  currentHandler.timeoutHandler = setTimeout(() => {
    fn();
    debounceHandlers.filter(x => x !== currentHandler);
  }, debounceTime);
  return currentHandler.timeoutHandler;
}

export function clearDebounce(context: any) {
  if (!isEmpty(context)) {
    const currentHandler = debounceHandlers.find(v => v.context === context);
    if (isJsObject(currentHandler)) {
      clearTimeout(currentHandler.timeoutHandler);
      debounceHandlers.filter(x => x !== currentHandler);
    }
  }
}

/**
 * Форматирование числа по формату
 * @param value исходное значение
 * @param format формат вида '{any_text}000.00#,{any_text}' или 'N:{any_text}000.00#,{any_text}'
 */
export function formatNumber(value: number, format: string): string {
  let prefix = "";
  let suffix = "";
  let useGrouping = false;
  let minimumIntegerDigits = 0;
  let minimumFractionDigits = 0;
  let maximumFractionDigits = 0;
  let mode = "prefix";
  for (const item of format) {
    switch (mode) {
      case "prefix":
        if (item !== "0") {
          prefix += item;
        } else {
          minimumIntegerDigits++;
          mode = "int";
        }
        break;
      case "int":
        if (item === "0") {
          minimumIntegerDigits++;
        } else if (item === "." || item === ",") {
          mode = "frac";
          useGrouping = item === ",";
        } else {
          mode = "suffix";
          suffix += item;
        }
        break;
      case "frac":
        if (item === "0") {
          minimumFractionDigits++;
          maximumFractionDigits++;
        } else if (item === "#") {
          mode = "frac1";
          maximumFractionDigits++;
        } else {
          mode = "suffix";
          suffix += item;
        }
        break;
      case "frac1":
        if (item === "#") {
          maximumFractionDigits++;
        } else {
          mode = "suffix";
          suffix += item;
        }
        break;
      default:
        suffix += item;
        break;
    }
  }
  const val = value.toLocaleString(undefined, {
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,
    useGrouping: useGrouping
  });
  return prefix + val + suffix;
}

export function formatEnum(
  value: any,
  values: { [key: string]: string }
): string {
  return values[value.toString()] || "";
}

export function OCFormat(
  value: any,
  format: string,
  defaultText?: string
): string {
  defaultText =
    defaultText === undefined || defaultText === null ? "" : defaultText;
  format = format === undefined || format === null ? "" : format.toString();
  if (format === "" && dayjs.isDayjs(value)) {
    format = "d:L";
  }
  let res = value === undefined || value === null ? "" : value.toString();
  if (format && res) {
    let formatType = "";
    if (
      format.startsWith("n:") ||
      format.startsWith("b:") ||
      format.startsWith("d:") ||
      format.startsWith("e:")
    ) {
      formatType = format.substr(0, 1).toLowerCase();
      format = format.substr(2);
    }

    if (formatType === "b" || typeof value === "boolean") {
      // это форматирование логического типа - формат вида '{YES_TEXT}|{NO_TEXT}' or 'B:{YES_TEXT}|{NO_TEXT}'
      let key = true.toString();
      const values = {};
      format.split("|").forEach(x => {
        const arr = x.split("=", 2);
        if (arr.length === 1) {
          arr[1] = arr[0];
          arr[0] = key;
        }
        if (arr[0] === true.toString()) {
          key = false.toString();
        }
        values[arr[0]] = arr[1];
      });
      //            res = (value ? values[0] : values[1]) || '';
      res = formatEnum(value, values);
    } else if (formatType === "e") {
      // это форматирование перечисления - формат вида '0={value1}|1={value2}|2={value3}' or 'E:0={value1}|1={value2}|2={value3}'
      const values = {};
      format.split("|").forEach(x => {
        const arr = x.split("=", 2);
        values[arr[0]] = arr[1];
      });
      res = formatEnum(value, values);
    } else if (formatType === "n" || typeof value === "number") {
      // это форматирование числа - формат вида '{any_text}000.00#{any_text}' или 'N:{any_text}000,00#{any_text}'
      res = formatNumber(value, format);
    } else {
      const date = dayjs(value);
      if (date.isValid()) {
        // это форматирование даты - формат как для dayjs
        res = date.format(format || "L");
      }
    }
  }
  return res === "" ? defaultText : res;
}

export function getPathValue(v: object, path: string): any {
  if (isEmpty(v) || !path) {
    return v;
  }
  if (v.hasOwnProperty(path)) {
    return v[path];
  }
  const arr = path.split(".");
  let key = "";
  for (let i = 0; i < arr.length; i++) {
    key = i === 0 ? arr[0] : key + "." + arr[i];
    if (v.hasOwnProperty(key)) {
      path = path.substring(path.indexOf(".") + 1);
      return getPathValue(v[key], path);
    }
  }
  return undefined;
}
export function setPathValue(v: object, path: string, value: any): void {
  if (isNotEmpty(v) && path) {
    if (!path.includes(".")) {
      v[path] = value;
    } else {
      const arr = path.split(".");
      let key = "";
      for (let i = 0; i < arr.length; i++) {
        key = i === 0 ? arr[0] : key + "." + arr[i];
        path = path.substring(path.indexOf(".") + 1);
        setPathValue(v[key], path, value);
      }
    }
  }
}

export function humanFileSize(bytes: number, si: number = 1024): string {
  const thresh = si ? 1000 : 1024;
  const units = ["Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + " " + units[u];
}

declare global {
  interface Window {
    _innerWidth: number;
  }
}

function isViewPort(screenWidth: number) {
  return window.matchMedia(`(max-width: ${screenWidth}px)`).matches;
}

const _isMobileView$ = new BehaviorSubject<boolean>(isViewPort(768));
const _isResizing$ = new Subject<boolean>();

export const isMobileView$ = _isMobileView$.asObservable();
export const isResizing$ = _isResizing$.asObservable();

fromEvent(window, "resize")
  .pipe(debounceTime(10))
  .subscribe(() => {
    _isMobileView$.next(isViewPort(768));
    _isResizing$.next(true);
  });

export function isMobileViewStrict(): boolean {
  window._innerWidth = window._innerWidth || window.innerWidth;
  return window._innerWidth < 768;
}

export function isTabletViewStrict(): boolean {
  window._innerWidth = window._innerWidth || window.innerWidth;
  return window._innerWidth < 960;
}

export function isDesktopViewStrict(): boolean {
  window._innerWidth = window._innerWidth || window.innerWidth;
  return window._innerWidth >= 960;
}

export function isMobileAgent(): boolean {
  return !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  );
}

export function toLowerCamelCase(s: string): string {
  let res = s || "";
  if (res !== "") {
    res = res[0].toLowerCase() + res.substr(1);
  }
  return res;
}

export function toUpperCamelCase(s: string): string {
  let res = s || "";
  if (res !== "") {
    res = res[0].toUpperCase() + res.substr(1);
  }
  return res;
}

/**
 * Deep copy an object (make copies of all its object properties, sub-properties, etc.)
 * An improved version of http://keithdevens.com/weblog/archive/2007/Jun/07/javascript.clone
 * that doesn't break if the constructor has required parameters
 *
 * It also borrows some code from http://stackoverflow.com/a/11621004/560114
 */
export function deepCopy(
  src: any,
  /* INTERNAL */ _visited?: any,
  _copiesVisited?: any
) {
  if (src === null || typeof src !== "object") {
    return src;
  }

  // Honor native/custom clone methods
  if (typeof src.clone === "function") {
    return src.clone(true);
  }

  // Special cases:
  // Date
  if (src instanceof Date) {
    return new Date(src.getTime());
  }
  // RegExp
  if (src instanceof RegExp) {
    return new RegExp(src);
  }
  // DOM Element
  if (src.nodeType && typeof src.cloneNode === "function") {
    return src.cloneNode(true);
  }

  // Initialize the visited objects arrays if needed.
  // This is used to detect cyclic references.
  if (_visited === undefined) {
    _visited = [];
    _copiesVisited = [];
  }

  // Check if this object has already been visited
  let i,
    // tslint:disable-next-line:prefer-const
    len = _visited.length;
  for (i = 0; i < len; i++) {
    // If so, get the copy we already made
    if (src === _visited[i]) {
      return _copiesVisited[i];
    }
  }

  // Array
  if (Object.prototype.toString.call(src) === "[object Array]") {
    // [].slice() by itself would soft clone
    const ret = src.slice();

    // add it to the visited array
    _visited.push(src);
    _copiesVisited.push(ret);

    let i = ret.length;
    while (i--) {
      ret[i] = deepCopy(ret[i], _visited, _copiesVisited);
    }
    return ret;
  }

  // If we've reached here, we have a regular object

  // make sure the returned object has the same prototype as the original
  let proto = Object.getPrototypeOf
    ? Object.getPrototypeOf(src)
    : src.__proto__;
  if (!proto) {
    proto = src.constructor.prototype; // this line would probably only be reached by very old browsers
  }
  const dest = object_create(proto);

  // add this object to the visited array
  _visited.push(src);
  _copiesVisited.push(dest);

  // tslint:disable-next-line:forin
  for (const key in src) {
    // Note: this does NOT preserve ES5 property attributes like 'writable', 'enumerable', etc.
    // For an example of how this could be modified to do so, see the singleMixin() function
    dest[key] = deepCopy(src[key], _visited, _copiesVisited);
  }
  return dest;
}

export function assignClone<T>(type: Type<T>, obj: any) {
  return Object.assign(Object.create(type.prototype), obj);
}

// If Object.create isn't already defined, we just do the simple shim,
// without the second argument, since that's all we need here
let object_create = Object.create;
if (typeof object_create !== "function") {
  object_create = function(o) {
    function F() {}
    F.prototype = o;
    return new F();
  };
}

export function remove_linebreaks(str: string) {
  return str.replace(/[\r\n]+/gm, "");
}

function utf8_encode(argString) {
  //  discOC at: http://phpjs.org/functions/utf8_encode/
  // original by: Webtoolkit.info (http://www.webtoolkit.info/)
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // improved by: sowberry
  // improved by: Jack
  // improved by: Yves Sucaet
  // improved by: kirilloid
  // bugfixed by: Onno Marsman
  // bugfixed by: Onno Marsman
  // bugfixed by: Ulrich
  // bugfixed by: Rafal Kukawski
  // bugfixed by: kirilloid
  //   example 1: utf8_encode('Kevin van Zonneveld');
  //   returns 1: 'Kevin van Zonneveld'

  if (argString === null || typeof argString === "undefined") {
    return "";
  }

  // .replace(/\r\n/g, "\n").replace(/\r/g, "\n");
  const string = argString + "";
  let utftext = "",
    start,
    end,
    stringl = 0;

  start = end = 0;
  stringl = string.length;
  for (let n = 0; n < stringl; n++) {
    let c1 = string.charCodeAt(n);
    let enc = null;

    if (c1 < 128) {
      end++;
    } else if (c1 > 127 && c1 < 2048) {
      enc = String.fromCharCode((c1 >> 6) | 192, (c1 & 63) | 128);
    } else if ((c1 & 0xf800) !== 0xd800) {
      enc = String.fromCharCode(
        (c1 >> 12) | 224,
        ((c1 >> 6) & 63) | 128,
        (c1 & 63) | 128
      );
    } else {
      // surrogate pairs
      if ((c1 & 0xfc00) !== 0xd800) {
        throw new RangeError("Unmatched trail surrogate at " + n);
      }
      const c2 = string.charCodeAt(++n);
      if ((c2 & 0xfc00) !== 0xdc00) {
        throw new RangeError("Unmatched lead surrogate at " + (n - 1));
      }
      c1 = ((c1 & 0x3ff) << 10) + (c2 & 0x3ff) + 0x10000;
      enc = String.fromCharCode(
        (c1 >> 18) | 240,
        ((c1 >> 12) & 63) | 128,
        ((c1 >> 6) & 63) | 128,
        (c1 & 63) | 128
      );
    }
    if (enc !== null) {
      if (end > start) {
        utftext += string.slice(start, end);
      }
      utftext += enc;
      start = end = n + 1;
    }
  }

  if (end > start) {
    utftext += string.slice(start, stringl);
  }

  return utftext;
}

export function md5(str) {
  // Calculate the md5 hash of a string
  // +   original by: Webtoolkit.info (http://www.webtoolkit.info/)
  // + namespaced by: Michael White (http://crestidg.com)

  const RotateLeft = function(lValue, iShiftBits) {
    return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
  };

  const AddUnsigned = function(lX, lY) {
    let lX4, lY4, lX8, lY8, lResult;
    lX8 = lX & 0x80000000;
    lY8 = lY & 0x80000000;
    lX4 = lX & 0x40000000;
    lY4 = lY & 0x40000000;
    lResult = (lX & 0x3fffffff) + (lY & 0x3fffffff);
    if (lX4 & lY4) {
      return lResult ^ 0x80000000 ^ lX8 ^ lY8;
    }
    if (lX4 | lY4) {
      if (lResult & 0x40000000) {
        return lResult ^ 0xc0000000 ^ lX8 ^ lY8;
      } else {
        return lResult ^ 0x40000000 ^ lX8 ^ lY8;
      }
    } else {
      return lResult ^ lX8 ^ lY8;
    }
  };

  const F = function(x, y, z) {
    return (x & y) | (~x & z);
  };
  const G = function(x, y, z) {
    return (x & z) | (y & ~z);
  };
  const H = function(x, y, z) {
    return x ^ y ^ z;
  };
  const I = function(x, y, z) {
    return y ^ (x | ~z);
  };

  const FF = function(a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  };

  const GG = function(a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  };

  const HH = function(a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  };

  const II = function(a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  };

  const ConvertToWordArray = function(str) {
    let lWordCount;
    const lMessageLength = str.length;
    const lNumberOfWords_temp1 = lMessageLength + 8;
    const lNumberOfWords_temp2 =
      (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
    const lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
    const lWordArray = Array(lNumberOfWords - 1);
    let lBytePosition = 0;
    let lByteCount = 0;
    while (lByteCount < lMessageLength) {
      lWordCount = (lByteCount - (lByteCount % 4)) / 4;
      lBytePosition = (lByteCount % 4) * 8;
      lWordArray[lWordCount] =
        lWordArray[lWordCount] | (str.charCodeAt(lByteCount) << lBytePosition);
      lByteCount++;
    }
    lWordCount = (lByteCount - (lByteCount % 4)) / 4;
    lBytePosition = (lByteCount % 4) * 8;
    lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
    lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
    lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
    return lWordArray;
  };

  const WordToHex = function(lValue) {
    let WordToHexValue = "",
      WordToHexValue_temp = "",
      lByte,
      lCount;
    for (lCount = 0; lCount <= 3; lCount++) {
      lByte = (lValue >>> (lCount * 8)) & 255;
      WordToHexValue_temp = "0" + lByte.toString(16);
      WordToHexValue =
        WordToHexValue +
        WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
    }
    return WordToHexValue;
  };

  let x = Array();
  let k, AA, BB, CC, DD, a, b, c, d;
  const S11 = 7,
    S12 = 12,
    S13 = 17,
    S14 = 22;
  const S21 = 5,
    S22 = 9,
    S23 = 14,
    S24 = 20;
  const S31 = 4,
    S32 = 11,
    S33 = 16,
    S34 = 23;
  const S41 = 6,
    S42 = 10,
    S43 = 15,
    S44 = 21;

  str = utf8_encode(str);
  x = ConvertToWordArray(str);
  a = 0x67452301;
  b = 0xefcdab89;
  c = 0x98badcfe;
  d = 0x10325476;

  for (k = 0; k < x.length; k += 16) {
    AA = a;
    BB = b;
    CC = c;
    DD = d;
    a = FF(a, b, c, d, x[k + 0], S11, 0xd76aa478);
    d = FF(d, a, b, c, x[k + 1], S12, 0xe8c7b756);
    c = FF(c, d, a, b, x[k + 2], S13, 0x242070db);
    b = FF(b, c, d, a, x[k + 3], S14, 0xc1bdceee);
    a = FF(a, b, c, d, x[k + 4], S11, 0xf57c0faf);
    d = FF(d, a, b, c, x[k + 5], S12, 0x4787c62a);
    c = FF(c, d, a, b, x[k + 6], S13, 0xa8304613);
    b = FF(b, c, d, a, x[k + 7], S14, 0xfd469501);
    a = FF(a, b, c, d, x[k + 8], S11, 0x698098d8);
    d = FF(d, a, b, c, x[k + 9], S12, 0x8b44f7af);
    c = FF(c, d, a, b, x[k + 10], S13, 0xffff5bb1);
    b = FF(b, c, d, a, x[k + 11], S14, 0x895cd7be);
    a = FF(a, b, c, d, x[k + 12], S11, 0x6b901122);
    d = FF(d, a, b, c, x[k + 13], S12, 0xfd987193);
    c = FF(c, d, a, b, x[k + 14], S13, 0xa679438e);
    b = FF(b, c, d, a, x[k + 15], S14, 0x49b40821);
    a = GG(a, b, c, d, x[k + 1], S21, 0xf61e2562);
    d = GG(d, a, b, c, x[k + 6], S22, 0xc040b340);
    c = GG(c, d, a, b, x[k + 11], S23, 0x265e5a51);
    b = GG(b, c, d, a, x[k + 0], S24, 0xe9b6c7aa);
    a = GG(a, b, c, d, x[k + 5], S21, 0xd62f105d);
    d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
    c = GG(c, d, a, b, x[k + 15], S23, 0xd8a1e681);
    b = GG(b, c, d, a, x[k + 4], S24, 0xe7d3fbc8);
    a = GG(a, b, c, d, x[k + 9], S21, 0x21e1cde6);
    d = GG(d, a, b, c, x[k + 14], S22, 0xc33707d6);
    c = GG(c, d, a, b, x[k + 3], S23, 0xf4d50d87);
    b = GG(b, c, d, a, x[k + 8], S24, 0x455a14ed);
    a = GG(a, b, c, d, x[k + 13], S21, 0xa9e3e905);
    d = GG(d, a, b, c, x[k + 2], S22, 0xfcefa3f8);
    c = GG(c, d, a, b, x[k + 7], S23, 0x676f02d9);
    b = GG(b, c, d, a, x[k + 12], S24, 0x8d2a4c8a);
    a = HH(a, b, c, d, x[k + 5], S31, 0xfffa3942);
    d = HH(d, a, b, c, x[k + 8], S32, 0x8771f681);
    c = HH(c, d, a, b, x[k + 11], S33, 0x6d9d6122);
    b = HH(b, c, d, a, x[k + 14], S34, 0xfde5380c);
    a = HH(a, b, c, d, x[k + 1], S31, 0xa4beea44);
    d = HH(d, a, b, c, x[k + 4], S32, 0x4bdecfa9);
    c = HH(c, d, a, b, x[k + 7], S33, 0xf6bb4b60);
    b = HH(b, c, d, a, x[k + 10], S34, 0xbebfbc70);
    a = HH(a, b, c, d, x[k + 13], S31, 0x289b7ec6);
    d = HH(d, a, b, c, x[k + 0], S32, 0xeaa127fa);
    c = HH(c, d, a, b, x[k + 3], S33, 0xd4ef3085);
    b = HH(b, c, d, a, x[k + 6], S34, 0x4881d05);
    a = HH(a, b, c, d, x[k + 9], S31, 0xd9d4d039);
    d = HH(d, a, b, c, x[k + 12], S32, 0xe6db99e5);
    c = HH(c, d, a, b, x[k + 15], S33, 0x1fa27cf8);
    b = HH(b, c, d, a, x[k + 2], S34, 0xc4ac5665);
    a = II(a, b, c, d, x[k + 0], S41, 0xf4292244);
    d = II(d, a, b, c, x[k + 7], S42, 0x432aff97);
    c = II(c, d, a, b, x[k + 14], S43, 0xab9423a7);
    b = II(b, c, d, a, x[k + 5], S44, 0xfc93a039);
    a = II(a, b, c, d, x[k + 12], S41, 0x655b59c3);
    d = II(d, a, b, c, x[k + 3], S42, 0x8f0ccc92);
    c = II(c, d, a, b, x[k + 10], S43, 0xffeff47d);
    b = II(b, c, d, a, x[k + 1], S44, 0x85845dd1);
    a = II(a, b, c, d, x[k + 8], S41, 0x6fa87e4f);
    d = II(d, a, b, c, x[k + 15], S42, 0xfe2ce6e0);
    c = II(c, d, a, b, x[k + 6], S43, 0xa3014314);
    b = II(b, c, d, a, x[k + 13], S44, 0x4e0811a1);
    a = II(a, b, c, d, x[k + 4], S41, 0xf7537e82);
    d = II(d, a, b, c, x[k + 11], S42, 0xbd3af235);
    c = II(c, d, a, b, x[k + 2], S43, 0x2ad7d2bb);
    b = II(b, c, d, a, x[k + 9], S44, 0xeb86d391);
    a = AddUnsigned(a, AA);
    b = AddUnsigned(b, BB);
    c = AddUnsigned(c, CC);
    d = AddUnsigned(d, DD);
  }

  const temp = WordToHex(a) + WordToHex(b) + WordToHex(c) + WordToHex(d);

  return temp.toLowerCase();
}

if (typeof String.prototype.ToMd5 !== "function") {
  String.prototype.ToMd5 = function() {
    return md5(this);
  };
}

export function interpolateTemplate(
  template: string,
  params: {
    [index: string]: string;
  }
): Function | any {
  const names = Object.keys(params);
  return new Function(...names, `return \`${template}\`;`).bind(this);
}

export function interpolateTemplateResult(
  template: string,
  params: {
    [index: string]: any;
  }
): string {
  const vals = Object.values(params);
  return interpolateTemplate(template, params)(...vals);
}

export function hashCode(target: string) {
  let hash = 0,
    i,
    chr;
  if (target.length === 0) {
    return hash;
  }
  for (i = 0; i < target.length; i++) {
    chr = target.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function groupArrayByKeyValue<T>(array: any[], key: string): T {
  return array.reduce((objects, obj) => {
    const value = obj[key];
    objects[value] = (objects[value] || []).concat(obj)
    return objects
  }, {})
}
