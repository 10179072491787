import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";

export const enterLeaveAnimation = [
  trigger("enterLeave", [
    state("enter", style({ opacity: 1, transform: "translateY(0)" })),
    transition("* => enter", [
      style({ opacity: 0, transform: "translateY(-50%)" }),
      animate("100ms linear")
    ]),
    state("leave", style({ opacity: 0, transform: "translateY(-50%)" })),
    transition("* => leave", [
      style({ opacity: 1, transform: "translateY(0)" }),
      animate("100ms linear")
    ])
  ])
];
