import { NrtFormGroup } from "./../../base/NrtFormGroup";
import { NrtFormControl } from "@core/core.module/base/NrtFormControl";
import { appForms, AppFormDeclaration } from "./app-form.decorator";
import { IFormFieldMetadata } from "@core/core.module/base/IFormFieldMetadata";
import { Type } from "@angular/core";
import { memoize } from "../memoize.decorator";
import { NrtFormArray } from "@core/core.module/base/NrtFormArray";

export abstract class FormUtils {
  static getCreateFormControl(
    entity: any,
    fieldName: string,
    config?: IFormFieldMetadata
  ) {
    const key = entity.constructor || entity;
    let form = appForms.get(key);

    if (!form) {
      form = { entityName: "", entity: key, controls: {} };
      appForms.set(key, form);
    }

    //appForms[entityName] = appForms[entityName] || {};

    form.controls[fieldName] = form.controls[fieldName] || {
      control: () => new NrtFormControl(fieldName, config),
      validators: [],
      metadata: { title: "", type: "string", ...config }
    };

    if (config) {
      form.controls[fieldName].metadata = {
        ...form.controls[fieldName].metadata,
        ...config
      };

      form.controls[fieldName].control = () => {
        const meta = { ...form.controls[fieldName].metadata, ...config };

        if (meta.refType && meta.type === "array") {
          return new NrtFormArray(fieldName, [], meta);
        }

        if (meta.refType) {
          const fg = FormUtils.createFormGroup(meta.refType);
          fg.name = fieldName;
          fg.metadata = meta;
          return fg;
        } else {
          return new NrtFormControl(fieldName, meta);
        }
      };
    }

    return form.controls[fieldName];
  }

  static createFormGroup<T>(
    type: Type<T> | string,
    source?: T,
    extensible?: boolean
  ): NrtFormGroup<T> {
    let form = FormUtils.getFormDeclaration(type);
    let fg = NrtFormGroup.fromFactory(form.controls, source);

    if (extensible) {
      fg = FormUtils.extendFormGroupFromSource(fg, source);
    }

    return fg;
  }

  static createFormArray<T>(
    type: Type<T> | string,
    source?: any[],
    formGroupMetadata?: IFormFieldMetadata
  ): NrtFormArray {
    let form = FormUtils.getFormDeclaration(type);

    if (typeof form.config?.refType === "string") {
      throw Error("String type not implemented!");
    }

    let fa = new NrtFormArray(form.entityName, [], {
      type: "array",
      refType: form.config?.refType,
      title: form.config?.title
    });

    source && FormUtils.setArrayItems(fa, source, form.config?.refType);

    if (formGroupMetadata) {
      fa.controls.forEach(x => {
        x.metadata = { ...x.metadata, ...formGroupMetadata };
      });
    }

    return fa;
  }

  static populateFormArray(controls, model) {
    for (const key in controls) {
      if (controls.hasOwnProperty(key) && model) {
        const control = controls[key];

        if (
          control instanceof NrtFormArray ||
          control instanceof NrtFormGroup
        ) {
          if (control instanceof NrtFormArray) {
            if (model && model[key]) {
              FormUtils.setArrayItems(
                control,
                model[key],
                control.metadata.refType
              );
            }
          }
          FormUtils.populateFormArray(control.controls, model[key]);
        }
      }
    }
  }

  static extendFormGroupFromSource<T>(
    formGroup: NrtFormGroup,
    source?: T
  ): NrtFormGroup {
    const refForm = FormUtils.getFormDeclaration(formGroup.metadata.refType)
      ?.config?.refType;

    const fg = NrtFormGroup.createNotExistedControlsFromSource(
      formGroup,
      source,
      { type: typeof refForm === "string" ? refForm : "string" }
    );
    return fg;
  }

  @memoize()
  static getFormDeclaration<T>(type: Type<T> | string): AppFormDeclaration {
    return typeof type === "string"
      ? Array.from(appForms.values()).find(x => x.entityName === type)
      : appForms.get(type);
  }

  static setArrayItems<T>(
    array: NrtFormArray,
    sections: T[],
    type: Type<T> | string
  ) {
    sections.forEach(s =>
      array.addControl(FormUtils.createFormGroup(type).patchValue(s))
    );
  }

  /**
   * Returns array of controls using NrtForm based on Entity Type, Entity Instance and metadata boolean flags.
   * @param type Entity Type
   * @param entity entity instance
   * @param metadataFlagsToInclude list of IFormFieldMetadata properties names to include
   * @param metadataFlagsToExclude list of IFormFieldMetadata properties names to exclude
   */
  static getOrderedControlsArray<T>(
    type: Type<T>,
    entity: T,
    metadataFlagsToInclude?: Array<keyof IFormFieldMetadata>,
    metadataFlagsToExclude?: Array<keyof IFormFieldMetadata>
  ): NrtFormControl[] {
    let form = FormUtils.createFormGroup(type);
    form.patchValue(entity);

    let array = Object.keys(form.controls)
      .map(key => {
        return form.controls[key] as NrtFormControl;
      })
      .filter(control => control instanceof NrtFormControl) //TODO - how to display refs
      .sort((control, control2) => {
        return control.metadata.order - control2.metadata.order;
      });

    metadataFlagsToInclude &&
      metadataFlagsToInclude.forEach(flag => {
        array = array.filter(control => control.metadata[flag]);
      });

    metadataFlagsToExclude &&
      metadataFlagsToExclude.forEach(unFlag => {
        array = array.filter(control => !control.metadata[unFlag]);
      });

    return array;
  }

  static getUnderlyingType(
    control: NrtFormControl | NrtFormGroup | NrtFormArray
  ) {
    return FormUtils.getFormDeclaration(control.metadata.refType)?.config
      ?.refType;
  }
}
