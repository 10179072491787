import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyLoadDirective } from './lazy-load.directive';
import { LazyLoadBackgroundDirective } from './lazy-load-background.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [LazyLoadDirective, LazyLoadBackgroundDirective],
  exports: [LazyLoadDirective, LazyLoadBackgroundDirective]
})
export class ImageLazyLoadModule { }
