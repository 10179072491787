import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Location } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  ActivatedRoute,
  NavigationStart,
  Router,
  Event,
  NavigationEnd
} from "@angular/router";
import { MetaDataService } from "@client-services/api/clientMetaData/metaData.service";
import { BrandingClient } from "@core/core.module/branding/branding";
import { KioskActionsService } from "@common/services/kiosk/actions.service";
import { PortalOverlayService } from "@common/services/portal-overlay.service";
import { fadeAnimation } from "@core/core.module/animations/fade.animations";
import { routeSlideAnimations } from "@core/core.module/animations/route-slide.animations";
import { UntilDestroy } from "@core/core.module/decorators/until-destroy.decorator";
import { dialog, msg } from "@core/core.module/interfaces";
import { AuthService } from "@core/core.module/jwt/auth.service";
import { IDLE_TIME } from "@core/core.module/jwt/injection-tokens";
import { AppModeService } from "@core/core.module/services/app-mode.service";
import { METADATA } from "@core/core.module/services/IMetaData";
import { NrtQuery } from "@core/core.module/services/storage/NrtQuery";
import { Sizing } from "@core/core.module/utils/Sizing";
import { untilDestroyed } from "@core/core.module/utils/take-until-destroy/take-until-destroy";
import { RootLayout } from "@core/pages-ui.module/layouts/root/root.component";
import { OktaExtService } from "@core/pages-ui.module/pages/okta/okta-ext/services/okta-ext.service";
import { pagesToggleService } from "@core/pages-ui.module/services/toggler.service";
import { filterNil } from "@datorama/akita";
import { TranslocoService } from "@ngneat/transloco";
import { ModalDirective } from "ngx-bootstrap/modal";
import { BehaviorSubject, from, fromEvent, merge, of } from "rxjs";
import {
  bufferTime,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom
} from "rxjs/operators";
import {
  MobileMenuItem,
  MobileMenuItems
} from "../../branding/branded-models/mobileMenuItems";
import { LogoutAlertModalComponent } from "../../components/logout-alert-modal/logout-alert-modal.component";
import { BootstrapMetaData } from "../../services/api/clientMetaData/MetaData";
import { LocationsService } from "../../services/api/locations/locations.service";
import { ProfileService } from "../../services/api/profile/profile.service";
import { ProfileTiersService } from "../../services/api/profileTiers/profileTiers.service";
import { RewardsService } from "../../services/api/rewards/rewards.service";

interface MenuItem {
  label: string;
  routerLink: string;
  thumbNailClass: string;
}
@UntilDestroy()
@Component({
  selector: "mobile-layout",
  templateUrl: "./mobile-layout.component.html",
  styleUrls: ["./mobile-layout.component.scss"],
  animations: [fadeAnimation, routeSlideAnimations],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileLayoutComponent extends RootLayout implements OnInit {
  userName: string;
  @ViewChild("menuModal", { static: false }) menuModal: ModalDirective;

  protected isHeaderPinned = true;
  @Input() contentClass = "";

  idleTime = this.appModeService.isKioskMode()
    ? this.idleTimes?.logoutIdleTimeKiosk
    : this.idleTimes?.logoutIdleTimePlayerPortal;

  protected menuOpened: boolean = false;

  public active: any;

  public isStatementDropdownOpened: boolean = false;

  openBenefits: boolean = false;
  menu: {
    label: string;
    routerLink: string;
    thumbNailClass: string;
    haveChild: boolean;
  }[];

  //_loading$ = new BehaviorSubject<boolean>(true);
  public loading$ = this.toggler.loading.pipe(delay(1), distinctUntilChanged()); // this._loading$.pipe(/* delay(1),  */distinctUntilChanged());

  @ViewChild("subHeader", { read: ViewContainerRef, static: false })
  subHeader: ViewContainerRef;

  private _showLogout$ = new BehaviorSubject<boolean>(false);
  showLogout$ = this._showLogout$.asObservable();
  @ViewChild("topheader") topheaderElement;
  @ViewChild("topmenu") topmenuElement: ElementRef;

  screen = Sizing.instance;
  headerBg: string;
  title: string;

  constructor(
    private renderer: Renderer2,
    protected auth: AuthService,
    protected oktaService: OktaExtService,
    protected toggler: pagesToggleService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected tiers: ProfileTiersService,
    protected locations: LocationsService,
    protected rewards: RewardsService,
    //private clientMetaData: MetaDataService,
    private translate: TranslocoService,
    private location: Location,
    private profile: ProfileService,
    protected cd: ChangeDetectorRef,
    public portalOverlayService: PortalOverlayService,
    private appModeService: AppModeService,
    private actionsService: KioskActionsService,
    public breakpointObserver: BreakpointObserver,
    private metaDataService: MetaDataService,
    private titleService: Title,
    @Inject(METADATA) public metaDataQuery: NrtQuery<BootstrapMetaData>,
    @Inject(IDLE_TIME) private idleTimes
  ) {
    super(toggler, router, route, auth);

    this.updateMenu();
    this.translate.langChanges$.pipe(untilDestroyed(this)).subscribe(x => {
      this.updateMenu();
    });

    this.actionsService.isMobile$
      .pipe(untilDestroyed(this))
      .subscribe(isMobile => {
        if (!isMobile && this.appModeService.isTabletMode()) {
          this.logoutAfterIdleTime();
          this._showLogout$.next(true);
        }
      });

    router.events.pipe(untilDestroyed(this)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.title = this.titleService.getTitle();
      }
    });
  }

  openBenefit: boolean = false;

  benefitsY: MenuItem[] = [
    // {
    //   label: "How it works",
    //   routerLink: `/how-it-works`,
    //   thumbNailClass: "text-white"
    // },
    // {
    //   label: "Member Benefits",
    //   routerLink: `/member-benefits`,
    //   thumbNailClass: "text-white"
    // },
    {
      label: "Tier Benefits",
      routerLink: `/milestones-benefits`,
      thumbNailClass: "text-white"
    },
    {
      label: "Earn & Redeem",
      routerLink: `/how-to-earn`,
      thumbNailClass: "text-white"
    }
  ];

  brand$ = this.metaDataQuery.select(x => x.currentBranding);
  footerMenu$: BehaviorSubject<MobileMenuItem[]> = new BehaviorSubject<
    MobileMenuItem[]
  >(null);

  routerLinkOptions: { exact: boolean } = {
    exact: false
  };

  public statementsNavigationMenuItems: MenuItem[] = [
    {
      label: "Rewards Statement",
      routerLink: `/reward-statement`,
      thumbNailClass: "text-white"
    },
    {
      label: "Win/Loss Statement",
      routerLink: `/win-loss`,
      thumbNailClass: "text-white"
    }
  ];

  openMenu(menuItem: MenuItem) {
    if (menuItem.label === "How it works") {
      this.openBenefits = !this.openBenefits;
      return;
    }
    this.isStatementDropdownOpened = !this.isStatementDropdownOpened;
  }

  onWindowScroll() {
    const pos = this.topmenuElement.nativeElement.parentNode.style.position;
    const scrollTop = this.isMobile ? 65 : 100;
    if (pos === "fixed") {
      this.renderer.setStyle(
        this.topheaderElement.nativeElement,
        "margin-top",
        this.isMobile ? "65px" : "100px"
      );
    }
    if (window.scrollY === 0) {
      this.renderer.setStyle(
        this.topheaderElement.nativeElement,
        "margin-top",
        "0px"
      );
    }
    if (window.scrollY > scrollTop) {
      this.renderer.setStyle(
        this.topheaderElement.nativeElement,
        "margin-top",
        this.isMobile ? "65px" : "100px"
      );
    }
  }

  updateMenu() {
    this.translate
      .selectTranslate([
        "MENU.PROFILE",
        "MENU.BADGES",
        "MENU.OFFERS",
        "MENU.HOW_IT_WORKS",
        "MENU.STATEMENTS"
        // 'MENU.CONTACTS',
      ])
      .pipe(
        map(x => Object.values(x) as string[]),
        withLatestFrom(this.brand$),
        map(
          ([
            [profile, badges, offers, benefits, statements /*, contuctUs*/],
            currentBranding
          ]) => {
            return [
              {
                label: profile,
                routerLink: `home/${currentBranding}/profile`,
                thumbNailClass: "text-white",
                haveChild: false
              },
              {
                label: badges,
                routerLink: `home/${currentBranding}/medallions`,
                thumbNailClass: "text-white",
                haveChild: false
              },
              {
                label: offers,
                routerLink: `home/${currentBranding}/offers-new`,
                thumbNailClass: "text-white",
                haveChild: false
              },
              {
                label: benefits,
                routerLink: `home/${currentBranding}/benefits`,
                thumbNailClass: "text-white",
                haveChild: true
              },
              {
                label: statements,
                routerLink: `home/${currentBranding}/statements`,
                thumbNailClass: "text-white",
                haveChild: false
              }
              // {
              //   label: contuctUs,
              //   routerLink: `home/${currentBranding}/contact-us`,
              //   thumbNailClass: 'text-white',
              //   haveChild: false
              // }
            ];
          }
        ),
        untilDestroyed(this)
      )
      .subscribe(x => {
        this.menu = x;
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.headerBg = this.metaDataService.query.current?.branding?.themes?.default?.settings[
      "genting_rewards"
    ];

    this.auth.currentProfile$.pipe(untilDestroyed(this)).subscribe(x => {
      this.userName = x && x.username;
    });

    this.brand$
      .pipe(take(1))
      .subscribe(
        brand => (this.routerLinkOptions["__change_detection_hack__"] = brand)
      );

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        untilDestroyed(this)
      )
      .subscribe((event: Event) => {
        this.menuOpened && this.toggleMenu();
      });
  }

  public logoutDialog() {
    dialog
      .confirm(
        this.translate.translate("MESSAGES.LOGOUT_CONFIRMATION_MSG"),
        this.translate.translate("MESSAGES.CONFIRMATION")
      )
      .then(x => {
        x && this.logoutUser();
        //this.toggleMenu()
      });
  }

  logoutUser() {
    this.toggler.setLoading(true);

    const id = this.auth.getOktaIdToken();

    super
      .logout(true)
      .pipe(
        finalize(() => setTimeout(() => this.toggler.setLoading(false), 5000)),
        untilDestroyed(this)
      )
      .subscribe(isLoggedOut => {
        if (isLoggedOut) {
          this.tiers.store.reset();
          this.locations.store.reset();
          this.rewards.store.reset();
          this.profile.store.reset();
        }

        this.oktaService.logoutFromOkta(
          id,
          this.metaDataQuery.current?.playerPortalSettings
            ?.unauthorizedRedirectUri
        );
      });
  }

  // unpinEvent() {
  //   this.headroomElement.innerStyle.transform = "translateY(-100%) !important"; //hotfix for headroom/angular 9 (#4729) remove after library updates
  // }

  public toggleMenu() {
    this.menuOpened = !this.menuOpened;
    this.openBenefits = false;
    this.menuModal.toggle();
  }

  goTo(path: string) {
    this.actionsService.gotTo(path);
  }

  public isLinkActive(menuItem: {
    label: string;
    routerLink: string;
    thumbNailClass: string;
  }) {
    return this.router.url.endsWith(menuItem.routerLink);
  }

  public isStringLinkActive(link: string) {
    return this.router.url.includes(link);
  }

  public linkClick(e, link) {
    //this.zone.run(() => {
    this.router.navigate([link], { relativeTo: this.route });
    setTimeout(() => this.menuModal.hide(), 200);
    //});
  }

  openDropdown() {
    console.log("value");
  }

  back() {
    //this.zone.run(() => {
    this._backTo
      ? this.router.navigate([this._backTo], { relativeTo: this.route })
      : this.location.back();
    //});
  }

  logoutAfterIdleTime() {
    const activityStreams$ = merge(
      fromEvent(document, "click"),
      fromEvent(document, "keydown"),
      fromEvent(document, "mousemove"),
      fromEvent(document, "touchstart"),
      fromEvent(document, "scroll"),
      fromEvent(window, "message").pipe(
        filter(event => event["data"] === "GameAction")
      )
    ).pipe(debounceTime(250), untilDestroyed(this));

    of(this.idleTime)
      .pipe(
        filterNil,
        switchMap(time => {
          return activityStreams$.pipe(
            bufferTime(time * 1000),
            distinctUntilChanged(),
            switchMap(x => {
              if (x && !x.length) {
                const logoutAlert = dialog.showModal(
                  LogoutAlertModalComponent,
                  {
                    animated: true,
                    ignoreBackdropClick: true
                  }
                );

                return from(logoutAlert.result);
              }
              return of({ isStay: true });
            }),
            untilDestroyed(this)
          );
        })
      )
      .subscribe(x => {
        if (x && !x.isStay) {
          this.logoutUser();
          msg.info(
            this.translate.translate("SESSION.SESSION_ENDED") ||
              "Your Session Ended"
          );
        }
      });
  }
}
