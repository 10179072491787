import { FormGroup, Validators } from "@angular/forms";
import { TranslocoService } from "@ngneat/transloco";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { dirtyCheck } from "../operators/dirtyCheck";
import { IFieldMetadata } from "../services/IMetaData";
import { IFormFieldMetadata } from "./IFormFieldMetadata";
import { INrtAbstractControl } from "./INrtAbstractControl";
import { INrtValidator } from "./INrtValidator";
//import { from } from 'linq/';
import { NrtFormArray } from "./NrtFormArray";
import { NrtFormControl } from "./NrtFormControl";

export class NrtFormGroup<T = any> extends FormGroup
  implements INrtAbstractControl {
  metadata: IFormFieldMetadata;
  name: string;

  controls: {
    [key: string]: NrtFormControl | NrtFormGroup | NrtFormArray;
  };

  private _controls$: BehaviorSubject<
    (NrtFormControl | NrtFormGroup | NrtFormArray)[]
  > = new BehaviorSubject([]);

  //private _store$ = new BehaviorSubject(undefined);
  //store$: Observable<any> = this._store$.asObservable();

  isDirty$ = (store$?: Observable<any>) =>
    this.valueChanges.pipe(dirtyCheck(store$ || this.valueChanges));

  isLoading$ = new BehaviorSubject<boolean>(false);

  controls$: Observable<
    (NrtFormControl | NrtFormGroup | NrtFormArray)[]
  > = this._controls$.asObservable().pipe(
    map(x => {
      return Object.values(x).sort((a, b) => {
        return a.metadata &&
          b.metadata &&
          (a.metadata.order || 0) < (b.metadata.order || 0)
          ? -1
          : 1;
      });
    })
  );

  controlsOrderedByName$: Observable<
    (NrtFormControl | NrtFormGroup | NrtFormArray)[]
  > = this._controls$.asObservable().pipe(
    map(x => {
      return Object.values(x).sort((a, b) => a.name?.localeCompare(b.name));
    })
  );

  private _destroyObserver: Subscription;

  constructor(name?: string, controls?: { [key: string]: NrtFormControl }) {
    super(controls || {});
    this.name = name;
    controls && this._controls$.next(Object.values(controls));
  }

  static fromFactory<T>(
    controls: {
      [key: string]: {
        control: () => NrtFormControl | NrtFormGroup | NrtFormArray;
        validators: INrtValidator[];
      };
    },
    sourceData?: { [id: string]: any } | any
  ): NrtFormGroup<T> {
    const fg = new NrtFormGroup();

    //fg._store$.next(sourceData);

    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        const control = controls[key].control(); //.clone();

        if (control instanceof NrtFormControl) {
          control.assignValidators(controls[key].validators);
          const ctrl = fg.addOrUpdateFormControl(key, control);
          sourceData && ctrl.patchValue(sourceData[key]);
        } else if (
          control instanceof NrtFormGroup ||
          control instanceof NrtFormArray
        ) {
          fg.addControl(key, control);
          sourceData &&
            sourceData[key] !== undefined &&
            control.patchValue(sourceData[key]);
        }
      }
    }

    return fg;
  }

  static createNotExistedControlsFromSource(
    fg: NrtFormGroup,
    sourceData: { [id: string]: any } | any,
    metadata: IFormFieldMetadata
  ): NrtFormGroup {
    for (const k in sourceData) {
      if (!fg.controls.hasOwnProperty(k)) {
        const newCtrl = new NrtFormControl(k, { ...metadata, title: k });
        sourceData && newCtrl.patchValue(sourceData[k]);
        fg.addControl(k, newCtrl);
        fg._controls$.next(Object.values(fg.controls));
      }
    }

    return fg;
  }

  addControl(
    name: string,
    control: NrtFormControl | NrtFormGroup | NrtFormArray
  ) {
    super.addControl(name, control);
    //if(control instanceof NrtFormGroup || control instanceof NrtFormArray){
    this._controls$.next(Object.values(this.controls));
    //}
    control instanceof NrtFormControl &&
      (this._destroyObserver = control.destroy$.subscribe(x =>
        this.removeControl(x)
      ));
  }

  removeControl(name: string) {
    super.removeControl(name);
    this._controls$.next(Object.values(this.controls));
  }

  patchValue(
    sourceData: { [id: string]: any } | any,
    options?: {
      onlySelf?: boolean;
      emitEvent: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ) {
    const data = sourceData || {};
    //this._store$.next(data);
    super.patchValue(data, options);

    return this;
  }

  patchValues(
    sourceData: { [id: string]: any } | any,
    childName?: string
  ): this {
    //this._store$.next(sourceData);

    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key];

        if (
          control instanceof NrtFormControl ||
          control instanceof NrtFormArray
        ) {
          const obj = childName ? sourceData[childName] : sourceData;
          control.patchValue(obj[key]);
        } else {
          const data = sourceData[(control.parent as NrtFormGroup).name];
          data && control.patchValues(data, key);
        }
      }
    }

    return this;
  }

  translate(translateService?: TranslocoService): this {
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key];
        control.translate(translateService);
      }
    }

    return this;
  }

  getNrtFormControl(controlName: string): NrtFormControl {
    return this.get(controlName) as NrtFormControl;
  }

  getNrtFormGroup(formGroupName: string): NrtFormGroup {
    return this.get(formGroupName) as NrtFormGroup;
  }

  applyFieldsMetadata(fields: IFieldMetadata[]): this {
    Object.keys(this.controls).forEach(key => {
      const field = fields.find(x => x.fieldName === key);
      const control = this.controls[key];
      if (field) {
        if (control) {
          if (control instanceof NrtFormControl) {
            control.updateMetadata({
              order: field.order,
              ...(field.label && { title: field.label })
            });
            //control.filterValidators(field.validators.map(x => ({ name: x })));
          } else {
            this.metadata = {
              ...this.metadata,
              order: field.order,
              ...(field.label && { title: field.label })
            };
          }
        }
      } else {
        if (control instanceof NrtFormControl) {
          if (!control.metadata.skipFieldMetadata) {
            control.updateMetadata({ disabled: true });
            control.disable();
            control.clearValidators();
          }
        } else {
          this.metadata = { ...this.metadata, disabled: true };
        }
      }
    });

    return this;
  }

  createFromFieldsMetadata(fields: IFieldMetadata[]): this {
    fields.forEach(field => {
      const control = this.controls[field.fieldName];

      if (!control) {
        this.addControl(field.fieldName, new NrtFormControl(field.fieldName));
        this._controls$.next(Object.values(this.controls));
      }

      if (control instanceof NrtFormControl) {
        if (control.metadata.skipFieldMetadata) {
          (control as NrtFormControl).updateMetadata({ disabled: true });
          control.disable();
          control.clearValidators();
        } else {
          (control as NrtFormControl).updateMetadata({
            order: field.order,
            title: field.label
          });
        }
      }
    });

    return this;
  }

  overrideFieldsMetadata(
    fieldsMetadata: Record<string, IFormFieldMetadata>
  ): this {
    fieldsMetadata &&
      Object.keys(this.controls).forEach(key => {
        const meta = fieldsMetadata[key];
        if (meta && Object.keys(meta).length) {
          const control = this.controls[key];
          if (control instanceof NrtFormControl) {
            if (meta.skipValidation) {
              control.clearValidators();
              control.updateValueAndValidity();
            }
            if (meta.required) {
              control.assignValidators([
                {
                  name: "required",
                  validator: Validators.required,
                  message: "Field required"
                }
              ]);
              control.updateValueAndValidity();
            }
            control.updateMetadata(meta);
          } else {
            //control.overrideFieldsMetadata(meta);
            throw Error("Form group currently not supported!");
          }
        } else {
          //throw Error(`No field found: ${kv.key}`);
        }
      });

    return this;
  }

  markAllAsDirty(controls?: { [key: string]: NrtFormControl | NrtFormGroup }) {
    const ctrls = controls || this.controls;
    for (const key in ctrls) {
      if (this.controls.hasOwnProperty(key)) {
        const element = ctrls[key];

        if (element instanceof NrtFormGroup) {
          element.markAllAsDirty();
        } else {
          element.markAsDirty();
        }
      }
    }
  }

  addOrUpdateFormControl(
    prop: string,
    control?: NrtFormControl
  ): NrtFormControl {
    if (this.controls[prop] instanceof NrtFormGroup) {
      throw new Error("Use this method on form controls only");
    }

    if (!this.controls[prop]) {
      this.addControl(prop, control || new NrtFormControl(prop));
    }
    control &&
      control.metadata &&
      Object.assign(this.controls[prop].metadata, control.metadata);

    this._controls$.next(Object.values(this.controls));

    return this.controls[prop] as NrtFormControl;
  }

  getDefinedValue<T>(): T {
    const model = this.getRawValue() as T;
    Object.keys(model).forEach(
      key =>
        (model[key] === null || model[key] === undefined) && delete model[key]
    );
    return model;
  }

  clone() {
    const obj = {};
    Object.values(this.controls).forEach(c => {
      obj[c.name] = c.clone();
    });

    return new NrtFormGroup(this.name, obj);
  }

  destroy(controls?: {
    [key: string]: NrtFormControl | NrtFormGroup | NrtFormArray;
  }) {
    const ctrls = controls || this.controls;
    for (const key in ctrls) {
      if (this.controls.hasOwnProperty(key)) {
        const element = ctrls[key];

        if (element instanceof NrtFormGroup) {
          element.destroy();
        } else {
          element.destroy();
        }
      }
    }

    this._destroyObserver && this._destroyObserver.unsubscribe();
  }
}
