import { Type, Injectable } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NrtDialogComponent } from "../components/dialogs/nrt-dialog/nrt-dialog.component";
import { IOCDialogsService } from "@core/core.module/interfaces";

export declare type DialogType =
  | "info"
  | "warning"
  | "ask"
  | "error"
  | "confirm"
  | "success";

export interface IDialogParams {
  text: string;
  header?: string;
  showCancelButton?: boolean;
  component?: Type<any>;
  componentInputs?: any;
  type: DialogType;
}
@Injectable({ providedIn: "root" })
export class DialogService implements IOCDialogsService {
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService) {}

  private showDialog(data: IDialogParams): Promise<any> {
    this.modalRef = this.modalService.show(NrtDialogComponent, {
      initialState: data,
      backdrop: data.type === "confirm" ? "static" : true
    });
    const modal = this.modalRef.content as NrtDialogComponent;

    return modal.result;
  }

  private createDialog<TDialog, TParams>(
    component: Type<TDialog>,
    parameters?: TParams,
    isModal?: boolean
  ): TDialog {
    this.modalRef = this.modalService.show(component, parameters);
    const modal = this.modalRef.content as TDialog;

    return modal;
  }

  show<TDialog, TParams>(
    component: Type<TDialog>,
    parameters?: TParams
  ): TDialog {
    return this.createDialog(component, parameters, false);
  }
  showModal<TDialog, TParams>(
    component: Type<TDialog>,
    parameters?: TParams | any,
    lazyModalComponentName?: string
  ): TDialog {
    parameters = lazyModalComponentName
      ? {
          ...parameters,
          initialState: {
            ...parameters.initialState,
            ...{ lazyModalComponentName }
          }
        }
      : { ...parameters, ...parameters };
    return this.createDialog(component, parameters, true);
  }
  info(text: string, header?: string): Promise<void> {
    return this.showDialog({ text: text, header: header, type: "info" });
  }
  alert(text: string, header?: string): Promise<void> {
    return this.showDialog({ text: text, header: header, type: "success" });
  }
  warning(text: string, header?: string): Promise<void> {
    return this.showDialog({ text: text, header: header, type: "warning" });
  }
  error(text: string, header?: string): Promise<void> {
    return this.showDialog({ text: text, header: header, type: "error" });
  }
  confirm(text: string, header?: string): Promise<void> {
    return this.showDialog({ text: text, header: header, type: "confirm" });
  }
  ask(
    text: string,
    showCancelButton?: boolean,
    header?: string
  ): Promise<boolean> {
    return this.showDialog({
      text: text,
      header: header,
      showCancelButton: showCancelButton,
      type: "ask"
    });
  }
}
