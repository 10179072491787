import {
  TemplateRef,
  Component,
  ViewChild
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IWatermark {
  watermark: string;
}

@Component({
  selector: 'oc-watermark-template',
  template: `
    <ng-template #tmpl let-watermark="watermark">
      <div class="watermark">
        <div class="watermark-content">
          {{ watermark }}
        </div>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .watermark {
        position: absolute;
        top: 50%;
        right: 50%;
        margin-top: -33px;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
        z-index: 1;
        pointer-events: none;
      }

      .watermark-content {
        transform: rotate(-45deg) scale(0.5);
        transform-origin: 50% 50%;
        display: block;
        font-family: Arial, sans-serif;
        font-size: 40px;
        line-height: 45px;
        color: #c00;
        background: #fff;
        border: solid 4px #c00;
        padding: 5px;
        border-radius: 5px;
        opacity: 0.8;
        -webkit-text-shadow: 0 0 2px #c00;
        text-shadow: 0 0 2px #c00;
        box-shadow: 0 0 2px #c00;
        text-transform: uppercase;
        white-space: nowrap;
        letter-spacing: 1px;
        z-index: 1;
      }
    `
  ]
})
export class WatermarkTemplateComponent {
  private static _template = new BehaviorSubject<TemplateRef<IWatermark>>(null);
  static template = WatermarkTemplateComponent._template.asObservable();

  @ViewChild('tmpl', { static: true }) set tmpl(val: TemplateRef<IWatermark>) {
    WatermarkTemplateComponent._template.next(val);
  }
}
