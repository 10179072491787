export type StyleProp = Record<string, string>;

export type StyleMap = {
  [id: string]: string | StyleProp;
  light?: StyleProp;
  dark?: StyleProp;
};

import { ɵComponentDef as ComponentDef } from "@angular/core";

export function withStyle(styleMap: StyleMap) {
  return (componentDef: ComponentDef<any>) => {
    const defaults = Object.keys(styleMap)
      .filter(k => typeof k === "string")
      .reduce((props, k) => {
        props[k] = styleMap[k];
        return props;
      }, {});

    const vars = obj =>
      Object.keys(obj)
        .map(k => {
          const key = k.startsWith("--") ? k : `--${k}`;
          return `${key}: ${obj[k]}`;
        })
        .join(";");

    componentDef.styles.push(`:root { ${vars(defaults)} }`);

    ["light", "dark"].forEach(theme => {
      const themeStyle = styleMap[theme];

      themeStyle &&
        componentDef.styles.push(`:root.${theme} { ${vars(themeStyle)} }`);
    });
  };
}
