import { Reflection } from '../utils/Reflection';
import { appServices } from './app-service.decorator';
import { rootInjector$ } from '../utils/root-injector';
import { InjectionToken } from '@angular/core';

export declare type IApiServiceMetadata = any;

export class ApiServiceMetadata /*implements IApiServiceMetadata*/ {

  public Url: string;
  public Metadata: IApiServiceMetadata;

  constructor(url: string, metadata: IApiServiceMetadata) {
    this.Url = url;
    this.Metadata = metadata;
  }
}

/**
 * Configures web api service and adds it to `appServices` array.
 * Use this decorator for the application level web api services.
 * @param url WebApi base address or injection token to retrieve the url
 * @param route The route of resource excluding base url
 * @param metadata Additional user data
 */
export function ApiService(url: string | InjectionToken<string>, route?: string, metadata?: IApiServiceMetadata) {
  return target => {

    const addService = (url: string) => {
      Reflection.makeDecorator(new ApiServiceMetadata(url + (route || ''), metadata), target);
      appServices.push(target);
    };

    let newUrl: string = url as string;
    if (typeof (url) !== 'string') {
      rootInjector$.subscribe(inj => {
        if (inj) {
          const link = inj.get(url);
          link && (newUrl = link);

          addService(newUrl);
        }
      });

    } else {
      addService(newUrl);
    }


  }
}

/**
 * Configures web api service. You need add the service decorated with this decorator to module providers manually.
 * Use this decorator for the core web api services.
 * @param url WebApi base address
 * @param metadata Additional user data
 */
// export const ApiModuleService = (url: string | InjectionToken<string>, metadata?: IApiServiceMetadata) => target => {
//     Reflection.makeDecorator(new ApiServiceMetadata(url, metadata), target);
// };
