import { Injectable, Compiler, Injector } from "@angular/core";
import { MetaDataService } from "./api/clientMetaData/metaData.service";
import { SocialNetwork } from "@core/core.module/components/social-login/social-definitions";
import { ISocialNetworkProvider } from "@core/core.module/services/IMetaData";
import { SettingsQuery } from "../pages/settings/storage/settings.query";
import { of, forkJoin, from } from "rxjs";
import { TranslocoService } from "@ngneat/transloco";
import { catchError, switchMap, first, tap, map } from "rxjs/operators";
import { brandedModules } from "../app-routing.module";
import { rootInjector } from "@core/core.module/utils/root-injector";
import { LazyModuleFactory } from "@core/core.module/utils/LazyModuleFactory";
import { pagesToggleService } from "@core/pages-ui.module/services/toggler.service";
import { LoadingStages } from "@core/pages-ui.module/services/loading-stages.enum";

export function extractSsoAppKey(
  socialNetwork: SocialNetwork,
  features: ISocialNetworkProvider[]
): string {
  let provider =
    features &&
    features.find(
      provider => provider.type.toLowerCase() === socialNetwork.toLowerCase()
    );
  return (provider && provider.appId) || null;
}

@Injectable({ providedIn: "root" })
export class AppInitService {
  constructor(
    private translate: TranslocoService,
    private settingsQuery: SettingsQuery,
    private clientMetaData: MetaDataService,
    private toggler: pagesToggleService,
  ) {}

  init(): Promise<any> {
    this.toggler.setLoadingStage(LoadingStages.METADATA);
    const md = forkJoin([
      this.clientMetaData.getBootstrapMetaData$(),
      this.settingsQuery
        .select(_ => _.language)
        .pipe(
          switchMap(lang => this.translate.load(lang)),
          catchError(_ => of(false)),
          first()
        )
    ]).pipe(
      switchMap(() =>
          from(brandedModules
            .find(m => m.path === this.clientMetaData.brand)
            .loadChildren() as Promise<LazyModuleFactory<any>>)
      ),
      map(x => x.create(rootInjector))
    );

    return md.toPromise();
  }
}
