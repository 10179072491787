import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { OCBaseComponent } from "@core/core.module/base/base.component";
import { KeyboardService } from "@core/core.module/components/keyboard/keyboard.service";
import { UntilDestroy } from "@core/core.module/decorators/until-destroy.decorator";
import { untilDestroyed } from "@core/core.module/utils/take-until-destroy/take-until-destroy";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { delay, filter, tap } from "rxjs/operators";
export class Action {
  title?: string;
  callback: Function; //TODO - What type of callback is better? some rx structure?
  isLoading$?: Observable<boolean>;
  isDisabled$?: Observable<boolean>;
  icon?: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class KioskActionsService {
  lastUrl: string;
  private _forceReloadPromotions$: Subject<any> = new Subject();
  forceReloadPromotions$ = this._forceReloadPromotions$.asObservable();
  forceReloadProfile$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  profileReloaded$: Subject<any> = new Subject();

  activeRoute$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  _actions$: BehaviorSubject<Action[]> = new BehaviorSubject<Action[]>([]);
  actions$: Observable<Action[]> = this._actions$.asObservable();

  _secondaryBrandedActions$: BehaviorSubject<Action[]> = new BehaviorSubject<
    Action[]
  >([]);
  secondaryBrandedActions$: Observable<
    Action[]
  > = this._secondaryBrandedActions$.asObservable();

  _cancelAction$: BehaviorSubject<Action> = new BehaviorSubject<Action>(null);
  cancelAction$: Observable<Action> = this._cancelAction$.asObservable();

  _isMobile$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  isMobile$: Observable<boolean> = this._isMobile$.pipe(
    tap(isMob => (this._isMobile = isMob))
  );

  private _isMobile: boolean;
  get isMobile() {
    return this._isMobile;
  }

  _navigate$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  navigate$: Observable<string> = this._navigate$.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private keyboardService: KeyboardService
  ) {
    const isMobile = this._isMobile$.next(
      this.ifMobileLayoutNeeded(window.location.href)
    );
    this.router.events
      .pipe(
        tap(x => {
          if (x instanceof NavigationEnd) {
            this.lastUrl = x.url;
            this._isMobile$.next(this.ifMobileLayoutNeeded(x.url));
          }
        }),
        delay(1),
        tap(_ => this.keyboardService.hideKeyboard())
      )
      .subscribe();
  }
  private ifMobileLayoutNeeded(url: string): boolean {
    return !!~url.indexOf("mobile") || !!~url.indexOf("okta-token");
  }

  forceReloadPromotions() {
    this._forceReloadPromotions$.next();
  }

  forceReloadProfile() {
    this.forceReloadProfile$.next(Symbol());
  }

  profileReloaded() {
    this.profileReloaded$.next();
  }

  setActionsObserver(
    route: string,
    actions: Action[],
    cancelAction: Action,
    context: OCBaseComponent
  ) {
    this.setActions(actions, cancelAction, route);

    this.router.events
      .pipe(
        filter(x => x instanceof NavigationEnd && x.url === route),
        untilDestroyed(context)
      )
      .subscribe(_ => {
        this.setActions(actions, cancelAction, route);
        /* if (route === this.offersPath) {
          this.forceReloadPromotions();
          this.forceReloadProfile(); //TODO - reload need only for campaigns with points
        }
        if (route === this.profilePath) {
          this.forceReloadProfile();
        } */
      });
  }

  private setActions(actions: Action[], cancelAction: Action, route: string) {
    this.clearActions();

    this._actions$.next(actions);
    this._cancelAction$.next(cancelAction);

    /* switch (route) {
      case this.profilePath:
        this.activeRoute$.next("profile");
        break;
      case this.offersPath:
        this.activeRoute$.next("profile");
        break;
      default:
        this.activeRoute$.next(null);
    } */
  }

  setBrandedActions(actions: Action[]) {
    this._secondaryBrandedActions$.next(actions);
  }

  clearActions() {
    this._actions$.next([]);
    this._cancelAction$.next(null);
  }

  sendToSMS() {
    this.router.navigate([this.router.url + "/invitation", "sms"]);
  }

  sendToEmail() {
    this.router.navigate([this.router.url + "/invitation", "email"]);
  }

  scanQRCode() {
    this.router.navigate([this.router.url + "/qrcode"]);
  }

  gotTo(path: string) {
    this._navigate$.next(path);
  }
}
