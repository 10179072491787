import { OverlayContainer } from "@angular/cdk/overlay";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostBinding,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ChangeDetectionStrategy
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ActivationEnd,
  NavigationEnd,
  Router
} from "@angular/router";
import { AnnouncementsService } from "@client-services/api/announcements/announcements.service";
import { GoogleAnalyticsService } from "@client-services/google-analytics.service";
import { routeAnimations } from "@core/core.module/animations/route.animations";
import { OCBaseComponent } from "@core/core.module/base/base.component";
import { ComponentFeatures } from "@core/core.module/decorators/features/component-features.decorator";
import { UntilDestroy } from "@core/core.module/decorators/until-destroy.decorator";
import { withStyle } from "@core/core.module/features/style.feature";
import { msg } from "@core/core.module/interfaces/core-messages.service";
import { AuthService } from "@core/core.module/jwt/auth.service";
import { UserActivityService } from "@core/core.module/services/user-activity.service";
import { untilDestroyed } from "@core/core.module/utils/take-until-destroy/take-until-destroy";
import { MessageService } from "@core/pages-ui.module/components/message/message.service";
import { TranslocoService } from "@ngneat/transloco";
import { ModalDirective } from "ngx-bootstrap/modal";
import { UpdateService } from "projects/player-portal/common/services/update.service";
import { BehaviorSubject, combineLatest, NEVER, Subject, timer } from "rxjs";
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  switchMapTo,
  takeWhile,
  tap,
  throttleTime
} from "rxjs/operators";
import { SettingsQuery } from "./pages/settings/storage/settings.query";
import { SettingsStore } from "./pages/settings/storage/settings.store";
import { MetaDataService } from "./services/api/clientMetaData/metaData.service";
import * as themeVars from "./theme.vars";

@UntilDestroy()
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [routeAnimations]
})
@ComponentFeatures([withStyle(themeVars.themes)])
export class AppComponent extends OCBaseComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding("class") componentCssClass;

  private themeWrapper = document.documentElement;
  _theme: string;

  @ViewChild("updateModal", { static: true }) updateModal: ModalDirective;

  private _themeTrigger$ = new BehaviorSubject<string>("light");

  private _themeSub$ = combineLatest(
    this._themeTrigger$,
    this.settingsQuery.current$
  ).pipe(
    map(([, settings]) => {
      const autoNightMode = settings.autoNightMode;
      const hours = new Date().getHours();

      let theme =
        autoNightMode && (hours >= 20 || hours <= 6) ? "dark" : settings.theme;

      !["light", "dark"].includes(theme) && (theme = "light");

      return theme;
    }),
    distinctUntilChanged(),
    switchMap(theme => {
      this.renderer.removeClass(this.themeWrapper, "light");
      this.renderer.removeClass(this.themeWrapper, "dark");

      this.renderer.addClass(this.themeWrapper, theme);

      this.settingsStore.update({
        theme: theme
      });

      return this.metadata.query
        .select(x => {
          const themeSettings = {
            ...x.branding?.themes?.default?.settings,
            ...x.branding?.themes[theme]?.settings
          };
          const cssProps = {
            ...x.branding?.themes?.default?.cssProps,
            ...x.branding?.themes[theme]?.cssProps
          };
          this.settingsStore.update({
            themeSettings: themeSettings
          });

          return cssProps;
        })
        .pipe(
          tap(currTheme => {
            for (let key in currTheme) {
              this.themeWrapper.style.setProperty(key, currTheme[key]);
            }
          })
        );
    })
  );

  constructor(
    public overlayContainer: OverlayContainer,
    private renderer: Renderer2,
    private router: Router,
    private titleService: Title,
    private settingsQuery: SettingsQuery,
    private settingsStore: SettingsStore,
    private translate: TranslocoService,
    private route: ActivatedRoute,
    private metadata: MetaDataService,
    private zone: NgZone,
    private updateService: UpdateService,
    protected cd: ChangeDetectorRef,
    private userActivity: UserActivityService,
    private auth: AuthService,
    private notifications: AnnouncementsService,
    private messageService: MessageService
  ) {
    super();

    const accountBranding = this.metadata.query.current.branding;
    const iconHref = accountBranding && accountBranding.iconUri;

    this.setFavicon(iconHref || "favicon.ico");

    translate.setDefaultLang("en-US");
    let lang = localStorage.getItem("Pseudo-Locale");
    !lang && (lang = this.settingsQuery.getValue().language);
    (~["en-US", "es-MX", "en-ZW"].indexOf(lang) &&
      translate.setActiveLang(lang)) ||
      translate.setActiveLang(translate.getDefaultLang());

    translate.langChanges$.pipe(untilDestroyed(this)).subscribe(x => {
      this.settingsStore.update(s => ({ ...s, language: x }));
      this.setPageTitle(this.route.snapshot);
    });

    this.userActivity.getActivityStream().subscribe(isActive => {
      if (isActive) {
        console.log("App is active...");
        this._themeTrigger$.next(this.settingsQuery.getValue()?.theme);
        this.updateService.checkForUpdates();
      } else {
        console.log("App not active.");
      }
    });
  }

  ngOnInit(): void {
    this.subscribeToSettings();
    this.subscribeToIsAuthenticated();
    this.subscribeToRouterEvents();
    this.subscribeToLogout();
  }

  toggleUpdateTimer$ = new Subject();
  remainingSeconds$ = this.toggleUpdateTimer$.pipe(
    switchMap((running: boolean) => {
      return running ? timer(0, 1000) : NEVER;
    }),
    map(t => 5 - t),
    //tap(() => this.cd.detectChanges()),
    takeWhile(t => t >= 0),
    tap(t => t === 0 && this.reload())
  );

  ngAfterViewInit() {
    const sub = this.updateService.updateAvailable$
      .pipe(
        delay(1000),
        filter(isUpdate => !!isUpdate)
      )
      .subscribe(() => {
        this.zone.run(() => {
          this.toggleUpdateTimer$.next(true);
          this.updateModal.show();
        });
      });
  }

  reload(force?) {
    this.toggleUpdateTimer$.next(false);
    force
      ? window.location.reload()
      : this.updateService.startUpdate().subscribe();
  }

  onLoginClick() {}

  onLogoutClick() {}

  private subscribeToIsAuthenticated() {
    // this.auth.userLogin$
    //   .pipe(
    //     filter(isLoggedIn => !!isLoggedIn),
    //     switchMapTo(this.notifications.getNotifications$())
    //   )
    //   .subscribe(notifications => {
    //     notifications.forEach(notification =>
    //       msg.warning(notification.message)
    //     );
    //   });
  }

  private subscribeToLogout() {
    this.auth.userLogout$
      .pipe(filter(isLoggedOut => !!isLoggedOut))
      .subscribe(_ => {
        this.messageService.remove();
      });
  }

  private subscribeToSettings() {
    this._themeSub$.subscribe();
  }

  private subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof ActivationEnd || event instanceof NavigationEnd
        ),
        throttleTime(500),
        untilDestroyed(this)
      )
      .subscribe(event => {
        if (event instanceof ActivationEnd) {
          this.setPageTitle(event.snapshot);
        }
      });
  }

  private setPageTitle(snapshot: ActivatedRouteSnapshot) {
    while (snapshot.children.length) {
      snapshot = snapshot.children[0];
    }
    const { title } = snapshot.data;
    this.translate
      .selectTranslate(title || "SITE_NAME")
      .pipe(untilDestroyed(this))
      .subscribe(x => this.titleService.setTitle(x));
  }

  private setFavicon(iconHref: string) {
    const linkElement = document.getElementById("dynamic-favicon");
    linkElement.setAttribute("href", iconHref);
  }
}
