<!-- WRAP LAYOUT IF BOXED -->
<ng-container
  *ngTemplateOutlet="
    contentTpl;
    context: { metaDataParent: metaDataQuery.current$ | async }
  "
></ng-container>
<!--brand sidebar-brand-->

<ng-template #logo let-meta="metaData" let-class="className">
  <ng-container *ngIf="brand$ | async as brand">
    <a *ngIf="!_allowBack; else subpage" (click)="linkClick('home/' + brand)">
      <img
        *ngIf="meta?.clientAccount?.account?.clientLogoUrl"
        [src]="meta?.clientAccount?.account?.clientLogoUrl"
        alt="logo"
        [ngClass]="class"
      />
    </a>
  </ng-container>
  <ng-template #subpage>
    <span class="large-text page-title m-t-10">{{
      _pageTitle | transloco
    }}</span>
  </ng-template>
</ng-template>

<!-- YOUR LAYOUT CONTENT GOES INSIDE HERE -->
<ng-template #contentTpl let-metap="metaDataParent">
  <!-- PAGE CONTAINER -->
  <ngx-headroom>
    <div class="header-wrapper">
      <div class="box a">
        <pg-menu-icon
          *ngIf="_allowBack"
          IconName="arrow"
          Direction="left"
          [Size]="40"
          (click)="back()"
        ></pg-menu-icon>

        <h4 *ngIf="!_allowBack" class="large-text  page-title m-0">
          {{ _pageTitle | transloco }}
        </h4>
      </div>
      <div class="box b">
        <div class="brand inline  p-r-0">
          <ng-container
            *ngTemplateOutlet="
              logo;
              context: { metaData: metap, className: 'brand' }
            "
          ></ng-container>
        </div>
      </div>
      <div class="box c">
        <pg-menu-icon
          IconName="bars"
          [Size]="35"
          (click)="toggleMenu()"
        ></pg-menu-icon>
      </div>
    </div>

    <!--
  <pg-header>
    <div class="title">

      <div class="brand inline  p-r-0">
        <ng-container
          *ngTemplateOutlet="
            logo;
            context: { metaData: metap, className: 'brand' }
          "
        ></ng-container>
      </div>
    </div>

    <div class="btn-back" *ngIf="_allowBack" (click)="back()">
      <span class="bar bar-back bar--active"></span>
    </div>

      <h4 *ngIf="!_allowBack" class="large-text  page-title">
        {{ _pageTitle | transloco }}
      </h4>
  </pg-header>

  <div
    class="menu-icon"
    (click)="toggleMenu()"
  >
    <span class="bar"></span>
  </div>
   -->
    <ng-template #subHeader></ng-template>
  </ngx-headroom>

  <ng-container *ngIf="portalOverlayService.overlayTemplate$ | async as tmpl">
    <ng-container [cdkPortalHost]="tmpl"></ng-container>
  </ng-container>

  <!-- <div class="overlay" [class.overlay--active]="menuOpened">

    <ul class="list">
      <li class="items" *ngFor="let m of menu">
        <a
          class="links"
          [routerLinkActive]="'active'"
          (click)="linkClick($event, m.routerLink)"
          >{{ m.label }}</a
        >
      </li>
      <li class="items" [class.animate]="menuOpened">
        <a class="text-danger links" (click)="logoutUser()">{{
          "MENU.LOGOUT" | transloco
        }}</a>
      </li>
    </ul>
  </div> -->

  <div
    class="page-content-wrapper p-b-20 {{ _pageContainerClass }}"
    *ngIf="{ loading: loading$ | async } as q"
  >
    <!-- <oc-page-loader></oc-page-loader> -->
    <!-- <div *ngIf="(loading$ | async) as loading">
      <oc-page-loader></oc-page-loader>
    </div> -->

    <!-- START PAGE CONTENT -->

    <div class="content {{ contentClass }}" [@fadeAnimation]="o.isActivated">
      <router-outlet #o="outlet"></router-outlet>
    </div>

    <!-- END PAGE CONTENT -->
    <!-- START COPYRIGHT -->
  </div>
  <!-- QUICKSEARCH -->

  <!-- QUICKVIEW -->
</ng-template>

<button
  *ngIf="isTabletMode"
  class="btn btn-danger btn-rounded logout-btn"
  (click)="logoutUser()"
>
  <pg-menu-icon IconType="clr" IconName="logout" [Size]="24"></pg-menu-icon>
</button>

<div
  bsModal
  #menuModal="bs-modal"
  class="menu-modal modal fade fill-in"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <pg-menu-icon
    Class="close-menu-modal"
    IconType="clr"
    IconName="times"
    (click)="toggleMenu()"
    [Size]="50"
  ></pg-menu-icon>

  <div class="modal-dialog modal-lg">
    <div class="modal-content-wrapper">
      <div class="modal-content p-t-10 p-b-10">
        <!-- <span class="text-white page-title menu-title">{{ _pageTitle | transloco }}</span> -->

        <ul class="list" [style.pointer-events]="'all'">
          <li class="items" *ngFor="let m of menuModal.isShown ? menu : []">
            <a
              class="links"
              [class.active]="isLinkActive(m)"
              (click)="linkClick(m.routerLink)"
              >{{ m.label }}</a
            >
          </li>
          <li class="items">
            <a class="links" (click)="logoutDialog(); toggleMenu()">{{
              "MENU.LOGOUT" | transloco
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
