import { isDevMode } from "@angular/core";
import { Reflection } from "../utils/Reflection";
import { isString, isArray } from "../utils/utils";
import { Json } from "../utils/Json";
import { msg } from "../interfaces/core-messages.service";
import { catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { throwError } from "rxjs";
import {
  HttpResponse,
  HttpClient,
  HttpXhrBackend,
  HttpHandler,
  HttpRequest,
  HttpEventType,
  HttpHeaders,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { HttpStandartRequestOptions } from "./HttpStandartRequestOptions";

export class OCHttp extends HttpClient {
  public static baseAddress = "";

  constructor(backend: HttpHandler) {
    super(backend);
  }

  public static instance(): HttpClient {
    return Reflection.Injector.get(HttpClient);
  }

  public static updateUrl(
    uri: string | HttpRequest<any>
  ): string | HttpRequest<any> | any {
    let url: string = <any>uri;
    if (uri instanceof Request) {
      url = uri.url;
    }
    if (
      url &&
      OCHttp.baseAddress &&
      url.indexOf("/") !== 0 &&
      url.indexOf("://") === -1
    ) {
      url = `${OCHttp.baseAddress}/${url}`;
    }
    if (uri instanceof HttpRequest) {
      uri = uri.clone({ url: url });
    } else {
      uri = url;
    }
    return uri;
  }

  /**
   * Extracts error from server response.
   */
  public static ExctractError(
    err: HttpErrorResponse
  ): string | HttpErrorResponse {
    const errors = isArray(err?.error?.errors)
      ? err?.error?.errors
          .filter(x => x.message)
          .map(x => x.message)
          .join(", ")
      : (err.error.message || err.message);

    return errors || err;

    // if (err instanceof Promise) {
    //   return err;
    // } else if (err instanceof HttpResponse) {
    //   try {
    //     err = this.ExctractError(err.body) || err;
    //     // ReSharper disable once TypeGuardProducesNeverType
    //     if (err instanceof Promise) {
    //       return err;
    //     }
    //   } catch (e) {
    //     return err.toString();
    //   }
    // } else if (err instanceof Blob) {
    //   let resolver;
    //   const res = new Promise<string>(r => (resolver = r));
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     const errorText = reader.result;
    //     err = this.ExctractError(errorText);
    //     resolver(err);
    //   };
    //   reader.readAsText(err);
    //   return res;
    // } else if (typeof err === 'string' && Json.IsJsonLike(err)) {
    //   err = Json.fromJson(err);
    // }

    // let error = (err || 'Fatal error').toString();
    // if (err) {
    //   let args = {};
    //   if (err.TranslateKey) {
    //     args = err.args || {};
    //     error = err.TranslateKey + '|' + (err.Message || '');
    //     //error = translate.translate(error);
    //   } else if (err.Message) {
    //     error = err.Message;
    //   } else if (typeof err.__zone_symbol__message === 'string') {
    //     error = err.__zone_symbol__message;
    //   }

    //   if (error === '[object ProgressEvent]') {
    //     error = 'Connection to API is lost.';
    //   }
    //   //error = translate.translate(error, args);

    //   if (isDevMode() && err.Details) {
    //     if (err.Details.Contains(error)) {
    //       error = err.Details;
    //     } else if (!error.Contains(err.Details)) {
    //       error = error + '\r\n' + err.Details;
    //     }
    //   }

    //   error = (error || '').replace(/(?:\r\n|\r|\n)/g, '<br/>');
    // }
    // // the messages starting with # is special ones and shouldn't be displayed
    // return error && error !== '#' ? error : undefined;
  }

  /**
   * Processes error as service response.
   */
  public static ProcessError(err: HttpErrorResponse): string | HttpErrorResponse {

    console.log(err)

    const error = OCHttp.ExctractError(err);
    if (error) {
      // if (error instanceof Promise) {
      //   error.then(e => {
      //     this.ProcessError(e);
      //   });
      // } else {
        // неперехваченная ошибка будет отображена дважды - второй раз в глобальном перехватчике необработанных ошибок angular
        if (isDevMode()) {
          msg.runtimeError(error);
        } else {
          console.error(error);
        }
      //}
    }
    return error;
  }

  /**
   * Handles the error.
   */
  public static HandleError(error: HttpErrorResponse): Observable<never> {
    const err = this.ProcessError(error);
    return throwError(err);
  }

  public static TransformServiceResponse(
    data: any,
    headers?: HttpHeaders,
    array: boolean = false
  ): any {
    // Copied from Angular default transform method
    if (isString(data)) {
      // Strip json vulnerability protection prefix and trim whitespace
      const tempData = data.replace(Json.JSON_PROTECTION_PREFIX, "").trim();

      if (tempData) {
        const contentType = headers && headers.get("Content-Type");
        if (
          (contentType && contentType.indexOf(Json.APPLICATION_JSON) === 0) ||
          Json.IsJsonLike(tempData)
        ) {
          data = Json.ResolveReferences(Json.fromJson(tempData));
          if (array && !isArray(data)) {
            data = [data];
          }
        }
      }
    }
    return data;
  }

  public static HandleResponse(
    response: Observable<HttpResponse<any>> //,
    //options?: HttpStandartRequestOptions
  ) {
    const res = response.pipe(
      map((value: HttpResponse<any> | any) => {
        if (value instanceof HttpResponse) {
          let resp;
          //if (options) {
          // switch (options.responseType) {
          //   case ResponseContentType.Blob:
          //     resp = value.blob();
          //     break;
          //   case ResponseContentType.Json:
          //     resp = value.json();
          //     break;
          //   case ResponseContentType.Text:
          //     resp = value.text();
          //     break;
          //   case ResponseContentType.ArrayBuffer:
          //     resp = value.arrayBuffer();
          //     break;
          //   default:
          //     resp = value.text();
          // }
          //} else {
          resp = value.body; //.text();
          //}

          return this.TransformServiceResponse(resp, value.headers);
        } else if (value instanceof HttpResponse) {
          return value.body;
        } else {
          return value;
        }
      })
    );

    return res;
  }

  /**
   * Performs any type of http request. First argument is required, and can either be a url or
   * a {@link Request} instance. If the first argument is a url, an optional {@link RequestOptions}
   * object can be provided as the 2nd argument. The options object will be merged with the values
   * of {@link BaseRequestOptions} before performing the request.
   */
  //public request(req: HttpRequest<any>): Observable<HttpEvent<any>>;
  public request<R = any>(
    method: any,
    url?: string,
    options?: HttpStandartRequestOptions
  ): Observable<R> {
    return super
      .request(
        method,
        OCHttp.updateUrl(url),
        this.createAuthorizationHeader(url, options)
      )
      //.pipe(catchError(e => OCHttp.HandleError(e))); //as any;//Observable<HttpResponse<any>>;
  }

  private createAuthorizationHeader(
    url: string | HttpRequest<any>,
    options?: HttpStandartRequestOptions
  ): HttpStandartRequestOptions {
    let uri: string;

    if (url instanceof HttpRequest) {
      uri = url.url;
      options = url;
    } else {
      uri = url;
    }
    if (
      uri.indexOf("://") === -1 ||
      (OCHttp.baseAddress && uri.startsWith(OCHttp.baseAddress + "/"))
    ) {
      options = options || {};
      options.headers = options.headers || new HttpHeaders();
      //   if (auth.isLoggedIn) {
      //       options.headers.append('Authorization', `Bearer ${auth.accessToken}`);
      //   }

      //   if (translate.getActiveLang()) {
      //       options.headers.append('X-Language', translate.getActiveLang());
      //   }
    }
    return options;
  }
}
