import {Injector} from '@angular/core';
import 'reflect-metadata';
//import * as linq from 'linq';
//import * as st from 'stacktrace-js';

export let AppInjector: Injector;

export function setInjector(injector: Injector) {
    AppInjector = injector;
}

export class Reflection {
    public static Injector: Injector = null;

    public static get(token: any, notFoundValue?: any): any {
        if (Reflection.Injector) {
            return Reflection.Injector.get(token, notFoundValue);
        } else {
            return undefined;
        }
    }

    public static makeDecorator(annotationInstance: any, target: Function) {
        const annotations = Reflect.getOwnMetadata('annotations', target) || [];
        annotations.push(annotationInstance);
        Reflect.defineMetadata('annotations', annotations, target);

    }

    // tslint:disable-next-line:max-line-length
    public static makePropDecorator(decoratorInstance: any, target: Object, propertyKey: string, descriptor?: TypedPropertyDescriptor<any>) {
        const meta = Reflect.getOwnMetadata('propMetadata', target.constructor) || {};
        meta[propertyKey] = meta[propertyKey] || [];
        meta[propertyKey].unshift(decoratorInstance);
        Reflect.defineMetadata('propMetadata', meta, target.constructor);
    }

    public static makeParamDecorator(decoratorInstance: any, target: Object, index: number) {
        const parameters = Reflect.getMetadata('parameters', target) || [];
        // there might be gaps if some in between parameters do not have annotations.
        // we pad with nulls.
        while (parameters.length <= index) {
            parameters.push(null);
        }
        parameters[index] = parameters[index] || [];
        const annotationsForParam = parameters[index];
        annotationsForParam.push(decoratorInstance);
        Reflect.defineMetadata('parameters', parameters, target);
    }

    public static getMetadata(name: string, target: any, own: boolean = false): any[] {
        return own ? (<any>Reflect).getOwnMetadata(name, target.constructor) : (<any>Reflect).getMetadata(name, target.constructor);
    }
    public static annotations(target: any, own: boolean = false): any[] {
        return Reflection.getMetadata('annotations', target, own);
    }
    public static propAnnotations(target: any, own: boolean = false): any[] {
        return Reflection.getMetadata('propMetadata', target, own);
    }

    public static classMetadata(target: any, metadataType: Function, own: boolean = false): any {
        const res = (this.annotations(target) || []).find(x => x instanceof metadataType);
        return res;
    }

    public static memberMetadata(target: any, memberName: string, metadataType: Function, own: boolean = false): any {
        const res = ((this.propAnnotations(target) || {})[memberName] || []).find(x => x instanceof metadataType);
        return res;
    }

    // public static callerName(
    //   level: number = 0,
    //   getLastPartOfName: boolean = true
    // ) {
    //   let res;
    //   const stackTrace = st.getSync({ offline: true });
    //   // If minification, two more calls includes to stack starting from getSync method
    //   // tslint:disable-next-line:max-line-length
    //   const self = linq
    //     .from(stackTrace)
    //     .firstOrDefault(
    //       x =>
    //         x.functionName &&
    //         (x.functionName === 'getSync' /* IE */ ||
    //           x.functionName.endsWith('.getSync')) /* Others */
    //     );
    //   if (self) {
    //     const index = stackTrace.indexOf(self) + 1;
    //     level += index;
    //   }
    //   res = stackTrace[level + 1].functionName;
    //   if (getLastPartOfName) {
    //     const arr = res.split('.');
    //     res = arr[arr.length - 1];
    //   }
    //   return res;
    // }

    public static getFnBody(fn: Function): string {
      // Get content between first { and last }
      const m = fn.toString().match(/\{([\s\S]*)\}/m)[1];
      // Strip comments
      return m.replace(/^\s*\/\/.*$/gm, '');
    }

    public static isFnEmpty(fn: Function): boolean {
      return (Reflection.getFnBody(fn) || '').trim() === '';
    }
}
