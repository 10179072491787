import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: 'safeStyle' })
@Injectable()
export class SafeStyle implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(content: string) {
    return this.sanitizer.bypassSecurityTrustStyle(content);
  }
}
