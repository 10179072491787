import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { OptimisticPreloadingStrategy } from "@core/core.module/services/OptimisticPreloadingStrategy";
import { LazyModuleFactory } from "@core/core.module/utils/LazyModuleFactory";
import { rootInjector } from "@core/core.module/utils/root-injector";
import { QuicklinkModule } from "ngx-quicklink";
import { AppModeService } from "./../../../../../libs/core/src/lib/core.module/services/app-mode.service";
import { DeviceHashResolverComponent } from "./branding/branded-components/device-hash-resolver/device-hash-resolver.component";
import { DummyHashResolverComponent } from "./branding/branded-components/device-hash-resolver/dummy-hash-resolver.component";
import { CardResolverComponent } from "./branding/clients/Coyote/card-resolver/card-resolver.component";
import { BrandingLayoutGuard } from "./BrandingLayoutGuard";
import { IframeTestComponent } from "./components/iframe-test.component";
import { PortalBlankComponent } from "./layouts/blank/blank.component";
import { CondensedLayoutComponent } from "./layouts/condensed/condensed-layout.component";
import { MobileLayoutComponent } from "./layouts/mobile/mobile-layout.component";
import { HomeContainerComponent } from "./pages/home/home-container";
import { HomeModule } from "./pages/home/home.module";
import { OptimisticDataActivator } from "./services/data.resolver";

export const brandedModules = [
  {
    path: "tp",
    loadChildren: LazyModuleFactory.produce(() =>
      import("./branding/clients/TownPump/tp.module").then(m => m.TpModule)
    ),
    canActivate: [OptimisticDataActivator],
    data: { title: "MENU.HOME", NO_PRELOAD: true }
  },
  {
    path: "elite",
    loadChildren: LazyModuleFactory.produce(() =>
      import("./branding/clients/Elite/elite.module").then(m => m.EliteModule)
    ),
    data: { title: "MENU.HOME", NO_PRELOAD: true, SUB_HEADER: true }
  },
  {
    path: "coyote",
    loadChildren: LazyModuleFactory.produce(() =>
      import("./branding/clients/Coyote/coyote.module").then(
        m => m.CoyoteModule
      )
    ),
    data: {
      title: "MENU.HOME",
      NO_PRELOAD: true
    }
  },
  {
    path: "bally",
    loadChildren: LazyModuleFactory.produce(() =>
      import("./branding/clients/Bally/bally.module").then(m => m.BallyModule)
    ),
    data: { title: "MENU.HOME", NO_PRELOAD: true, SUB_HEADER: true }
  },
  {
    path: "rwlv",
    loadChildren: LazyModuleFactory.produce(() =>
      import("./branding/clients/RWLV/rwlv.module").then(m => m.RwlvModule)
    ),
    data: {
      title: "MENU.PROFILE",
      NO_PRELOAD: true
    }
  },
  {
    path: "offercraft",
    loadChildren: LazyModuleFactory.produce(() =>
      import("./branding/clients/OfferCraft/offercraft.module").then(m => m.OfferCraftModule)
    ),
    data: {
      title: "MENU.PROFILE",
      NO_PRELOAD: true
    }
  },
  {
    path: "naskila",
    loadChildren: LazyModuleFactory.produce(() =>
      import("./branding/clients/Naskila/naskila.module").then(m => m.NaskilaModule)
    ),
    data: {
      title: "MENU.PROFILE",
      NO_PRELOAD: true
    }
  },
];

export const appRoutes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "home"
  },
  {
    path: "home",
    component: HomeContainerComponent,
    canActivate: [BrandingLayoutGuard],
    data: { title: "MENU.HOME" },
    children: brandedModules
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./pages/profile/profile.module").then(m => m.ProfileModule),
    data: { title: "MENU.PROFILE" }
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./pages/settings/settings.module").then(m => m.SettingsModule),
    data: { title: "MENU.SETTINGS" }
  }
];

// addTransitionSequence("*", RouteState.Profile);
// addTransitionSequence("*", RouteState.Settings);
// addTransitionSequence("*", RouteState.Home);

export const routes: Routes = [
  {
    path: "",
    canActivate: [BrandingLayoutGuard],
    component: PortalBlankComponent
  },
  {
    path: "kiosk",
    canActivate: [BrandingLayoutGuard],
    component: PortalBlankComponent
  },
  {
    path: "kiosk/card/:cardNumber",
    component: CardResolverComponent
  },
  {
    path: "device",
    component: DeviceHashResolverComponent
  },
  {
    path: "dummy",
    component: DummyHashResolverComponent
  },
  {
    path: "dummy1",
    canActivate: [BrandingLayoutGuard],
    component: PortalBlankComponent
  },
  {
    path: "iframe-test",
    component: IframeTestComponent
  },
  {
    path: "okta-token",
    loadChildren: () =>
      import("./branding/branded-components/okta-token/okta-token.module").then(
        m => m.OktaTokenModule
      )
  },
  {
    path: "session",
    matcher: null,
    component: PortalBlankComponent,
    data: { showLangSelect: true },
    loadChildren: () => {
      const appMode = rootInjector.get(AppModeService);
      const module = appMode.isKioskMode() ? "kiosk-session" : "session";

      return import(
        `libs/core/src/lib/pages-ui.module/pages/${module}/session.module`
      ).then(m => m.SessionModule);
    }
  },
  {
    path: "dummy-login",
    matcher: null,
    component: PortalBlankComponent,
    data: { showLangSelect: true },
    loadChildren: () => {
      const appMode = rootInjector.get(AppModeService);

      return import(
        `libs/core/src/lib/pages-ui.module/pages/dummy-session/session.module`
      ).then(m => m.SessionModule);
    }
  },
  {
    path: "providers",
    matcher: null,
    component: PortalBlankComponent,
    data: { showLangSelect: false },
    loadChildren: () => {
      return import(
        `libs/core/src/lib/pages-ui.module/pages/okta/okta-ext/okta-ext.module`
      ).then(m => m.OktaExtModule);
    }
  },
  {
    path: "condensed",
    component: CondensedLayoutComponent,
    canActivate: [BrandingLayoutGuard],
    children: appRoutes
  },
  {
    path: "kiosk/condensed",
    component: CondensedLayoutComponent,
    canActivate: [BrandingLayoutGuard],
    children: appRoutes
  },
  {
    path: "lucid",
    component: PortalBlankComponent,
    canActivate: [BrandingLayoutGuard],
    children: appRoutes
  },
  {
    path: "mobile",
    component: MobileLayoutComponent,
    canActivate: [BrandingLayoutGuard],
    children: appRoutes
  },
  {
    path: "kiosk/lucid",
    component: PortalBlankComponent,
    canActivate: [BrandingLayoutGuard],
    children: appRoutes
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: ""
  }
];

@NgModule({
  imports: [
    CommonModule,
    //PagesComponentsModule,
    HomeModule,
    //LayoutsModule,
    QuicklinkModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: /* QuicklinkStrategy */ OptimisticPreloadingStrategy,
      enableTracing: true,
      // scrollPositionRestoration: "enabled"
      anchorScrolling: "enabled"
    })
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() { }
}
