import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { ButtonsModule } from 'ngx-bootstrap';
import { LanguageSelectComponent } from './language-select.component';

@NgModule({
  declarations: [LanguageSelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    //ButtonsModule.forRoot()
  ],
  bootstrap: [LanguageSelectComponent],
  exports: [LanguageSelectComponent]
})
export class LanguageSelectModule { }
