import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse
} from "@angular/common/http";
import { Observable, of, Subject, combineLatest } from "rxjs";
import { tap, startWith } from "rxjs/operators";
import { cachedUrls } from "@core/core.module/services/api.service";
import { AuthService } from "@core/core.module/jwt/auth.service";

export interface CacheData {
  expiry?: number;
  res: any;
}

export const cacheClean$ = new Subject();
export let cacheStorage = new Map<string, CacheData>();

@Injectable({ providedIn: "root" })
export class CacheInterceptor implements HttpInterceptor {
  private cacheStorage = new Map<string, CacheData>();

  constructor(private auth: AuthService) {
    combineLatest([
      this.auth.userLogout$.pipe(startWith(false)),
      this.auth.userLogin$.pipe(startWith(false)),
      cacheClean$.pipe(startWith(true))
    ]).subscribe(() => {
      cachedUrls.clear();
      this.cacheStorage.clear();
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const expiration = cachedUrls.get(request.url);
    if (!expiration || request.method !== "GET") {
      return next.handle(request);
    }

    const cachedResponse = this.cacheStorage.get(request.url);
    if (cachedResponse) {
      if (
        cachedResponse.expiry &&
        cachedResponse.expiry <
          Date.now() /* || skipCacheUrls.has(request.url) */
      ) {
        this.cacheStorage.delete(request.url);
        //skipCacheUrls.delete(request.url);
      } else {
        return of(cachedResponse.res);
      }
    }

    return next.handle(request).pipe(
      tap(res => {
        if (res instanceof HttpResponse) {
          this.cacheStorage.set(request.url, {
            expiry: Date.now() + expiration,
            res: res
          });
        }
      })
    );
  }
}
