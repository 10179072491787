<div
  class="card card-{{ _type }} {{ _additionalClasses }}"
  [attr.style]="_cardStyle | safeStyle"
  *ngIf="!_close_card"
  (onClick)="onClick.next($event)"
  [class.card-collapsed]="_isCollapsed"
  [class.card-maximized]="_isMaximixed"
  [style.transform]="'scale(' + _zoom + ')'"
  [style.border-top-left-radius]="
    _isLoading && _progressType === 'bar' ? '0px' : null
  "
  [style.border-top-right-radius]="
    _isLoading && _progressType === 'bar' ? '0px' : null
  "
  #appRipple="appRipple"
  appRipple
  [showRipple]="ShowRipple"
  #hostContent
>
  <div *ngIf="_headerVisible" class="card-header {{ _extraHeaderClass }}">
    <div class="card-title">
      <pg-menu-icon
        *ngIf="CardType"
        IconType="clr"
        [Size]="24"
        [Class]="
          {
            info: 'is-highlight',
            warning: 'is-warning',
            error: 'is-error',
            success: 'is-success'
          }[CardType]
        "
        [style.margin-right.px]="10"
        [IconName]="
          {
            info: 'info-standard',
            warning: 'warning-standard',
            error: 'error-standard',
            success: 'success-standard'
          }[CardType]
        "
      >
      </pg-menu-icon>
      <ng-template [ngTemplateOutlet]="CardTitle"></ng-template>
    </div>
    <div class="card-controls" *ngIf="_showTools == true">
      <ul *ngIf="_minimalHeader == false; else minimalBlock">
        <ng-template [ngTemplateOutlet]="CardExtraControls"></ng-template>
        <li *ngIf="_toggle == true">
          <a
            href="javascript:void(0);"
            data-toggle
            class="card-collapse"
            (click)="toggle()"
            ><i
              class="pg pg-arrow_maximize"
              [class.pg-arrow_minimize]="_isCollapsed"
            ></i
          ></a>
        </li>
        <li *ngIf="_refresh == true">
          <a
            href="javascript:void(0);"
            data-toggle
            class="card-refresh"
            (click)="refresh()"
            ><i class="card-icon card-icon-refresh"></i
          ></a>
        </li>
        <li *ngIf="_maximize == true">
          <a
            href="javascript:void(0);"
            data-toggle
            class="card-maximize"
            (click)="maximize()"
            ><i class="card-icon card-icon-maximize"></i
          ></a>
        </li>
        <li *ngIf="_close == true">
          <a
            href="javascript:void(0);"
            data-toggle
            class="card-close"
            (click)="close()"
            ><i class="card-icon card-icon-close"></i
          ></a>
        </li>
      </ul>
      <ng-template #minimalBlock>
        <ul>
          <ng-template [ngTemplateOutlet]="CardExtraControls"></ng-template>
          <li *ngIf="_refresh == true">
            <a
              href="javascript:void(0);"
              (click)="refresh()"
              class="card-refresh minimal"
              [class.refreshing]="_isLoading"
            >
              <pg-progress
                #minimalCircleLoading
                *ngIf="!_isLoading"
                [type]="'circleready'"
                [color]="'info-light'"
                [thick]="false"
                [size]="16"
              >
              </pg-progress>
              <pg-progress
                #minimalCircleLoadingTrigger
                *ngIf="_isLoading"
                [type]="'circle'"
                style="position: absolute;top:-16px;left:-16px"
                [color]="'info-light'"
                [thick]="false"
                [size]="16"
              >
              </pg-progress>
            </a>
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
  <div
    class="card-body {{ _extraBodyClass }}"
    [attr.style]="_bodyStyle | safeStyle"
    [@collapseState]="_isCollapsed == true ? 'inactive' : 'active'"
    (@collapseState.done)="onDoneCollapseAnimation($event)"
  >
    <ng-content></ng-content>
  </div>
  <div *ngIf="_messageVisible == true" class="pgn-wrapper" data-position="top">
    <div class="pgn pgn-bar push-on-sidebar-open">
      <div class="alert alert-danger">
        <span>{{ _message }}</span
        ><button class="close" (click)="alertDismiss()" type="button">
          <span aria-hidden="true">×</span><span class="sr-only">Close</span>
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="_messageVisible == true"
    class="card-progress"
    [style.backgroundColor]="
      _minimalHeader ? 'rgba(0,0,0, 0.6)' : 'rgba(255, 255, 255, 0.8)'
    "
    style=" display: block;"
  ></div>
  <div
    *ngIf="!_disableLoadingOverlay"
    [@fadeAnimation]="_isLoading"
    class="card-progress d-block"
    [style.border-top-left-radius]="
      _isLoading && _progressType === 'bar' ? '0px' : null
    "
    [style.border-top-right-radius]="
      _isLoading && _progressType === 'bar' ? '0px' : null
    "
    [style.backgroundColor]="
      _minimalHeader ? 'rgba(0,0,0, 0.1)' : 'rgba(255, 255, 255, 0.8)'
    "
  >
    <pg-progress
      *ngIf="_minimalHeader == false && _isLoading"
      [type]="_progressType"
      color="{{ _progressColor }}"
      [indeterminate]="true"
      class=""
    ></pg-progress>
  </div>

  <div
    *ngIf="_watermark"
    class="card-progress"
    [style.border-top-left-radius]="
      _isLoading && _progressType === 'bar' ? '0px' : null
    "
    [style.border-top-right-radius]="
      _isLoading && _progressType === 'bar' ? '0px' : null
    "
    [style.backgroundColor]="
      _minimalHeader ? 'rgba(0,0,0, 0.1)' : 'rgba(255, 255, 255, 0.5)'
    "
    style="display: block;pointer-events: none"
  >
    <div class="watermark">
      <div class="watermark-content">
        <ng-container *ngTemplateOutlet="_watermark"></ng-container>
      </div>
    </div>
  </div>
</div>
