import { filterNil } from "@datorama/akita";
import { Injectable, NgZone } from "@angular/core";
import { fromEvent, of, merge, BehaviorSubject } from "rxjs";
import {
  debounceTime,
  filter,
  switchMap,
  bufferTime,
  map,
  distinctUntilChanged,
  mapTo,
  shareReplay
} from "rxjs/operators";
import { outsideZone } from "../operators/outsideZone";

@Injectable({
  providedIn: "root"
})
export class UserActivityService {
  readonly activityStreams$ = merge(
    fromEvent(document, "click"),
    fromEvent(document, "keydown"),
    fromEvent(document, "mousemove"),
    fromEvent(document, "touchstart"),
    fromEvent(document, "scroll"),
    fromEvent(window, "message").pipe(filter(event =>
      event['data'] === 'GameAction'
    ))
  ).pipe(outsideZone(this.zone), debounceTime(500));

  constructor(private zone: NgZone) { }

  getActivityStream(intervalSec: number | string = 5) {
    const activityBufferedStream$ = of(intervalSec).pipe(
      filterNil,
      map(time => (typeof time === "string" ? parseInt(time) : time)),
      filter(time => time > 0),
      switchMap(time => {
        return this.activityStreams$.pipe(
          bufferTime(time * 1000),
          map(activities => { activities.length > 0 }),
          distinctUntilChanged()
        );
      }),
      outsideZone(this.zone)
    );

    const activityRealtimeStream$ = this.activityStreams$.pipe(mapTo(true));

    return merge(activityBufferedStream$, activityRealtimeStream$).pipe(
      distinctUntilChanged()
    );
  }
}
